/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { ChevronLeftRounded } from '@mui/icons-material';
import {
  Container, Grid, IconButton, Snackbar,
} from '@mui/material';
// import { DatePicker, LocalizationProvider, MuiPickersAdapter } from '@mui/lab'
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement, Elements, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { TikTok } from 'react-tiktok';
import { httpsCallable } from 'firebase/functions';
import {
  CarouselProvider, Slider, Slide,
} from 'pure-react-carousel';
import images from '../../Configs/images';
import { getUserData, setUserData } from '../../utils/storage';
import { auth, fFunction } from '../../Configs/firebase';
import { amplytics } from '../../utils/helper';
import 'pure-react-carousel/dist/react-carousel.es.css';

const stripePromise = loadStripe('pk_test_dGNR4cYmCjNKP0y8lg05phbd00XTBJZria');
export default function Payment(props) {
  const { classes, history } = props;
  // const isIOS = isiOS()
  const userData = getUserData();
  useEffect(() => {
    document.title = 'Kopi Date Experience - Payment';
  }, []);
  const [snack, setSnack] = useState('');

  const [input] = useState({});

  const [error, setError] = useState({
  });

  const [step, setStep] = useState(0);

  const [fields] = useState([
    {
      desc: `Woo hoo! 🎉\nCongrats, ${userData.signupInfo?.firstName}! 🥳`,
      altDesc: (<span>
        We’re skipping you ahead of the
        {' '}
        <strong>800+ people</strong>
        <br />
        in the waitlist as it looks like you’re a great fit
        <br />
        for Kopi Date’s community!
      </span>),
      seeOnly: true,
      btnText: 'PROCEED',
      field: 'congrats',
    },
    {
      title: `Welcome ${userData.signupInfo?.firstName}`,
      desc: 'How Kopi Date works',
      field: 'howKDWorks',
      seeOnly: true,
    },
    {
      title: `Welcome ${userData.signupInfo?.firstName}`,
      desc: 'Let the great\nconversations roll!',
      field: 'conversation',
      seeOnly: true,
    },
    {
      title: `Welcome ${userData.signupInfo?.firstName}`,
      desc: 'You’re about to go on\ngreat dates!',
      field: 'stripe',
      btnText: 'ONWARDS',
    },
  ]);
  const active = fields[step];

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (active.field === 'howKDWorks') {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 5000);
    } else setDisable(false);
  }, [active]);

  const handleNext = (e) => {
    e.preventDefault();
    if ((active.field && !input[active.field] && active.field !== 'promoCode' && !active.seeOnly)) {
      setError({ ...error, [active.field]: true });
    } else {
      if (active.field) {
        setError({ ...error, [active.field]: false });
      }
      setStep(step + 1);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (step >= 1) { setStep(step - 1); } else history.replace('/');
  };

  const renderField = () => {
    switch (active.field) {
      case 'stripe': return (
        <>
          <div className="altDesc2">
            Add a card to reserve your spot.
            <br />
            <strong style={{ textDecoration: 'underline' }}>No charge</strong>
            {' '}
            until you’re onboard.
          </div>
          <div className="altDesc2" style={{ marginTop: 20 }}>{userData.signupInfo?.email}</div>
          <Elements stripe={stripePromise}>
            <CheckoutForm classes={classes} history={history} />
          </Elements>
        </>
      );
      case 'howKDWorks': return (
        <div className="video-container">
          <div style={{
            position: 'absolute',
            top: 45,
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            left: 0,
            right: 0,
            zIndex: 0,
          }}
          >
            <img alt="loading" src={images.Loading} width="100" height="100" />
          </div>
          <TikTok url="https://www.tiktok.com/@kopidate/video/7025089261657509121" />
        </div>
      );
      case 'congrats': return (
        <div style={{ marginTop: 20, width: '80%' }}>
          <CarouselProvider
            naturalSlideWidth={3}
            naturalSlideHeight={3}
            totalSlides={4}
            infinite
            dragEnabled
          >
            <Slider>
              <Slide index={0}>
                <img alt="feedback" style={{ width: '70%' }} src={images.FB1} />
              </Slide>
              <Slide index={1}>
                <img alt="feedback" style={{ width: '70%' }} src={images.FB2} />
              </Slide>
              <Slide index={2}>
                <img alt="feedback" style={{ width: '70%' }} src={images.FB3} />
              </Slide>
              <Slide index={3}>
                <img alt="feedback" style={{ width: '70%' }} src={images.FB4} />
              </Slide>
            </Slider>
          </CarouselProvider>
        </div>
      );
      case 'conversation': return (
        <>
          <img alt="conversation" style={{ width: '70%' }} src={images.Conversation} />
          <div style={{ fontStyle: 'italic', fontSize: 14, marginTop: 20 }}>
            “Forget swiping, Kopi Date revives old school
            <br />
            dating with conversations over coffee.”
          </div>
          <div style={{
            fontSize: 14, marginTop: 10, letterSpacing: 1, marginBottom: 20,
          }}
          >
            VULCAN POST
          </div>
          <img src={images.Google2} alt="supported" style={{ width: '90%' }} />
        </>
      );
      default: return null;
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh' }}>
        <div className={classes.root}>
          {(step !== 0 || userData)
                        && <IconButton className="back" onClick={handleBack}><ChevronLeftRounded /></IconButton>}
          <div className="top" style={active.image ? imageStyle : {}}>
            <div className="title">{active.title}</div>
            <div className="desc">{active.desc}</div>
            <div className="altDesc">{active.altDesc}</div>
            <div className="fieldContainer">
              {active.image
                                && <img src={active.image} alt="about you" className="signupImage" />}
              {active.field
                                && renderField()}
            </div>
          </div>
          <div style={{ paddingTop: 36, boxSizing: 'border-box', textAlign: 'center' }}>
            <Container>
              <div style={{
                fontSize: 14, color: active.desc2 ? '#606060' : '#fef8f4', marginTop: 8, display: 'flex', justifyContent: 'center', alignItems: 'center',
              }}
              >
                {active.desc2 || '.'}
              </div>
              {((active.btnText !== 'ONWARDS' && active.field !== 'howKDWorks') || (active.field === 'howKDWorks' && !disable))
                                && (
                                <button
                                  disabled={disable}
                                  onClick={handleNext}
                                  style={{
                                    cursor: 'pointer', background: '#9e7666', marginBottom: 8, border: 'none', borderRadius: 10, width: '100%', padding: '12px 14px', marginTop: 20,
                                  }}
                                >
                                  <div style={{
                                    textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 16, color: '#fff',
                                  }}
                                  >
                                    {active.btnText || 'NEXT'}
                                  </div>
                                </button>
                                )}
            </Container>
          </div>
        </div>

        <Snackbar
          open={Boolean(snack)}
          onClose={() => setSnack('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div className={classes.snack}>{snack}</div>
          </div>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

const imageStyle = {
  background: '#f9efe8',
  borderRadius: '0 0 50% 50%',
  width: '180%',
  marginLeft: '-40%',
};

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [cardVal, setCardVal] = useState(null);
  const [error, setError] = useState('');
  useEffect(() => {
    if (!cardVal) {
      const c1 = elements?.getElement(CardElement);
      const c2 = elements?.getElement('card');
      setCardVal(c1 || c2);
    }
  });
  const { classes, history } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    setError('');
    amplytics('btn-payment');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const callable = httpsCallable(fFunction, 'oneTimePaymentWrapper');
    await callable({ userId: auth.currentUser?.uid }).then(async (intents) => {
      const { paymentIntent, setupIntent } = intents.data;
      console.log('client_secret', paymentIntent.client_secret);
      await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: { card: cardVal },
      }).then(async (result) => {
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          setError(result.error.message);
          setLoading(false);
        } else {
          console.log('payment success');
          await setUserData(auth.currentUser);
          history.replace('/calendly');
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          setLoading(false);
        }

        if (setupIntent) {
          await stripe
            .confirmCardSetup(setupIntent.client_secret, {
              payment_method: { card: cardVal },
            })
            .then(async (res) => {
              if (res.error) {
                setError(res.error.message);
                setLoading(false);
              } else {
                console.log('payment success');
                await setUserData(auth.currentUser);
                history.replace('/calendly');
                setLoading(false);
              }
            });
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    });
  };

  return (
    <form
      action="/charge"
      method="post"
      onSubmit={handleSubmit}
      style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '95%',
      }}
    >
      {loading
          && (
          <div className={classes.loading2}>
            <div className="loadingText">Please wait...</div>
            <img alt="loading" src={images.Loading} width="100" height="100" />
          </div>
          )}
      <div style={{ display: loading ? 'none' : 'block', width: '100%' }}>
        <div style={{ width: '100%' }}>
          <CardElement className="field-pay" id="card" />
        </div>
        {Boolean(error) && (
          <div style={{
            fontSize: 12, color: 'salmon', marginBottom: 20, fontWeight: 'bold',
          }}
          >
            {error}
          </div>
        )}
        <div>
          <img alt="stripe" src={images.Stripe} height="40" width="160" />
          <div style={{ fontSize: 10, color: 'grey' }}>All transactions are secure and encrypted.</div>
        </div>
        <button
          type="submit"
          style={{
            cursor: 'pointer', background: '#FFA500', marginBottom: 8, border: 'none', borderRadius: 10, width: '100%', padding: '12px 14px', marginTop: 120,
          }}
        >
          <div style={{
            textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 16, color: '#fff',
          }}
          >
            ONWARDS
          </div>
        </button>
      </div>
    </form>
  );
}
