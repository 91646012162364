/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CloseRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { brown } from '../../Configs/colors';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function BaseModal({
  classes,
  open,
  title,
  desc,
  handleClose,
  handleSubmit,
  noCancel,
  noSubmit,
  submitText,
  closeText,
  children,
  actionFull,
  bgColor,
  overlayColor,
  disabled,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      className={classes.root}
      fullWidth
      maxWidth="xs"
      sx={{ backgroundColor: overlayColor }}
    >
      <DialogTitle sx={{ backgroundColor: bgColor, position: 'relative' }}>
        <IconButton sx={{ position: 'absolute', right: 2, top: 0 }} onClick={handleClose}>
          <CloseRounded />
        </IconButton>
        {title}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: bgColor }}>
        {desc
          && (
          <DialogContentText sx={{ fontSize: 14 }}>
            {desc}
          </DialogContentText>
          )}
        {children}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: bgColor }}>
        {!noCancel
            && (
            <Button
              id={`btn-modal-close-${title.replace(' ', '')}`}
              style={{
                color: brown,
                border: `1px solid ${brown}`,
                textTransform: 'none',
                borderRadius: 8,
              }}
              size="medium"
              onClick={handleClose}
            >
              {closeText || 'Close'}
            </Button>
            )}
        {!noSubmit
          && (
          <Button
            disabled={disabled}
            id={`btn-modal-submit-${title.replace(' ', '')}`}
            fullWidth={actionFull}
            style={{
              color: '#fff',
              backgroundColor: brown,
              border: `1px solid ${brown}`,
              textTransform: 'none',
              borderRadius: 8,
            }}
            size="medium"
            onClick={handleSubmit}
          >
            {submitText || 'Send'}
          </Button>
          )}
      </DialogActions>
    </Dialog>
  );
}
