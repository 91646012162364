/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Box, Container, Divider, Grid, SwipeableDrawer,
} from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import { EmailIcon, TelegramIcon, WhatsappIcon } from 'react-share';
import { bg_grey } from '../../Configs/colors';
import { OrangeButton } from '../../Elements';
import images from '../../Configs/images';
import { amplytics } from '../../utils/helper';
import { getReferralInfo } from '../../utils/storage';

export default function Component({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Referral';
    amplytics('Referral View');
  }, []);
  const referralInfo = getReferralInfo();

  const message = `Have%20you%20tried%20Kopi%20Date?%20Sign%20up%20with%20my%20promo%20code:%20${referralInfo?.promocode}%20and%20you%20will%20get%20off%20the%20waitlist,%20PLUS%20a%20VIP%20pass!%20%F0%9F%A4%9F%F0%9F%8F%BB%20Come%20check%20it%20out!%20https://kopidate.com`;

  const [openToShare, setOpenToShare] = useState(false);
  const toggleOpenToShare = () => {
    if (!openToShare) {
      amplytics('Clicks on referral');
    }
    setOpenToShare(!openToShare);
  };

  const handleRedirect = (link) => () => {
    // const linkArr = link.split('/');
    // const destiny = linkArr[linkArr.length - 1];
    // amplytics(`Home CTA redirect to ${destiny}`);
    window.open(link, '_blank');
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} style={{ position: 'relative' }}>
          <Container sx={{ pt: 3, fontSize: 14, fontWeight: '500' }}>
            <div className="flex align-items-center" onClick={() => history.goBack()}>
              <ChevronLeftRounded />
              <div>BACK</div>
            </div>
          </Container>

          <div className="center title">
            Refer your friends
          </div>

          <div style={{ background: bg_grey }} className="center code-container">
            <div style={{ color: '#606060', fontSize: 14, fontWeight: '600' }}>YOUR REFERRAL CODE</div>
            <div className="code">{referralInfo?.promocode}</div>
            <OrangeButton onClick={toggleOpenToShare} text="SHARE MAGIC LINK" rounded styles={{ padding: '6px 32px', fontWeight: '600', fontSize: 16 }} />
          </div>

          <Container sx={{ mt: 3 }}>
            <div className="center desc-container">
              <div className="head">How it works</div>
              <Box sx={{ mt: 1 }}>
                Send your referral code to your friends &gt;
                your friend
                uses your code to get on Kopi Date &gt; Both of you get rewarded
              </Box>
            </div>
            <Divider sx={{ mt: 3 }} />
          </Container>

          <Container sx={{ mt: 3 }}>
            <div className="benefits-container">
              <div className="head">Your friend gets</div>
              <div className="benefit">- Bonus 30% OFF their first Kopi Date Experience</div>
              <div className="benefit">- V.I.P Pass: Expedited Application Review</div>

              <div className="head">You get</div>
              <div className="benefit">- 1 FREE Kopi Date Experience with every friend referred</div>
              <div className="benefit">- Bonus: refer 3 or more friends and unlock Religion Preference</div>
            </div>
          </Container>

          <Box className="center" sx={{ mt: 3, pb: 4 }}>
            <img src={images.Socialkd} alt="social-kd" style={{ width: '80%' }} />
            <div style={{ color: '#606060', fontSize: 12, marginTop: 16 }}>Referral T&Cs Apply</div>
          </Box>

        </Grid>
      </Grid>
      <SwipeableDrawer
        anchor="bottom"
        open={openToShare}
        onClose={toggleOpenToShare}
        onOpen={() => null}
      >
        <Box
          sx={{ width: 'auto', textAlign: 'center' }}
          role="presentation"
        >
          <div style={{
            marginTop: 10, fontSize: 16, fontWeight: 'bold', color: '#000',
          }}
          >
            Share using
          </div>
          <div style={{
            padding: '18px 20px 40px', display: 'flex', gap: 4, justifyContent: 'center',
          }}
          >
            <WhatsappIcon size={45} round onClick={handleRedirect(`https://api.whatsapp.com/send?text=${message}`)} />
            <TelegramIcon size={45} round onClick={handleRedirect(`https://t.me/share/url?url={https://kopidate.com}&text=${message}`)} />
            <a href={`sms:&body=${message}`}>
              <EmailIcon size={45} round />
            </a>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
