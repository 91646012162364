/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from 'react';
import { Widget } from '@typeform/embed-react';
import { useParams } from 'react-router-dom';
import { BottomNav } from '../../Elements';

export default function Feedback({ history }) {
  const { typeformId } = useParams();

  useEffect(() => {
    document.title = 'Kopi Date Experience - Feedback';
    if (!typeformId) {
      history.replace('/');
    }
    return null;
  }, []);

  return (
    <>
      <div style={{ height: '100vh', /* background:'rgb(238, 234, 221)' */ backgroundColor: '#fef8ee' }}>
        <Widget id={typeformId} style={{ width: '100%', height: '90%' }} className="my-form" />
      </div>
      <BottomNav active={0} />
    </>
  );
}
