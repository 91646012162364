const styles = {
  quizSection: {
    paddingLeft: 8,
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .cards': {
      display: 'flex',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      position: 'relative',
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
      '& div.card': {
        borderRadius: 10,
        width: 100,
        boxSizing: 'content-box',
        marginBottom: 10,
        marginRight: 10,
        cursor: 'pointer',
      },
      '& .game-desc': {
        fontWeight: '400',
        color: '#606060',
        marginTop: 4,
        fontSize: 12,
      },
      '& .card-img': {
        width: '100%',
        height: 100,
        objectFit: 'cover',
        borderRadius: 10,
      },
    },
  },
};

export default styles;
