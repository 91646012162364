const styles = {
  root: {
    background: '#fef8f4',
    minHeight: '100vh',
    overflowX: 'hidden',
    position: 'relative',
    '& .top': {
      textAlign: 'center',
      height: '75vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .back': { position: 'absolute', left: 8, top: 8 },
    '& .nice': {
      color: '#2E99A5', fontWeight: '500', alignSelf: 'flex-end', margin: '0 5%', marginBottom: 14,
    },
    '& .selectGender': {
      background: '#fff',
      width: '90%',
      borderRadius: 10,
      border: '2px solid #eee',
      '& .womanSection': {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '14px 24px 10px',
      },
      '& .manSection': {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 24px 14px',
      },
      '& .sex': { fontSize: 18, fontWeight: '600', color: 'grey' },
    },
    '& .title': {
      color: '#9e7666', fontWeight: '600', fontSize: 14, marginTop: 36,
    },
    '& .desc': {
      color: '#9e7666', fontWeight: 'bolder', fontSize: 24, marginTop: 6, overflowWrap: 'break-word', lineBreak: 'auto', whiteSpace: 'pre-line',
    },
    '& .altDesc': {
      color: '#9e7666', fontWeight: '500', fontSize: 14, marginTop: 20, overflowWrap: 'break-word', lineBreak: 'auto', whiteSpace: 'pre-line',
    },
    '& .altDesc2': {
      color: 'gray', fontWeight: '500', fontSize: 14, marginTop: 20, overflowWrap: 'break-word', lineBreak: 'auto', whiteSpace: 'pre-line',
    },
    '& .fieldContainer': {
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, width: '95%',
    },
    '& .signupImage': {
      width: '50%',
      marginBottom: 20,
    },
    '& .video-container': {
      height: '65vh',
      overflowY: 'auto',
      borderRadius: 8,
      position: 'relative',
      minWidth: '100%',
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
    },
    '& .field-pay': {
      margin: '20px 0',
      borderRadius: 10,
    },
  },
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    '& .loadingText': {
      fontSize: 14, color: 'grey', marginBottom: 20, marginTop: -40,
    },
  },
  loading2: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    '& .loadingText': {
      fontSize: 14, color: 'grey', marginBottom: 20, marginTop: -40,
    },
  },
  snack: {
    backgroundColor: '#ccc',
    textAlign: 'center',
    position: 'relative',
    borderRadius: 8,
    padding: '4px 0',
    color: '#9e7666',
    fontSize: 14,
    justifyContent: 'center',
  },
};

export default styles;
