import { brown } from '../../Configs/colors';

const styles = {
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    position: 'fixed',
    zIndex: 999,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  root: {
    height: '100vh',
    position: 'relative',
    '& .head': {
      color: brown,
      fontWeight: '500',
      fontSize: 28,
      marginTop: 10,
      marginBottom: 10,
    },
    '& .action': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '12px 0',
      textDecoration: 'none',
      '& .manualbg-action-name': { backgroundColor: '#f9edde', borderRadius: 8, padding: '0 4px' },
    },
    '& .social-title': {
      marginTop: 28,
      marginBottom: 12,
      fontSize: 12,
      fontWeight: '500',
      color: '#000',
    },
    '& .social': { marginRight: 10 },
    '& .social:first-child': { marginLeft: -8 },
    '& .cloud': {
      position: 'absolute',
      bottom: 10,
      left: 0,
      right: 0,
    },
    '& .blue-cup': {
      position: 'absolute',
      bottom: 50,
      right: 20,
      width: '50%',
      height: 250,
    },
    '& .logout-btn': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '12px 0',
      position: 'absolute',
      bottom: 75,
      left: 25,
      '& .logout-icon': {
        color: '#555',
        transform: 'rotate(180deg)',
        fontSize: 22,
      },
    },
    '& .action-name': {
      fontSize: 14, color: '#555', marginLeft: 12, fontWeight: '500',
    },
    '& .divider': { color: '#ccc', marginBottom: 6 },
  },
};
export default styles;
