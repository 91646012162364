/* eslint-disable */
import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, IconButton } from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import '../style.css';
import images from '../../../Configs/images';

export default function StartScreen(props) {
  const { history } = props;
  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className="root start-screen-container">
          <div className="wrapper">
            <div className="how-to-play">HOW TO PLAY</div>
            <div className="background">
              <img src={images.TiltPhone} alt="" />
            </div>
            <p>
              Player 1 and Player 2 will take turns to ask each other questions.
              You win by being vulnerable.
            </p>
            <Link to="/game-screen" className="btn">
              START
            </Link>
            <div className="footer-text">
              © KOPI DATE 2022. All rights reserved.
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
