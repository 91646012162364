/* eslint-disable max-len */
/// /////////////////FIRESTORE
/* import firebase from "firebase/app";
import "firebase/functions";
//require("firebase/functions");

const sendEmail = (
  email_target,
  Name,
  partner_name,
  Message,
 match_id
) => {

const sendEmailNotification= firebase.app().functions('asia-east-2').httpsCallable('sendEmailNotification');
sendEmailNotification({emailTarget: email_target, name: Name, partnerName: partner_name, message: Message, matchId: match_id}).then((result) => {console.log(result.data);}).catch( (error) => {
  console.warn(error);
});

}

export default sendEmail; */

/// ///////////////AXIOS

import axios from 'axios';
// TODO:
const sendEmail = (
  email_target,
  Uid,
  Name,
  partner_name,
  Message,
  match_id,
  Domain,
) => {
  axios
    .post('https://asia-east2-kopi-date-v1.cloudfunctions.net/sendEmailNotification', {
      data: {
        emailTarget: email_target,
        uid: Uid,
        name: Name,
        partnerName: partner_name,
        message: Message,
        matchId: match_id,
        domain: Domain,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.warn(error);
    });
};

export default sendEmail;

/// /////////FETCH
/* const fetch = require('node-fetch');
const sendEmail = ( email_target,
  Name,
  partner_name,
  Message,
 match_id ) => {

  let json_data= JSON.stringify({
    "emailTarget": email_target,
     "name": Name,
     "partnerName": partner_name,
     "message": Message,
     "matchId": match_id
  })

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: json_data
};

fetch('https://asia-east2-kopi-date-dev.cloudfunctions.net/sendEmailNotification', requestOptions)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.warn("error "+error);
    });
};

export default sendEmail; */

/// ////////////XML HTTP REQUEST

/* const sendEmail = ( email_target,
  Name,
  partner_name,
  Message,
 match_id ) => {

  var xhr = new XMLHttpRequest()
  let json_data= JSON.stringify({
    "emailTarget": email_target,
     "name": Name,
     "partnerName": partner_name,
     "message": Message,
     "matchId": match_id
  })

  xhr.open('POST', 'https://asia-east2-kopi-date-dev.cloudfunctions.net/sendEmailNotification')
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(json_data)
};

export default sendEmail; */
