/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Container } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { auth, firestore } from '../../Configs/firebase';
import {
    getEnv,
  getGettingToKnowYou,
  getLastMatch,
  getNextMatch, getPastDates, getTodaysMatch, getUserData, setUserData,
} from '../../utils/storage';

export default function Component() {
  const history = useHistory();
  const user = getUserData();
  const nextMatch = getNextMatch();
  const todaysMatch = getTodaysMatch();
  const lastMatch = getLastMatch();
  const gettingToKnowYouInfo = getGettingToKnowYou();
  const env = getEnv()

  const gender = user?.signupInfo?.gender;

  const pastDates = getPastDates() || [];
  const hasConcludedDate = () => {
    let hasConcluded = false;
    pastDates.forEach((date) => {
      const has = gender === 'Male' ? date.maleFeedback : date.femaleFeedback;
      if (has) {
        hasConcluded = true;
      }
    });
    return hasConcluded;
  };

  const isUserNeverFillFeedback = () => {
    if (gender === 'Male' && ((todaysMatch && !todaysMatch?.maleFeedback) || (lastMatch && !lastMatch?.maleFeedback))) {
      return true;
    } if (gender === 'Female' && ((todaysMatch && !todaysMatch?.femaleFeedback) || (lastMatch && !lastMatch?.femaleFeedback))) {
      return true;
    }
    return false;
  };

  const openGuide = async () => {
    window.open('https://docs.google.com/presentation/d/e/2PACX-1vRKDVJdfBJEiwQjuJfvzcVvLr5UkG08BZycB6zIe9A864uKcEnTOKfWe8L-n0TqTGaRtZbQ1ix8EElB/pub?start=false&loop=false&delayms=3000&slide=id.p', '_blank');
    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser?.uid}`);

    await updateDoc(docRef, {
      hasReadToolkit: true,
    }).then(async () => {
      await setUserData(auth.currentUser);
    }).catch((err) => {
      alert(err.message);
      console.log(err);
    });
  };

  const handleTo = (link) => () => {
    // amplytics(`Home tap to ${destiny || link}`);
    history.push(link);
  };

  return (
    <div className="need-to-do">
      <Container>

        {
          user?.userState === 1
            ? <Action type="info" text1="Status:" text2="Application in review" noAct />

            : (user?.userState === 2 || user?.userState === 3 || user?.userState === 4) && !user?.planType && user?.price === 1
              ? <Action type="info" actText="SELECT" handleClick={handleTo('/subscription')} text2="Choose a plan that works for you!" />

              : user?.userState === 2 && hasConcludedDate()
                ? <Action type="info" actText="Go for additional new date" handleClick={handleTo('/adhoc')} text2="Waiting for next date" />

                : user?.userState === 2 && !user?.onboarded && !lastMatch
                  ? <Action type="warning" actText="PROCEED" handleClick={handleTo('/payment')} text1="Pending:" text2="The Kopi Date Brew Onboarding" decor="underline" />

                  : !gettingToKnowYouInfo && user.userState === 3
                    ? <Action type="warning" handleClick={handleTo('/knowing-the-real-you')} text1="Pending:" text2="Complete Profile" decor="underline" />

                    : todaysMatch
                      ? <Action type="success" text1="" text2="Woo hoo! It`s date-day! 🥳" actText="DETAILS" btnBg="orange" handleClick={handleTo('/upcoming-date')} />

                        : (user?.userState === 4 && env.adhoc_push_for_state)? <Action type="info" text1="Status:" text2="Waiting for curation" actText="Expedited adhoc date" handleClick={handleTo('/adhoc')}/>

                        : user?.userState === 4 ? <Action type="info" text1="Status:" text2="Waiting for curation" noAct />

                        : (user?.userState === 5 && (nextMatch || todaysMatch))
                          ? <Action type="warning" text1="Pending:" text2="Confirm date" decor="underline" actText="DETAILS" handleClick={handleTo('/date-details')} />

                          : (user?.userState === 6 && (nextMatch || todaysMatch))
                            ? <Action type="info" text1="Status:" text2="Pending Partner's Confirmation" actText="DETAILS" handleClick={handleTo('/upcoming-date')} />

                            : user?.userState === 7 && isUserNeverFillFeedback()
                              ? (
                                <Action
                                  type="success"
                                  text1="Status:"
                                  text2="Date Confirmed"
                                  actText="LOCATION REVEAL"
                                  btnBg="orange"
                                  handleClick={handleTo('/upcoming-date')}
                                />
                              )
                              : isUserNeverFillFeedback()
                                ? (
                                  <Action
                                    type="warning"
                                    text1="Pending:"
                                    text2="Conclude the date"
                                    decor="underline"
                                    handleClick={handleTo(`/conclude/${todaysMatch?.matchId || lastMatch.matchId}`)}
                                    actText="CONCLUDE"
                                  />
                                )
                                : user?.planType && !user?.hasReadToolkit
                                  ? <Action type="info" text1="" text2="Read essential tool kit" handleClick={openGuide} actText="Read" />
                                  : null
                        }

      </Container>
    </div>
  );
}

function Action({
  type, noAct, text1, text2, decor, actText, handleClick, btnBg,
}) {
  const c = type === 'warning' ? '#ffa634' : type === 'info' ? '#7ca6ef' : type === 'success' ? '#55b37e' : null;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <li style={{ display: 'list-item', color: c, fontSize: 14 }}>
        {text1}
        {' '}
        <strong style={{ textDecoration: decor, fontStyle: type === 'info' ? 'italic' : 'normal' }} onClick={handleClick || null}>{text2}</strong>
      </li>
      {!noAct
        && (
        <div>
          <button
            style={{
              background: btnBg || c, borderRadius: 25, border: 'none', color: '#fff', fontSize: 10, fontWeight: '500', padding: '4px 12px',
            }}
            onClick={handleClick}
          >
            {actText || 'GET STARTED'}
          </button>
        </div>
        )}
    </div>
  );
}
