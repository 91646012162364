import { bg_grey, brown, grey } from '../../Configs/colors';

const styles = {
  root: {
    height: '100vh',
    padding: '16px 0 56px',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
    '& .section': {
      textAlign: 'center',
      padding: '16px',
    },
    '& .back': { position: 'absolute', left: 8, top: 8 },
    '& .title': {
      color: '#9e7666', fontWeight: '600', fontSize: 14, marginTop: 16,
    },
    '& .title2': { color: '#9e7666', fontWeight: 'bold', fontSize: 14 },
    '& .title3': {
      color: '#606060', fontSize: 12, fontWeight: 'bold', margin: '16px 0 12px',
    },
    '& .desc': {
      color: '#9e7666', fontWeight: 'bolder', fontSize: 24, marginTop: 6, overflowWrap: 'break-word', lineBreak: 'auto', whiteSpace: 'pre-line',
    },
    '& .altDesc': {
      color: '#9e7666', fontWeight: '500', fontSize: 14, marginTop: 20, overflowWrap: 'break-word', lineBreak: 'auto', whiteSpace: 'pre-line',
    },
    '& .info': {
      textAlign: 'left', color: '#606060', fontSize: 13, fontWeight: '400',
    },
    '& .info2': {
      color: '#606060', marginTop: 16, fontSize: 14, fontWeight: '500',
    },
    '& .divider': { marginTop: 20, marginBottom: 20 },
    '& .form-label': { color: '#a68c58', fontWeight: 'bold', marginTop: 16 },
    '& .order-desc': { fontSize: 12, color: '#606060' },
    '& .chips-selected': {
      background: '#fdf0e3', minHeight: 140, borderRadius: 8, marginTop: 10, padding: '10px',
    },
    '& .chips-options': { display: 'flex', gap: 6, flexWrap: 'wrap' },
    '& .chip': {
      backgroundColor: bg_grey, color: brown, fontWeight: 'bold', fontSize: 12,
    },
    '& .chip-selected': {
      backgroundColor: '#fff', color: brown, fontWeight: 'bold', fontSize: 14,
    },
    '& .submit': {
      cursor: 'pointer', background: '#FFA500', marginBottom: 8, border: 'none', borderRadius: 10, width: '100%', padding: '12px 14px', marginTop: 20,
    },
    '& .submit-text': {
      textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 16, color: '#fff',
    },
    '& .disabled': {
      background: '#f7cd81',
      cursor: 'auto',
    },
  },
  tooltip: {
    color: grey,
    fontSize: 14,
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 8,
    border: '1px solid #ccc',
  },
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    '& .loadingText': {
      fontSize: 14, color: 'grey', marginBottom: 20, marginTop: -40,
    },
  },
};

export default styles;
