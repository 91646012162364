export const localData = {
  USER_DATA: '',
  LAST_MATCH: '',
  TODAYS_MATCH: '',
  NEXT_MATCH: '',
  PAST_DATES: '',
  PAST_CANCELLED_DATES: '',
  GETTING_TO_KNOW_YOU: '',
  LIKED_PEOPLE: '',
  EXPLORE_PEOPLE: '',
  PROFILE_PICT: '',
  HOMEMADE_BREW: '',
  TOTAL_MATCH_COUNT: 0,
};
