import React, { useEffect, useState } from 'react';
import {
  Grid, Modal, Box, Fade, Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import images from '../../Configs/images';
import { getNextMatch, getTodaysMatch, getUserData } from '../../utils/storage';
import { bg_brown_light, brown } from '../../Configs/colors';
import { BaseModal } from '../../Elements';

export default function DateDayModal({ classes }) {
  const history = useHistory();
  const user = getUserData();
  const todaysMatch = getTodaysMatch();
  const nextMatch = getNextMatch();

  const upcomingDate = todaysMatch || nextMatch;

  const [needConfirmDate, setNeedConfirm] = useState(false);

  // const handleOpenDetailPeople = (people) => {
  //   setDetailPeople(people);
  // };

  const closeConfirmDate = () => {
    setNeedConfirm(false);
  };

  const submitConfirmDate = () => {
    history.push('/date-details');
  };

  const [openDateDayModal, setOpenDateDayModal] = useState(0);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    if (todaysMatch && !todaysMatch.askForReschedule) {
      setOpenDateDayModal(1);
    }
    if (user.userState === 5 && upcomingDate && !upcomingDate.askForReschedule) {
      setNeedConfirm(true);
    }
  }, []);

  useEffect(() => {
    if (openDateDayModal === 1) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
    return null;
  }, []);

  const handleNextModal = () => {
    // const docRefRating = doc(firestore, `/feedbackRatings/${auth.currentUser?.uid}`);
    // console.log(docRefRating);
    setOpenDateDayModal(openDateDayModal + 1);
  };

  const handleCloseModal = () => {
    setOpenDateDayModal(0);
    // updateHasOpenedPopup()
  };

  return (
    <>
      <Modal
        open={Boolean(openDateDayModal)}
        className={classes.dateModal}
      >
        <Grid container justifyContent="center" style={{ outline: 'none' }}>
          <Grid item lg={4} md={6} sm={8} xs={12} style={{ position: 'relative' }}>
            {openDateDayModal === 1
            && (
            <Box className="box-modal">
              <div className="box-content">
                <div style={{ textAlign: 'center' }}>
                  <img alt="kopi-date" src={images.Lightbulb} width="45" height="45" style={{ marginTop: -18 }} />
                </div>
                <div style={{ textAlign: 'center', position: 'relative', overflow: 'hidden' }}>
                  <div style={{ color: '#706a53', fontSize: '32px', fontWeight: '500' }}> It&apos;s date-day! </div>
                  <div style={{ fontSize: '14px', color: '#7d6e67' }}>
                    {' '}
                    Woo-hoo!🥳
                    <br />
                    {' '}
                    The time has arrived for you to meet your
                    <br />
                    {' '}
                    date!🎉 Here are two tips for you
                    {' '}
                  </div>
                  <img alt="kopi-date" src={images.People} style={{ width: '100%', marginTop: 20, marginBottom: -5 }} />
                  <Fade in timeout={3000}>
                    <img
                      alt="kopi-date"
                      src={images.Confetti}
                      style={{
                        position: 'absolute', top: 20, bottom: 20, right: 5, left: 5, display: showConfetti ? 'block' : 'none',
                      }}
                    />
                  </Fade>
                </div>
              </div>
              <div role="button" onKeyDown={null} tabIndex={0} className="box-footer" onClick={handleNextModal}>NEXT</div>
            </Box>
            )}
            {openDateDayModal === 2
            && (
            <Box className="box-modal">
              <div className="box-content">
                <div style={{ textAlign: 'center' }}>
                  <img alt="kopi-date" src={images.Lightbulb} width="45" height="45" style={{ marginTop: -18 }} />
                  <div style={{ color: '#887c6c', fontWeight: 'normal', fontSize: 18 }}> How to Enjoy Every Date </div>
                  <div style={{ color: '#706a53', fontSize: '32px', fontWeight: '500' }}> &apos;The 50-50 rule&apos; </div>
                  <div style={{ fontSize: '14px', color: '#7d6e67' }}>
                    For a better conversational flow, use the
                    <br />
                    50-50 ratio for talking and listening. A great
                    <br />
                    conversation should be made up of equal
                    <br />
                    sharing by both parties.
                  </div>
                  <img alt="kopi-date" src={images.TwoTips} style={{ width: '80%', marginTop: 20, marginBottom: -5 }} />
                </div>
              </div>
              <div role="button" onKeyDown={null} tabIndex={0} className="box-footer" onClick={handleNextModal}>NEXT</div>
            </Box>
            )}
            {openDateDayModal === 3
            && (
            <Box className="box-modal">
              <div className="box-content">
                <div style={{ textAlign: 'center' }}>
                  <img alt="kopi-date" src={images.Lightbulb} width="45" height="45" style={{ marginTop: -18 }} />
                  <div style={{ color: '#887c6c', fontWeight: 'normal', fontSize: 18 }}> A reminder </div>
                  <div style={{ color: '#706a53', fontSize: '32px', fontWeight: '500' }}> 3 Values </div>
                  <div style={{ fontSize: '14px', color: '#7d6e67' }}>
                    {' '}
                    To fully enjoy your Kopi Date, embark on your
                    <br />
                    {' '}
                    experience with our 3 values:
                    {' '}
                  </div>
                  <img alt="kopi-date" src={images.ThreeTips} style={{ width: '80%', marginTop: 10 }} />
                </div>
              </div>
              <div role="button" onKeyDown={null} tabIndex={0} className="box-footer" onClick={handleCloseModal}>CLOSE</div>
            </Box>
            )}
          </Grid>
        </Grid>
      </Modal>
      <BaseModal open={needConfirmDate} title="" noCancel actionFull bgColor={bg_brown_light} handleClose={closeConfirmDate} noSubmit>
        <div>
          <div style={{ color: brown, textAlign: 'center', fontSize: 22 }}>
            Congrats,
            {' '}
            {user?.signupInfo?.firstName}
            {' '}
            🥳
            <br />
            You&apos;ve got a date!
          </div>
          <div style={{
            color: brown, fontSize: 12, textAlign: 'center', marginTop: 14,
          }}
          >
            We searched high and low, far and wide, then finally
            landed on you two as our next Kopi Date of choice.
          </div>
          <img src={images.Horray} alt="Horray" style={{ margin: '20px 0' }} />
          <div style={{ color: '#000', fontSize: 12, textAlign: 'center' }}>
            The time is ripe - for you to take on this first date, and
            make it as meaningful and connection-filled as you can!
          </div>
          <div className="wide" style={{ marginTop: 12 }}>
            <Button
              id="btn-modal-submit-next-to-confirm-date"
              fullWidth
              style={{
                color: '#fff',
                backgroundColor: brown,
                textTransform: 'none',
                borderRadius: 8,
              }}
              size="large"
              onClick={submitConfirmDate}
            >
              View date details
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
