/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from 'react';
import { Widget } from '@typeform/embed-react';
import { BottomNav } from '../../Elements';

export default function ExplorePeople() {
  useEffect(() => {
    document.title = 'Kopi Date Experience - ExplorePeople';
  }, []);
  return (
    <>
      <div style={{ height: '100vh', /* background:'rgb(238, 234, 221)' */ backgroundColor: '#fef8ee' }}>
        <Widget id="gq7U7cCl" style={{ width: '100%', height: '90%' }} className="my-form" />
      </div>
      <BottomNav active={0} />
    </>
  );
}
