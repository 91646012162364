/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { StarRounded } from '@mui/icons-material';
import {
  Container, Grid, Tab, Tabs, Box,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { BottomNav } from '../../Elements';
import { amplytics } from '../../utils/helper';
import {
  getNextMatch, getPastCancelledDates, getPastDates, getTodaysMatch, getUserData,
} from '../../utils/storage';

export default function Dates({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Dates';
    amplytics('Dates View');
  }, []);

  const pastDates = getPastDates();
  const pastCancelledDates = getPastCancelledDates();
  const user = getUserData();
  const gender = user?.signupInfo?.gender;
  const nextMatch = getNextMatch();
  const todaysMatch = getTodaysMatch();

  const [activeTab, setActiveTab] = useState(1);
  const handleChangeTab = (e, tabN) => {
    setActiveTab(tabN);
  };

  const isUserNeverFillFeedback = (date) => {
    if (gender === 'Male' && !date?.maleFeedback) {
      return true;
    } if (gender === 'Female' && !date?.femaleFeedback) {
      return true;
    }
    return false;
  };

  const userFeedback = (date) => (gender === 'Male' ? date.maleFeedback : date.femaleFeedback);
  const upcomingDate = todaysMatch || nextMatch;
  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className={classes.root}>
          <Container>
            <div className="title">My Kopi Dates ☕️</div>
            {/* <div className="credit">
              Remaining credits:
              {' '}
              {user?.creditBalance || 0}
            </div> */}
          </Container>

          {upcomingDate
          && (
          <div className="next-match">
            <Container>
              <div className="flex align-items-center space-between">
                <div>
                  <div style={{ fontSize: 12 }}>Upcoming date 🥳</div>
                  <div className="match-with">
                    Kopi Date With &apos;
                    {gender === 'Male' ? upcomingDate.femaleAlias : upcomingDate.maleAlias}
                    &apos;
                  </div>
                  <div className="status">
                    <div>
                      <strong>
                        {moment(upcomingDate.date.seconds * 1000).tz('Asia/Singapore').format('D MMM')}
                        {' '}
                        .
                        {' '}
                      </strong>
                    </div>
                    <div>
                  &emsp;
                      <i>{user?.userState === 5 && 'Pending confirmation'}</i>
                      <i>{user?.userState === 6 && "Pending Partner's Confirmation"}</i>
                    </div>
                  </div>
                  <div style={{ marginTop: 16, display: 'flex' }}>
                    {user?.userState === 5
                      && (
                      <button className="green-btn btn" onClick={() => history.push('/date-details')}>
                        CONFIRM DATE
                      </button>
                      )}
                    <button className="brown-btn btn" onClick={() => history.push('/date-details')}>
                      VIEW DETAILS
                    </button>
                  </div>
                </div>
                <div>
                  {user?.userState === 5
                    ? (
                      <div className="reveal-location">
                        Confirm the date to reveal location
                      </div>
                    )
                    : (
                      <img
                        src={upcomingDate.locationImageLink}
                        alt="imagelink"
                        height="100"
                        width="100"
                        style={{ borderRadius: 8, objectFit: 'cover' }}
                      />
                    )}
                </div>
              </div>
            </Container>
          </div>
          )}

          {Boolean([2, 4].includes(user?.userState) && user.planType && user.price === 1)
          && (
          <div>
            <Container>
              <div className="adhoc">
                <div className="adhoc-title">Excited to go on another date 🎉</div>
                <div className="adhoc-desc">
                  Add an ad-hoc date now!
                </div>
                <button className="green-btn btn full" onClick={() => history.push('/adhoc')}>
                  GO FOR NEXT KOPI DATE
                </button>
              </div>
            </Container>
          </div>
          )}

          <Container style={{ marginTop: 20 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                className={classes.tabs}
                variant="fullWidth"
              >
                <Tab className="tab" label="Past dates" value={1} />
                <Tab className="tab" label="Cancelled dates" value={2} />
              </Tabs>
            </Box>

            <div hidden={activeTab !== 1} style={{ paddingBottom: 80 }}>
              {pastDates.length ? pastDates.map((date) => (
                <Box key={date.matchId} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
                  <div className="past-date-h">
                    <div className="partner">
                      {gender === 'Male' ? `'${date.femaleAlias}', ${date.gettingToKnowYouInfoMale?.headline}` : `'${date.maleAlias}', ${date.gettingToKnowYouInfoFemale?.headline}`}
                    </div>
                    {isUserNeverFillFeedback(date)
                    && (
                    // <div>
                      <button className="orange-btn btn" onClick={() => history.push(`/conclude/${date.matchId}`)}>
                        CONCLUDE DATE
                      </button>
                    // </div>
                    )}
                  </div>
                  <div className="date-info">
                    <div>
                      {moment(date.date.seconds * 1000).tz('Asia/Singapore').format('D MMM')}
                      {' '}
                      .
                    </div>
                    <div className="flex align-items-center" style={{ gap: 4 }}>
                      <div style={{ marginTop: 2 }}>
                        <StarRounded htmlColor="#606060" sx={{ fontSize: 14 }} />
                      </div>
                      <div>
                        {userFeedback(date) && userFeedback(date).conservationRatings
                          ? userFeedback(date).conservationRatings / 20
                          : 0}
                      </div>
                      <div>
                        .
                      </div>
                    </div>
                    <div
                      className="see-detail"
                      onClick={() => history.push(`/date-message/${date.matchId}`)}
                    >
                      <strong>View date message</strong>

                    </div>
                  </div>
                </Box>
              ))
                : (
                  <div className="no-data">
                    You’re all caught up
                  </div>
                )}
            </div>
            <div hidden={activeTab !== 2} style={{ paddingBottom: 80 }}>
              {pastCancelledDates.length ? pastCancelledDates.map((date, index) => (
                <Box key={date.matchId || index} sx={{ borderBottom: 1, borderColor: 'divider', py: 2 }}>
                  <div className="past-date-h">
                    <div className="mute">
                      {gender === 'Male' ? `'${date.femaleAlias}', ${date.gettingToKnowYouInfoMale?.headline}` : `'${date.maleAlias}', ${date.gettingToKnowYouInfoFemale?.headline}`}
                    </div>
                  </div>
                  <div className="date-info">
                    <div>Cancelled . </div>
                    <div>
                      {moment(date.date.seconds * 1000).tz('Asia/Singapore').format('D MMM')}
                    </div>
                  </div>
                </Box>
              )) : (
                <div className="no-data">
                  You’re all caught up
                </div>
              )}
            </div>
          </Container>
        </div>
        <BottomNav active={3} />
      </Grid>
    </Grid>
  );
}
