import { success2 } from '../../Configs/colors';

const styles = {
  root: {
    padding: '40px 0 56px',
    background: '#fef8f4',
    position: 'relative',
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& .benefits': {
      padding: '20px 20px',
      '& .benefit': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        color: success2,
        fontWeight: '800',
      },
    },
    '& .mute': {
      marginTop: 4,
      marginLeft: 4,
      color: '#555',
      fontSize: 14,
    },
    '& .progress': {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      '& .progress-bar': {
        background: '#f0cbb6',
        height: 10,
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#ffa06b',
        },
      },
    },
    '& .back': {
      position: 'absolute',
      left: 8,
      top: 12,
    },
    '& .title': {
      color: '#9e7666',
      fontWeight: '600',
      fontSize: 14,
    },
    '& .desc': {
      color: '#9e7666',
      fontWeight: 'bolder',
      fontSize: 28,
      marginTop: 6,
      overflowWrap: 'break-word',
      lineBreak: 'auto',
      whiteSpace: 'pre-line',
    },
    '& .center': {
      textAlign: 'center',
    },
    '& .right': {
      textAlign: 'right',
    },
    '& .info': {
      textAlign: 'left',
      color: '#606060',
      fontSize: 13,
      fontWeight: '500',
    },
    '& .divider': {
      marginTop: 20,
      marginBottom: 20,
    },
    '& .form-label': {
      color: '#a68c58',
      fontWeight: 'bold',
    },
    '& .head1': {
      color: '#9e7666',
      fontWeight: '600',
      fontSize: 14,
    },
    '& .head2': {
      color: '#000',
      fontWeight: '500',
      fontSize: 14,
    },
    '& .box': {
      background: '#fff',
      padding: '16px 4px',
      borderRadius: 16,
      marginTop: 20,
    },
    '& .consider': {
      '& .MuiFormControlLabel-label': {
        color: '#606060',
        fontWeight: '600',
        marginBottom: -5,
        fontSize: 12,
      },
    },
    '& .connect': {
      '& .MuiFormControlLabel-label': {
        color: '#606060',
        fontWeight: '600',
        fontSize: 12,
      },
    },
    '& .mt-2': {
      marginTop: 20,
    },
    '& .head-desc': {
      fontSize: 12,
      color: '#606060',
      marginTop: 10,
    },
  },
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },

};

export default styles;
