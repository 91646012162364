/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ChevronLeftRounded } from '@mui/icons-material';
import {
  Container, Divider, Grid, IconButton,
} from '@mui/material';

// import DateAdapter from '@mui/lab/AdapterDateFns'
import images from '../../Configs/images';
// import { getUser, } from '../../utils/storage';
// import { isiOS } from '../../utils/helpers';

export default function Waitlisted(props) {
  const { classes, history } = props;

  const [loading] = useState(false);

  useEffect(() => {
    document.title = 'Kopi Date Experience - Waitlisted';
  }, []);

  const handleBack = (e) => {
    e.preventDefault();
    history.replace('/');
  };

  const handleNext = () => {
    history.push('/home');
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh' }}>
        {loading
          ? (
            <div className={classes.loading}>
              <div className="loadingText">Making magic happen</div>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          ) : (
            <div className={classes.root}>
              <IconButton className="back" onClick={handleBack}><ChevronLeftRounded /></IconButton>

              <div className="top">
                <div className="title">YOU’RE IN THE WAITLIST</div>
                <div style={{ fontSize: 14, color: '#606060', marginTop: 5 }}>Please give us some time to review your application.</div>
                <div style={{
                  color: '#FFA500', fontSize: 14, fontWeight: 'bold', marginTop: 10,
                }}
                >
                  APPLICATIONS IN THE QUEUE: ~935
                </div>
                <img src={images.Horray} alt="about you" style={{ width: '70%', margin: '20px 0' }} />

                <Divider sx={{ width: '80%' }} />

                <div className="desc">What’s ahead</div>
                <div style={{ fontSize: 14, color: '#606060', marginTop: 5 }}>
                  Hop on a casual, documentary-style
                  <br />
                  onboarding session with us as we celebrate
                  <br />
                  you and your story! 🥰
                </div>
                <img src={images.Meet} alt="about you" style={{ width: '90%', marginTop: 20 }} />

              </div>
              <div style={{ paddingTop: 20, boxSizing: 'border-box', textAlign: 'center' }}>
                <Container>
                  <button
                    onClick={handleNext}
                    style={{
                      cursor: 'pointer', background: '#FFA500', marginBottom: 8, border: 'none', borderRadius: 10, width: '100%', padding: '12px 14px', marginTop: 20,
                    }}
                  >
                    <div style={{
                      textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 16, color: '#fff',
                    }}
                    >
                      VISIT DASHBOARD
                    </div>
                  </button>
                </Container>
              </div>
            </div>
          )}
      </Grid>
    </Grid>
  );
}
