const styles = {
  root: {
    padding: 8,
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .left-sect': {
      width: '45%',
      '& .coach-girl': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '16px 0 0 16px',
      },
    },
    '& .right-sect': {
      width: '65%',
      marginLeft: '-10%',
      background: '#F9F0D9',
      borderRadius: 16,
      padding: 16,
      paddingBottom: 32,
      boxSizing: 'border-box',
      position: 'relative',
      '& .head': { fontSize: 16, fontWeight: '700' },
      '& .desc': { fontSize: 14, fontWeight: '500', marginTop: 4 },
      '& .full-desc': { fontSize: 12, marginTop: 4 },
      '& .waitlist-desc': {
        position: 'absolute',
        bottom: 8,
        right: 16,
        fontSize: 12,
      },
    },
  },
};

export default styles;
