import React from 'react';
import { Button } from '@mui/material';

export default function OrangeBUtton({
  onClick, fullwidth, text, size, rounded, styles, outlined,
}) {
  return (
    <Button
      onClick={onClick}
      size={size || 'small'}
      fullWidth={fullwidth || false}
      style={{
        backgroundColor: outlined ? 'transparent' : 'orange',
        border: '1px solid orange',
        color: outlined ? 'orange' : '#fff',
        textTransform: 'none',
        borderRadius: rounded ? 25 : 8,
        ...styles,
      }}
    >
      {text}
    </Button>
  );
}
