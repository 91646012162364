import { brown } from '../../Configs/colors';

const styles = {
  root: {
    height: '100vh',
    paddingBottom: 80,
    overflow: 'auto',
    boxSizing: 'border-box',
    '& .head': {
      color: brown,
      fontSize: 24,
      fontWeight: '500',
      marginTop: 8,
    },
    '& .section-title': {
      color: '#000',
      fontWeight: 'bold',
      marginTop: 16,
      fontSize: 14,
    },
    '& .desc': {
      color: '#606060',
      fontWeight: '500',
      fontSize: 14,
    },
  },
};

export default styles;
