import { brown } from '../../Configs/colors';

const styles = {
  root: {
    padding: 8,
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .card-content': {
      marginTop: 8,
      background: '#f7f4ef',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 2,
      alignItems: 'center',
      marginBottom: 8,
      '&.free': {
        background: '#ffd561',
      },
      '& .left-content': {
        padding: '0 8px 0 24px',
        '& .title': {
          color: '#000',
          fontWeight: '500',
          fontSize: 14,
        },
        '& .desc': {
          color: '#000',
          fontWeight: '400',
          fontSize: 12,
          marginTop: 4,
        },
        '& .more-btn': {
          textTransform: 'none',
          color: '#000',
          padding: '6px 24px',
          border: 'none',
          borderRadius: 25,
          background: '#fff',
          textDecoration: 'none',
          display: 'inline-block',
          fontWeight: 'bold',
          fontSize: 13,
          marginTop: 12,
          marginLeft: -4,
          outline: 'none',
        },
        '& .locked': {
          gap: 8,
          fontSize: 12,
          color: brown,
          marginTop: 16,
        },
      },
    },

    '& .explore-card': {
      background: '#E1DC5D',
      borderRadius: 8,
      boxShadow: '1px 1px 8px rgba(0,0,0,.2)',
      overflow: 'hidden',
      margin: '8px 0',
    },
  },
};

export default styles;
