import { ChevronLeftRounded } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Component({ history }) {
  // console.log(classes, history);
  const location = useLocation();
  useEffect(() => {
    if (!location.state?.topicLink) {
      history.replace('/');
    }
  }, [location]);
  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div>
          <div style={{
            height: '6vh',
            background: '#282727',
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Button
              sx={{
                color: '#fff',
                fontWeight: '600',
              }}
              startIcon={(<ChevronLeftRounded />)}
              onClick={() => history.replace('')}
            >
              Back to Experience App
            </Button>
          </div>

          <object
            title="Kopi Date"
            data={location.state?.topicLink}
            style={{
              width: '100%',
              height: '94vh',
              overflow: 'auto',
              border: 'none',
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}
