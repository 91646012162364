export const admin = [
  'weilip1803@gmail.com',
  'zhiqun@kopidate.com',
  'jinglin@kopidate.com',
  'liuzhixin.sg@gmail.com',
  'kopidatesg@gmail.com',
  'hello@kopidate.com',
  'engineerkopidate@gmail.com',
  'andi.irsandi765@gmail.com',
];
