import { bg_brown, brown } from '../../Configs/colors';

const styles = {
  root: {
    '& .stepper': {
      display: 'flex',
      gap: 4,
      justifyContent: 'center',
      marginTop: 24,
    },
    '& .step': {
      background: '#7f7f7f',
      height: 6,
      width: 35,
      borderRadius: 3,
      '&.active': {
        background: '#fff',
      },
    },
    '& .step1': {
      background: bg_brown,
      margin: 20,
      borderRadius: 8,

      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',

      padding: 40,
      textAlign: 'center',

      minHeight: '60vh',
      '& .title': {
        color: brown,
        fontSize: 42,
        fontWeight: '500',
        lineHeight: '120%',
      },
    },
    '& .step2': {
      margin: 20,

      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',

      textAlign: 'center',

      minHeight: '60vh',

      '& .content': {
        color: brown,
        fontSize: 20,
        lineHeight: '140%',
        '& .highlighted': {
          color: bg_brown,
        },
      },
    },

    '& .step3': {
      margin: 20,

      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',

      textAlign: 'center',

      minHeight: '60vh',

      '& .content': {
        color: brown,
        fontSize: 20,
        lineHeight: '140%',
        '& .highlighted': {
          color: bg_brown,
        },
      },
    },

    '& .floating-act': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
    },
  },
};

export default styles;
