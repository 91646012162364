/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  Popper,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  doc, setDoc, Timestamp, collection, getDoc, getDocs, query as firestoreQuery, where, updateDoc,
} from 'firebase/firestore';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  auth, firestore,
} from '../../Configs/firebase';
import {
  getEnv,
  getGettingToKnowYou,
} from '../../utils/storage';
import images from '../../Configs/images';
import {
  BrownButton,
  ConfirmationModal, OrangeButton,
} from '../../Elements';
import { amplytics, reduceMonths } from '../../utils/helper';
import { brown } from '../../Configs/colors';

export default function MonthlyQuestions({ classes }) {
  const gettingToKnowYouInfo = getGettingToKnowYou();
  const history = useHistory();
  const env = getEnv();
  const [monthlyQuestions, setMonthlyQuests] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingThumbs, setLoadingThumbs] = useState(false);

  // useEffect(() => {
  //   document.title = 'Kopi Date Experience - Home';
  //   return null;
  // }, []);

  const getData = async () => {
    const today = new Date();
    today.setHours(23, 59, 59, 0);
    const lastHourToday = Timestamp.fromDate(today);
    const threeMonthAgo = reduceMonths(today, 3);
    const threeMonthAgoInTimestamp = Timestamp.fromDate(threeMonthAgo);
    // console.log(today, threeMonthAgo);

    const monthlyQuestref = collection(firestore, '/monthlyQuestions');
    const monthlyQuestQuery = firestoreQuery(monthlyQuestref, where('date', '<=', lastHourToday), where('date', '>=', threeMonthAgoInTimestamp));
    const monthlyQuestSnap = await getDocs(monthlyQuestQuery);

    const monthlyQuestData = monthlyQuestSnap.docs.map((docData) => {
      const data = {
        ...docData.data(),
        id: docData.id,
      };
      return data;
    });

    const questsWithQount = await Promise.all(monthlyQuestData.map(async (quest) => {
      const answerQountRef = collection(firestore, `monthlyQuestions/${quest.id}/answers`);
      const answers = await getDocs(answerQountRef);
      const answersData = answers.docs.map((docData) => ({
        ...docData.data(),
        userId: docData.id,
      })).sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0));
      return {
        count: answers.docs.length,
        answers: answersData,
        ...quest,
      };
    }));

    const questsWithAnswer = await Promise.all(questsWithQount.map(async (quest) => {
      const userAnswerQuery = doc(firestore, `monthlyQuestions/${quest.id}/answers/${auth.currentUser?.uid}`);
      const userAnswer = await getDoc(userAnswerQuery);
      return {
        answer: userAnswer.data(),
        ...quest,
      };
    }));

    // console.log(questsWithAnswer);
    setMonthlyQuests(questsWithAnswer);
    setLoadingThumbs(false);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleTo = (link, destiny) => () => {
    // amplytics(`Home tap to ${destiny || link}`);
    history.push(link);
  };

  const [answeredQuest, setAnsweredQuest] = useState(false);

  const [questAnswer, setQuestAnswer] = useState('');
  const sendAnswer = async ({ id, answer }) => {
    if (id && answer) {
      setLoading(true);

      const questRef = doc(firestore, `monthlyQuestions/${id}/answers/${auth.currentUser?.uid}`);

      await setDoc(questRef, {
        answer,
        userHeadlines: gettingToKnowYouInfo?.headline || '-',
      }).then(() => {
        // await setUserData(auth.currentUser);
        amplytics('Answered Monthly Question');
        setQuestAnswer('');
        setAnsweredQuest(true);
        getData();
      }).catch((err) => {
        alert(err.message);
        console.log(err);
      });

      setLoading(false);
    }
  };

  const handleToggleLike = async (likes, questId, userId) => {
    // console.log(likes, questId, userId);
    // setLoadingThumbs(true);
    // const iLikeIt = likes?.includes(auth.currentUser?.uid) || false;
    amplytics('Liking an answer');
    const questRef = doc(firestore, `monthlyQuestions/${questId}/answers/${userId}`);
    // let newLikes = [];

    // if (iLikeIt) {
    //   newLikes = likes.filter((uid) => uid !== auth.currentUser?.uid);
    // } else {
    const newLikes = [...likes, auth.currentUser?.uid];
    // }

    const newQuests = monthlyQuestions.map((quest) => {
      if (quest.id === questId) {
        const newQuestData = quest;
        const newAnswers = quest.answers.map((answer) => {
          if (answer.userId === userId) {
            return {
              ...answer,
              likes: newLikes,
            };
          } return answer;
        });
        newQuestData.answers = newAnswers;
        return newQuestData;
      } return quest;
    });
    setMonthlyQuests(newQuests);

    await updateDoc(questRef, {
      likes: newLikes,
    }).then(() => {

    }).catch((err) => {
      console.log('errLike', err);
      setLoadingThumbs(false);
    });
  };

  return (
    <div hidden={!monthlyQuestions?.length || !env.monthlyQuestion}>
      <div className={classes.communitySection}>
        <Container className="head">
          <div className="title">Community Talks</div>
          <IconButton onClick={handleTo('/feedback/BDQUSd6J', 'Community Talks')}>
            <img src={images.Thumbs} alt="Thumbs" width="40" height="40" />
          </IconButton>
        </Container>
        <Container>
          <div>
            {monthlyQuestions.map((quest) => (
              <div className="card" hidden={quest.hideQuestion} key={quest.id}>
                <img src={images.Logokd} alt="logo kd community" width="40" height="40" />
                <div className="date">{moment(quest.date.seconds * 1000).format('D MMM')}</div>
                <div className="quest">{quest.question}</div>
                <div className="replies">
                  {quest.count + 102}
                  {' '}
                  Replies
                  {' '}
                  <img
                    src={images.Info}
                    alt="info"
                    className="i"
                  />
                </div>
                <div hidden={quest.answer}>
                  <div className="field">
                    <TextField
                      placeholder="Share your thoughts"
                      className="fieldtext"
                      rows={3}
                      multiline
                      fullWidth
                      variant="outlined"
                      onChange={({ target }) => setQuestAnswer(target.value)}
                    />
                  </div>
                  <OrangeButton onClick={() => sendAnswer({ id: quest.id, answer: questAnswer })} text="SUBMIT" size="medium" rounded styles={{ padding: '4px 32px', fontWeight: 'bold' }} />
                  <div className="desc">Contribute to discover submission from the community! 🔎</div>
                </div>
                {(quest.answer && quest.answers?.length >= 10)
                && (
                  <div className="answers">
                    <div className="scrollable">
                      {quest.answers?.map((data, index) => (
                        <Fragment key={index}>
                          <AnswerCard index={index} data={data} quest={quest} handleToggleLike={handleToggleLike} />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                )}

                {(quest.answer && quest.answers?.length < 10)
                && (
                  <div className="thank-text">
                    Thank you for contributing your answer !
                  </div>
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>

      <ConfirmationModal
        open={answeredQuest}
        title="Thank you for contributing your answer"
        // desc="We will share our community sharing via email!"
        agreeText="Suggest next question"
        disagreeText="Done"
        handleClose={() => setAnsweredQuest(false)}
        handleAgree={handleTo('/feedback/R6AqtxBj', 'Suggest next question')}
      />
    </div>
  );
}

function AnswerCard({
  index, data, quest, handleToggleLike,
}) {
  const history = useHistory();

  const [clicked, setClicked] = useState(false);
  const [clapNum, setClapNum] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);

  const anchorEl = useRef(null);

  useEffect(() => {
    const numLikes = data?.likes?.filter((uid) => uid === auth.currentUser?.uid) || [];
    setClapNum(numLikes.length || 0);
  }, [data?.likes]);

  return (
    <>
      <div className="answer-card">
        {index + 1 === quest.answers?.length
        && (
        <div className="center">
          <div className="blur-card" />
        </div>
        )}
        {/* <Button sx={{ textTransform: 'none', color: brown, fontWeight: '500' }}>See all answers</Button> */}
        {/* </div> */}
        <div className="user-answer">
          {data.answer}
        </div>
        <div className="user-name one-line">
          {data.userHeadlines}
        </div>
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <div className="like-btn flex align-items-center">
            <Tooltip disableFocusListener title={clicked ? `+${clapNum}` : 'Clap'} placement="top">
              <IconButton
                onClick={() => {
                  handleToggleLike(data.likes || [], quest.id, data.userId);
                  setClicked(true);
                }}
                disableRipple
              >
                <img src={data.likes?.includes(auth.currentUser?.uid) ? images.Clap2 : images.Clap1} alt="clap" width="16" height="16" />
              </IconButton>
            </Tooltip>
            <Box sx={{ fontSize: 12, ml: 0.5, mt: 0.5 }}>{data.likes?.length || 0}</Box>
          </div>
        </Box>

        {/* Mobile clap */}
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
            <div className="like-btn flex align-items-center">
              <Tooltip title={clicked ? `+${clapNum}` : 'Clap'} placement="top">
                <IconButton
                  ref={anchorEl}
                  onClick={() => {
                    handleToggleLike(data.likes || [], quest.id, data.userId);
                    setClicked(true);
                    setOpenTooltip(true);
                  }}
                  disableRipple
                >
                  <img src={data.likes?.includes(auth.currentUser?.uid) ? images.Clap2 : images.Clap1} alt="clap" width="16" height="16" />
                </IconButton>
              </Tooltip>
              <Box sx={{ fontSize: 12, ml: 0.5, mt: 0.5 }}>{data.likes?.length || 0}</Box>
            </div>
          </ClickAwayListener>
          <Popper
            anchorEl={anchorEl.current}
            disablePortal
            open={openTooltip}
            role={undefined}
            transition
            placement="top"
            style={{
              position: 'relative',
              zIndex: 3,
            }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
              >
                <div style={{
                  color: '#fff',
                  backgroundColor: 'rgba(0,0,0,.6)',
                  padding: '4px 8px',
                  borderRadius: 6,
                  fontSize: 12,
                }}
                >
                  +
                  {clapNum}
                </div>
              </Grow>
            )}
          </Popper>
        </Box>

      </div>

      {(index < 12 && (index + 1) % 6 === 0 && index + 1 !== quest.answers?.length)
      && (
      <div className="answer-card">
        <div className="user-answer">
          Contribute for better community talks!
        </div>
        <BrownButton
          onClick={() => {
            history.push('/explore/topic', { topicLink: 'https://form.typeform.com/to/R6AqtxBj' });
            // handleTo('/feedback/R6AqtxBj', 'Suggest next question')
          }}
          text="Suggest next question"
          styles={{
            marginBottom: -32,
            marginTop: 4,
            padding: '4px 16px',
          }}
        />
      </div>
      )}
    </>
  );
}
