import axios from 'axios';
// TODO:
const sendSMS = ({
  receiver,
  message,
}) => {
  axios
    .post('https://asia-east2-kopi-date-dev.cloudfunctions.net/sendSMS', {
      data: {
        message,
        receiver,
      },
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      console.log('sms', response);
    })
    .catch((error) => {
      console.warn(error);
    });
};

export default sendSMS;
