import {
  brown, grey, bg_brown_light, success,
} from '../../Configs/colors';

const styles = {
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    position: 'fixed',
    zIndex: 999,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  root: {
    padding: '20px 0 80px',
    // background: '#fef8f4',
    position: 'relative',
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& .title': {
      color: brown,
      fontSize: 32,
      fontWeight: 'bold',
    },
    '& .credit': {
      color: grey,
      fontSize: 16,
      fontWeight: '500',
      marginTop: 8,
    },
    '& .next-match': {
      background: bg_brown_light,
      padding: '20px 0',
      marginTop: 20,
      borderRadius: 10,
      '& .match-with': {
        fontSize: 16,
        color: '#000',
        fontWeight: 'bold',
        marginTop: 8,
      },
      '& .status': {
        display: 'flex',
        fontSize: 12,
        marginTop: 4,
        color: brown,
        fontWeight: '500',
      },
      '& .reveal-location': {
        background: '#ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 18px',
        height: 100,
        width: 100,
        borderRadius: 8,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '500',
      },
    },
    '& .adhoc': {
      background: bg_brown_light,
      padding: 20,
      marginTop: 20,
      textAlign: 'center',
      borderRadius: 10,
      '& .adhoc-title': {
        fontSize: 14,
        fontWeight: '700',
        color: '#000',
      },
      '& .adhoc-desc': {
        fontSize: 14,
        color: '#606060',
        lineHeight: '140%',
        marginTop: 8,
      },
      '& .full': {
        width: '100%',
        marginTop: 20,
        padding: '8px 0',
        fontSize: 16,
      },
    },
    '& .green-btn': {
      background: success,
      marginRight: 10,
    },
    '& .brown-btn': {
      background: brown,
    },
    '& .orange-btn': {
      background: 'orange',
    },
    '& .btn': {
      borderRadius: 25,
      border: 'none',
      color: '#fff',
      fontSize: 11,
      fontWeight: 'bold',
      padding: '4px 16px',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    '& .past-date-h': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      justifyContent: 'space-between',
      '& .partner': {
        fontSize: 14,
        fontWeight: 'bold',
        color: brown,
        minWidth: '60%',
      },
      '& .mute': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#606060',
      },
    },
    '& .date-info': {
      display: 'flex',
      gap: 4,
      fontSize: 12,
      fontWeight: '500',
      color: '#606060',
      marginTop: 12,
      flexWrap: 'wrap',
      alignItems: 'center',
      '& .see-detail': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    '& .no-data': {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 40,
      textAlign: 'center',
    },
  },
  tabs: {
    '& :hover': {
      color: brown,
      opacity: 1,
    },
    '& .Mui-selected': {
      color: `${brown} !important`,
      fontWeight: 'bold',
    },
    '& .Mui-focusVisible': {
      backgroundColor: `${brown} !important`,
    },
    '& .tab': {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: brown,
    },
  },
  detailMatch: {
    '& .contents': {
      background: '#fff',
      marginBottom: -24,
      position: 'relative',
      zIndex: 2,
      borderRadius: 16,
      paddingTop: 20,
    },
    '& .x-btn': {
      position: 'absolute',
      right: 10,
      top: 10,
    },
    '& .close-btn': {
      background: brown,
      color: '#fff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      padding: '24px 0 16px',
    },
    '& .ttl': {
      color: brown,
      fontSize: 12,
      fontWeight: 'bold',
    },
  },
};
export default styles;
