import {
  brown,
  // success,
} from '../../Configs/colors';

const styles = {
  root: {
    paddingBottom: 80,
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& .head': {
      color: 'orange',
      fontSize: 16,
      fontWeight: '500',
    },
    '& .star': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
    },
    '& .head2': {
      color: brown,
      fontSize: 24,
      fontWeight: '700',
    },
    '& .form': {
      width: '100%',
      minHeight: '90vh',
      textAlign: 'center',
      paddingTop: 20,
    },
    '& .error-mssg': {
      fontSize: 12,
      color: 'salmon',
      marginBottom: 20,
      fontWeight: 'bold',
    },
    '& .onwards-btn': {
      cursor: 'pointer',
      background: '#FFA500',
      marginBottom: 8,
      border: 'none',
      borderRadius: 10,
      width: '100%',
      padding: '12px 14px',
      marginTop: 24,
    },
    '& .onwards-text': {
      textAlign: 'center',
      width: '100%',
      fontWeight: 'bold',
      fontSize: 16,
      color: '#fff',
    },
    '& .card-container': {
      width: '100%',
      marginTop: 42,
      marginBottom: 32,
    },
    '& .secure': {
      fontSize: 10,
      color: 'grey',
    },
  },
  loading2: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    '& .loadingText': {
      fontSize: 14, color: 'grey', marginBottom: 20,
    },
  },
};

export default styles;
