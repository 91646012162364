/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import {
  Autocomplete, Container,
  Divider, FormControl,
  FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField,
} from '@mui/material';
import { signOut } from '@firebase/auth';
import { LogoutRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { Link, useLocation } from 'react-router-dom';
import DateObject from 'react-date-object';
import { BaseModal, BottomNav, ConfirmationModal } from '../../Elements';
import { auth, firestore } from '../../Configs/firebase';
import images from '../../Configs/images';
import {
  clearStorage, getNextMatch, getTodaysMatch, getTotalMatchCount, getUserData, setUserData,
} from '../../utils/storage';
import { addDays, amplytics } from '../../utils/helper';
import {isMobile} from 'react-device-detect'


// import GuidePDF from '../../Assets/Guide.pdf';

export default function Settings({ classes }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Settings';
    amplytics('Settings View');

    if (isMobile) {
        window.location.assign(process.env.REACT_APP_DEEPLINK + `/mainapp/settings`)
    }

  }, []);

  const upcomingDate = getTodaysMatch() || getNextMatch();

  // console.log(upcomingDate);

  const radioStyle = {
    '&.Mui-checked': {
      color: '#9e7666',
    },
  };

  const twoWeeks = new DateObject().add(14, 'days').format('ddd DD MMM YYYY');
  const threeWeeks = new DateObject().add(21, 'days').format('ddd DD MMM YYYY');
  const label1 = '14 days ('.concat(twoWeeks, ')');
  const label2 = '21 days ('.concat(threeWeeks, ')');

  const userData = getUserData();
  const totalMatchCount = getTotalMatchCount()
  const isPaused = userData.isPaused || false;

  const [loading, setLoading] = useState(false);

  const [popUpPause, setOpenPopPause] = useState(false);
  const [pauseDays, setPauseDays] = useState(0);
  const [pauseReason, setPauseReason] = useState('');

  const [daysError, setDaysError] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.pauseCuration) {
      setOpenPopPause(location.state?.pauseCuration);
    }
  }, [location]);

  const handleTogglePauseCuration = async () => {
    if (!isPaused && !pauseDays) {
      setDaysError(true);
      return;
    }
    if (!isPaused && !pauseReason) {
      setReasonError(true);
      return;
    }

    setLoading(true);
    setOpenPopPause(false);
    const pausingLogs = userData.pausingLogs || [];
    const now = new Date();

    let newLog = {
      action: isPaused ? 'Unpaused' : 'Paused',
      actionTime: now,
        description: isPaused ? "Resumed by member" : "Paused by member"
    };
    if (pauseReason) newLog.pauseReason = pauseReason;

    if (pauseDays) {
        const pauseUntil = addDays(now, Number(pauseDays));
        newLog = {
            ...newLog,
            pauseUntil : pauseUntil
        }
    }

    const body = {
      pausingLogs: {
        ...pausingLogs,
        [+now]: newLog,
      },
      isPaused: !isPaused,
    };
    if (pauseDays) {
      const pauseUntil = addDays(now, Number(pauseDays));
      body.pauseUntil = pauseUntil;
    } else { body.pauseUntil = null; }
    body.pauseReason = pauseReason || null;

    // prevent user stuck in state 5 or 6 when resumption
    if (isPaused) {
        if (userData.userState == 5 || userData.userState == 6 || (userData.userState == 2 && totalMatchCount > 0)) {
            body.userState = 4
        }
    }

    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser.uid}`);

    await updateDoc(docRef, body)
      .then(async () => {
        await amplytics(`Completed ${isPaused ? 'unpause' : 'pause'} curation`);
        await setUserData(auth.currentUser);
      }).catch((err) => {
        alert(err.message);
        console.log(err);
      });
    setPauseDays('');
    setPauseReason('');
    setLoading(false);
  };

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // window.location.replace('/login');
        clearStorage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [logOutConfirm, setLC] = useState(false);

  const redirectTo = (url) => {
    // const linkArr = url.split('/');
    // const destiny = linkArr[linkArr.length - 1];
    // amplytics(`Home redirect to ${destiny}`);
    window.open(url, '_blank');
  };

  // const handleTo = (link) => () => {
  //   // amplytics(`Settings tap to ${link}`);
  //   history.push(link);
  // };

  const handleTogglePopUp = () => {
    if (!popUpPause) {
      if (isPaused) {
        amplytics('Resumption curation');
      } else if (!upcomingDate) {
        amplytics('Clicks on pause curation');
      } else {
        amplytics('Clicks on pause curation with error message');
      }
    }
    setOpenPopPause(!popUpPause);
  };

  const openGuide = () => {
    window.open('https://docs.google.com/presentation/d/e/2PACX-1vRKDVJdfBJEiwQjuJfvzcVvLr5UkG08BZycB6zIe9A864uKcEnTOKfWe8L-n0TqTGaRtZbQ1ix8EElB/pub?start=false&loop=false&delayms=3000&slide=id.p', '_blank');
  };

  const pauseReasons = [
    'Need a social break',
    'Need some time to know past date better',
    'Busy work period',
    'Traveling abroad',
    'Others (please specify): '];

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          {loading
            && (
            <div className={classes.loading}>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
            )}
          <div className={classes.root}>
            <Container>
              <div className="head">Setting</div>
              <div>
                <div id="btn-settings-account" className="action" onClick={() => redirectTo('kopidate.com/user/signup')}>
                  <img alt="person" src={images.Person} width="20" height="20" />
                  <div className="action-name">Your account</div>
                </div>
                <Divider className="divider" />
                <Link id="btn-settings-aboutme" className="action" to="/knowing-the-real-you">
                  <img alt="about" src={images.Bell} width="20" height="20" />
                  <div className="action-name">About me</div>
                </Link>
                <Divider className="divider" />
                {isPaused
                  ? (
                    <div id="btn-settings-to-resume-curation" className="action" onClick={handleTogglePopUp}>
                      <div className="manualbg-action-name">
                        <PlayArrowRounded htmlColor="#9e8469" style={{ fontSize: 12 }} />
                      </div>
                      <div className="action-name">Resume curations</div>
                    </div>
                  ) : (
                    <div id="btn-settings-to-pause-curation" className="action" onClick={handleTogglePopUp}>
                      <div className="manualbg-action-name">
                        <PauseRounded htmlColor="#9e8469" style={{ fontSize: 12 }} />
                      </div>
                      <div className="action-name">Pause curation</div>
                    </div>
                  )}
                <Divider className="divider" />
                <div id="btn-settings-to-faq" className="action" onClick={() => redirectTo('https://kopidate.com/faq')}>
                  <img alt="help" src={images.Ask} width="20" height="20" />
                  <div className="action-name">Help</div>
                </div>
                <Divider className="divider" />
                {/* <div id="btn-settings-to-faq" className="action" onClick={handleTo('/faq')}>
                  <img alt="FAQ" src={images.Ask} width="20" height="20" />
                  <div className="action-name">FAQ</div>
                </div>
                <Divider className="divider" /> */}
                <div hidden={!userData.planType}>
                  <div id="btn-settings-to-faq" className="action" onClick={openGuide}>
                    <img alt="Guide" src={images.Guide} width="20" height="20" />
                    <div className="action-name">Read Essential Tool Kit</div>
                  </div>
                </div>
                <div hidden={!userData.planType}>
                  <Divider className="divider" />
                </div>
              </div>
              <div>
                <div className="social-title">Latest Happenings</div>
                <div>
                  <IconButton id="btn-social-ig" onClick={() => redirectTo('https://www.instagram.com/kopi.date/')} className="social"><img alt="instagram" src={images.Instagram} width="18" height="18" /></IconButton>
                  <IconButton id="btn-social-fb" onClick={() => redirectTo('https://www.facebook.com/kopidateofficial/')} className="social"><img alt="facebook" src={images.Facebook} width="18" height="18" /></IconButton>
                  <IconButton id="btn-social-tt" onClick={() => redirectTo('https://www.tiktok.com/@kopidate')} className="social"><img alt="tiktok" src={images.Tiktok} width="18" height="18" /></IconButton>
                  <IconButton id="btn-social-tg" onClick={() => redirectTo('https://t.me/kdpickapair')} className="social"><img alt="telegram" src={images.Telegram} width="18" height="18" /></IconButton>
                </div>
              </div>
            </Container>
            <img src={images.Cloud} alt="cloud" className="cloud" />
            <img src={images.BlueCup} alt="blue-cup" className="blue-cup" />
            <div id="btn-settings-to-logout" onClick={() => setLC(true)} className="logout-btn">
              <LogoutRounded className="logout-icon" />
              <div className="action-name">Log out</div>
            </div>
          </div>
        </Grid>
      </Grid>
      <BottomNav active={4} />
      <ConfirmationModal title="Logout" open={logOutConfirm} handleClose={() => setLC(false)} handleAgree={handleLogOut} />
      <ConfirmationModal title="Resumption Account" open={popUpPause && isPaused} handleClose={handleTogglePopUp} handleAgree={handleTogglePauseCuration} />
      <ConfirmationModal
        title="You have an upcoming date"
        desc="You may pause your curation after your date has been completed"
        open={popUpPause && upcomingDate}
        handleClose={handleTogglePopUp}
        handleAgree={handleTogglePopUp}
        agreeText="Okay"
        noCancel
      />
      <BaseModal title="Pause Curation" open={popUpPause && !isPaused && !upcomingDate} handleClose={handleTogglePopUp} handleSubmit={handleTogglePauseCuration}>
        <FormControl component="fieldset" error={daysError}>
          <FormLabel component="legend">How many days & why you&apos;d like to pause your curations?</FormLabel>
          <RadioGroup
            aria-label="gender"
            value={pauseDays}
            name="radio-buttons-group"
            onChange={(e) => setPauseDays(e.target.value)}
            sx={{ mt: 2 }}
          >
            <FormControlLabel value={14} control={<Radio size="small" sx={radioStyle} />} label={label1} />
            <FormControlLabel value={21} control={<Radio size="small" sx={radioStyle} />} label={label2} />
            {/* <FormControlLabel
            value={60} control={<Radio size="small" sx={radioStyle} />} label="60 days" /> */}
          </RadioGroup>
        </FormControl>
        <div style={{ fontSize: 16, marginTop: 14, marginBottom: 8 }}>
          Why you&apos;d like to pause?
        </div>
        <Autocomplete
          freeSolo
          options={pauseReasons}
          error={reasonError}
          onChange={(e, value) => setPauseReason(value)}
          renderInput={(params) => <TextField {...params} error={reasonError} fullWidth size="small" sx={{ mb: 1 }} placeholder="Select Reason" />}
        />
        <div style={{ marginTop: 6, fontSize: 14, textAlign: 'center' }}>
          Need to pause for longer? Write in to us at
          {' '}
          <a href="mailto:hello@kopidate.com?subject=I want to pause my account for more than 60 days" target="_blank" style={{ fontSize: 14, textDecoration: 'none' }} rel="noreferrer">hello@kopidate.com</a>
        </div>
      </BaseModal>
    </>
  );
}
