import { Container } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth, firestore } from '../../Configs/firebase';
import images from '../../Configs/images';
import { ConfirmationModal } from '../../Elements';
import {
  getLastMatch, getNextMatch, getTodaysMatch, getUserData, setUserData,
} from '../../utils/storage';

export default function Component() {
  const history = useHistory();
  const user = getUserData();
  const nextMatch = getNextMatch();
  const todaysMatch = getTodaysMatch();
  const lastMatch = getLastMatch();

  const gender = user?.signupInfo?.gender;

  const isUserNeverFillFeedback = () => {
    if (gender === 'Male' && ((todaysMatch && !todaysMatch?.maleFeedback) || (lastMatch && !lastMatch?.maleFeedback))) {
      return true;
    } if (gender === 'Female' && ((todaysMatch && !todaysMatch?.femaleFeedback) || (lastMatch && !lastMatch?.femaleFeedback))) {
      return true;
    }
    return false;
  };

  const handleRedirect = (link) => () => {
    // const linkArr = link.split('/');
    // const destiny = linkArr[linkArr.length - 1];
    // amplytics(`Home redirect to ${destiny}`);
    window.open(link, '_blank');
  };

  const handleTo = (link) => () => {
    // amplytics(`Home tap to ${destiny || link}`);
    history.push(link);
  };

  const [confirmUnlock, setConfirmUnlock] = useState(false);

  const handleToggleOpenConfirm = () => {
    setConfirmUnlock(!confirmUnlock);
  };

  const openQuoteOfTheDate = async () => {
    handleToggleOpenConfirm();

    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser?.uid}`);

    await updateDoc(docRef, {
      unlockQuote: true,
    }).then(async () => {
      await setUserData(auth.currentUser);
    }).catch((err) => {
      alert(err.message);
      console.log(err);
    });
  };

  return (
    <>
      <div className="actions-section">
        <Container>
          <div className="title">Actions</div>
        </Container>
        <div className="actions">
          {Boolean(todaysMatch) && <img id="action-to-chat" onClick={handleTo('/chat')} className="action" alt="ETA" src={images.Eta} />}
          {Boolean(todaysMatch) && <img id="action-to-kit" onClick={handleTo('/kit')} className="action" alt="Kit" src={images.Kit} />}
          {isUserNeverFillFeedback()
                      && <img id="action-to-conclude" onClick={handleTo(`/conclude/${todaysMatch?.matchId || lastMatch.matchId}`)} alt="Conclude" className="action" src={images.Conclude} />}
          {(user?.userState === 2 && !user?.onboarded && !lastMatch)
                      && <img id="action-to-boarded" onClick={handleRedirect('https://kopidate.com/user/welcome')} alt="Boarded" className="action" src={images.GetOnboarded} />}
          <img id="action-to-referral" onClick={handleTo('/referral')} alt="Earn" className="action" src={images.Earn} />
          {(!todaysMatch && !nextMatch)
                      && <img id="actions-to-availability" onClick={handleTo('/availability')} className="action" alt="Availability" src={images.Availability} />}
          <img id="actions-to-share-feedback" onClick={handleTo('/share-feedback')} className="action" alt="Win" src={images.Win} />
          {(!user.unlockQuote && lastMatch)
                      && <img id="actions-to-unlock-quote" onClick={handleToggleOpenConfirm} alt="Quote" className="action" src={images.Quote} style={{ marginTop: '-3%' }} />}
        </div>
      </div>
      <ConfirmationModal open={confirmUnlock} title="Unlock Quote of the Date" desc="Are you sure?" handleClose={handleToggleOpenConfirm} handleAgree={openQuoteOfTheDate} />
    </>
  );
}
