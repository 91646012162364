import React from 'react';
import { Button } from '@mui/material';
import { brown } from '../../Configs/colors';

export default function BrownButton({
  onClick, fullwidth, text, size, rounded, styles,
}) {
  return (
    <Button
      onClick={onClick}
      size={size || 'small'}
      fullWidth={fullwidth || false}
      style={{
        color: '#fff', backgroundColor: brown, textTransform: 'none', borderRadius: rounded ? 25 : 8, ...styles,
      }}
    >
      {text}
    </Button>
  );
}
