import { Container, IconButton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../Configs/firebase';
import images from '../../Configs/images';
import { getUserData } from '../../utils/storage';

export default function Component({ classes }) {
  const history = useHistory();
  // console.log(classes, history);
  const user = getUserData();
  return (
    <div className={classes.quizSection}>
      <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="title">Find out more about yourself</div>
        <IconButton onClick={() => history.push('/explore/topic', { topicLink: 'https://form.typeform.com/to/cdudGUqD' })}>
          <img src={images.Thumbs} alt="Thumbs" width="40" height="40" />
        </IconButton>
      </Container>
      <div>
        <div className="cards">

          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 0 }}>
            <div
              className="card"
              onClick={() => history.push('/explore/topic', { topicLink: `https://form.typeform.com/to/tKLr6xQi#name=${user?.signupInfo?.fullName}&uid=${auth.currentUser?.uid}` })}
            >
              <img
                src={images.Attachment}
                alt="card"
                className="card-img"
              />
              <div className="game-desc">What&apos;s your attachment style?</div>
            </div>
            <div className="card" onClick={() => history.push('/explore/topic', { topicLink: `https://form.typeform.com/to/hhxSu97T#name=${user?.signupInfo?.fullName}&uid=${auth.currentUser?.uid}` })}>
              <img
                src={images.Datingpersonality}
                alt="card"
                className="card-img"
              />
              <div className="game-desc">Quiz: What&apos;s Your Dating Personality Type</div>
            </div>
            <div className="card" onClick={() => history.push('/explore/topic', { topicLink: 'https://form.typeform.com/to/KrCLEnQP' })}>
              <img
                src={images.Solokd}
                alt="card"
                className="card-img"
              />
              <div className="game-desc">The Solo Kopi Date Experience</div>
            </div>
            <div className="card" onClick={() => history.push('/explore/topic', { topicLink: 'https://form.typeform.com/to/s2zS59D4' })}>
              <img
                src={images.Controversial}
                alt="card"
                className="card-img"
              />
              <div className="game-desc">Quiz: How Controversial Are You?</div>
            </div>
            <div className="card" onClick={() => history.push('/explore/topic', { topicLink: 'https://form.typeform.com/to/PpP2KkZ0' })}>
              <img
                src={images.Myths}
                alt="card"
                className="card-img"
              />
              <div className="game-desc">Quiz: Debunk 5 Dating Myths</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
