import { brown } from '../../Configs/colors';

const styles = {
  nav: {
    '& .MuiBottomNavigationAction-label': {
      fontSize: 8,
      fontWeight: '500',
      color: '#b2b2b2 !important',
    },
    '& .Mui-selected': {
      color: `${brown} !important`,
      fontSize: '8px !important',
    },
    '& .MuiBadge-standard': {
      fontSize: '8px !important',
      height: 14,
      minWidth: 12,
      padding: '0 4px !important',
      top: 4,
      backgroundColor: 'salmon !important',
    },
  },
};
export default styles;
