/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-danger */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */

import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  SwipeableDrawer,
  Box,
  Divider,
} from '@mui/material';
import {
  EmailIcon,
  TelegramIcon,
  WhatsappIcon,
} from 'react-share';
import { TikTok } from 'react-tiktok';
import {
  getEnv,
  getReferralInfo,
  getUserData,
} from '../../utils/storage';
import images from '../../Configs/images';
import {
  BottomNav,
} from '../../Elements';
import MonthlyQuestions from '../../Fragments/MonthlyQuestions';
import Recommendation from '../../Fragments/Recommendation';
import ReferralBlock from '../../Fragments/ReferralBlock';
import KitBlock from '../../Fragments/KitBlock';
import QuizesAndGames from '../../Fragments/QuizesAndGames';
import Articles from '../../Fragments/Articles';
import QuoteOfTheDate from '../../Fragments/QuoteOfTheDate';
import ExploreTopic from '../../Fragments/Explore';
import UnderstandYourself from '../../Fragments/UnderstandYourself';
import StatusBar from '../../Fragments/StatusBar';
import Actions from '../../Fragments/Actions';
import Profile from '../../Fragments/Profile';
import DateDayModal from '../../Fragments/DateDayModal';
import { amplytics } from '../../utils/helper';
import BannerMobile from '../../Fragments/BannerMobile';

export default function Home({ classes }) {
  const user = getUserData();
  const referralInfo = getReferralInfo();
  const env = getEnv();
  const message = `Have%20you%20tried%20Kopi%20Date?%20Sign%20up%20with%20my%20promo%20code:%20${referralInfo?.promocode}%20and%20you%20will%20get%20off%20the%20waitlist,%20PLUS%20a%20VIP%20pass!%20%F0%9F%A4%9F%F0%9F%8F%BB%20Come%20check%20it%20out!%20https://kopidate.com`;

  const [openToShare, setOpenToShare] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleRedirect = (link) => () => {
    window.open(link, '_blank');
  };
  const toggleOpenToShare = () => {
    if (!openToShare) {
      amplytics('Clicks on referral');
    }
    setOpenToShare(!openToShare);
  };

  useEffect(() => {
    amplytics('Home View');
    setLoading(false);
    document.title = 'Kopi Date Experience - Home';
  }, []);

  return (
    <>

      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh', overflow: 'hidden' }}>
          <BannerMobile />
          {loading
            ? (
              <div className={classes.loading}>
                <img alt="loading" src={images.Loading} width="100" height="100" />
              </div>
            )
            : (
              <div className={classes.root}>
                <DateDayModal />
                <div className={classes.sectionTwo}>
                  <Profile />

                  <StatusBar />

                  <Actions />
                </div>

                <ReferralBlock toggleOpenToShare={toggleOpenToShare} />
                <KitBlock />
                <Divider sx={{ border: '2px solid #f3f3f3', mt: 3 }} />

                <div hidden={env.recommendPeople === false}>
                  <Recommendation />
                  <Divider sx={{ border: '2px solid #f3f3f3', mt: 2 }} />
                </div>

                <div>
                  <QuizesAndGames />
                  <Divider sx={{ border: '2px solid #f3f3f3', mt: 2, mb: 1 }} />
                </div>

                <div hidden={env.understandYourself === false || user.userState === 1 || !user.userState}>
                  <UnderstandYourself />
                </div>
                <Divider sx={{ border: '2px solid #f3f3f3', mt: 1 }} />

                <div hidden={env.dessertForTheSoul === false}>
                  <Articles handleRedirect={handleRedirect} />
                  <Divider sx={{ border: '2px solid #f3f3f3', mt: 2 }} />
                </div>

                <MonthlyQuestions />

                <div>
                  <ExploreTopic />
                </div>

                <div>
                  <Divider sx={{ border: '2px solid #f3f3f3', mb: 1 }} />
                  <TikTok url={`https://www.tiktok.com/@kopidate/video/${env.tiktokvideo}`} />
                  <Divider sx={{ border: '2px solid #f3f3f3' }} />
                </div>

                <QuoteOfTheDate />

                <SwipeableDrawer
                  anchor="bottom"
                  open={openToShare || false}
                  onClose={toggleOpenToShare}
                >
                  <Box
                    sx={{ width: 'auto', textAlign: 'center' }}
                    role="presentation"
                  >
                    <div style={{
                      marginTop: 10,
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#000',
                    }}
                    >
                      Share using
                    </div>
                    <div style={{
                      padding: '18px 20px 40px',
                      display: 'flex',
                      gap: 4,
                      justifyContent: 'center',
                    }}
                    >
                      <WhatsappIcon size={45} round onClick={handleRedirect(`https://api.whatsapp.com/send?text=${message}`)} />
                      <TelegramIcon size={45} round onClick={handleRedirect(`https://t.me/share/url?url={https://kopidate.com}&text=${message}`)} />
                      <a href={`sms:&body=${message}`}>
                        <EmailIcon size={45} round />
                      </a>
                    </div>
                  </Box>
                </SwipeableDrawer>
                <BottomNav active={0} />
              </div>
            )}
        </Grid>
      </Grid>
    </>
  );
}
