const styles = {
  quotesSection: {
    padding: '8px 0 8px 8px',
    marginTop: 8,
    position: 'relative',
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .quotes': {
      marginTop: 10,
      display: 'flex',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      position: 'relative',
      '& .quote-card': {
        display: 'flex',
        flexWrap: 'nowrap',
        padding: 0,
        gap: 8,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
      '& div.quote': {
        borderRadius: 10,
        objectFit: 'cover',
        backgroundColor: '#fef8f4',
        color: '#9e7666',
        fontWeight: '400',
        width: 250,
        padding: '20px 20px 34px',
        position: 'relative',
      },
      '& .author': {
        position: 'absolute',
        bottom: 8,
        left: 20,
        fontSize: 11,
        textTransform: 'uppercase',
        letterSpacing: 2,
      },
      '& .date': {
        position: 'absolute',
        bottom: 8,
        right: 20,
        fontSize: 11,
        color: '#b2b2b2',
        textTransform: 'uppercase',
      },
      '& .quote-header': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
};

export default styles;
