const styles = {
  root: {
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& .flexRow': {
      display: 'flex',
      gap: 8,
      marginTop: 14,
      '& .list-title': {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#9e7666',
      },
      '& .list-value': {
        fontSize: 12,
        color: '#606060',
      },
    },
  },
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
};

export default styles;
