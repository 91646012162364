import { bg_brown } from '../../Configs/colors';

const styles = {
  dateModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 20px',
    outline: 'none',
    '& .box-modal': {
      width: '100%',
      alignSelf: 'center',
      outline: 'none',
      border: 'none',
      '& .box-content': {
        backgroundColor: bg_brown,
        borderRadius: 12,
        position: 'relative',
        zIndex: 1,
      },
      '& .box-footer': {
        backgroundColor: '#c4b780',
        borderRadius: 12,
        marginTop: -17,
        position: 'relative',
        zIndex: 0,
        textAlign: 'center',
        padding: '29px 0 14px',
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
      },
      '& .btn-next': {
        fontSize: 24,
        backgroundColor: '#c4b780',
        borderColor: '#c4b780',
        borderStyle: 'solid',
        color: '#fffdee',
        fontWeight: 'bolder',
        marginBottom: 10,
        borderRadius: 10,
      },
    },
  },
};

export default styles;
