/* eslint-disable */
import { React } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, IconButton } from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import '../style.css';
import images from '../../../Configs/images';

export default function TitleScreen(props) {
  const { history } = props;

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className="root title-screen-container">
          <div className="wrapper">
            <div className="img-background">
              <img src={images.QuestionGameBanner} alt="" />
            </div>
            <h1>30 Absurd Questions</h1>
            <p>The only first-date toolkit you’ll ever need!</p>
            <Link to="/start-screen" className="btn">
              PLAY GAME
            </Link>
            <div className="footer-text">
              © KOPI DATE 2022. All rights reserved.
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
