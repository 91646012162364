import React from 'react';
import { useHistory } from 'react-router-dom';
import images from '../../Configs/images';
import { OrangeButton } from '../../Elements';

export default function Component({ classes, toggleOpenToShare }) {
  // console.log(classes);
  const history = useHistory();
  return (
    <div className={classes.referralSection}>
      <div className="container">
        <div style={{ width: '65%' }}>
          <div className="highlight">
            Enjoy bonus perks! 🎉
          </div>
          <div className="desc">
            Give
            <b> 30% OFF</b>
            {' '}
            your friend&apos;s first experience! Enjoy
            <b> 1 FREE date </b>
            with every successfully referred friend.
          </div>
          <div className="flex" style={{ gap: 4 }}>
            <OrangeButton text="FIND OUT MORE" rounded outlined styles={{ padding: '4px 8px', fontSize: 10, fontWeight: 600 }} onClick={() => history.push('/referral')} />
            <OrangeButton text="SHARE CODE" rounded outlined styles={{ padding: '4px 8px', fontSize: 10, fontWeight: 600 }} onClick={toggleOpenToShare} />
          </div>
        </div>
        <img
          src={images.Referralpic}
          className="referral-pict"
          alt="friend"
        />
      </div>
    </div>
  );
}
