import { brown } from '../../Configs/colors';

const styles = {
  root: {
    paddingBottom: 56,
  },
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '95vh',
  },
  sectionTwo: {
    backgroundColor: '#fef8f4',
    borderRadius: 18,
    padding: '8px 8px 20px',
    '& .profile-section': {
      backgroundColor: '#f9efe8',
      borderRadius: 18,
      position: 'relative',
      minHeight: 180,
    },
    '& .actions-section': {
      marginTop: 20,
      '& .actions': {
        marginTop: 10,
        display: 'flex',
        overflow: 'auto',
        gap: 4,
        '&::-webkit-scrollbar': {
          display: 'none',
          scrollbarWidth: 'none',
        },
        '& .action': {
          width: '23%',
          objectFit: 'contain',
          cursor: 'pointer',
        },
      },
      '& .title': {
        color: '#9e7666',
        fontSize: 16,
        fontWeight: '500',
      },
      '& .button-card': {
        backgroundColor: '#f9efe8',
        borderRadius: '50px 50px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 0 8px',
        position: 'relative',
        '& .button-text': {
          fontSize: 10,
          color: brown,
          fontWeight: '500',
          marginTop: 5,
          textAlign: 'center',
        },
      },
      '& .special-border': {
        backgroundColor: '#fff',
        border: '2px solid #ff9b5f',
        boxSizing: 'border-box',
        borderRadius: '50px 50px 20px 20px',
        position: 'relative',
        padding: 2,
        '& .conclude-btn-card': {
          borderRadius: '48px 48px 18px 16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 0 4px',
          backgroundColor: '#ff9b5f',
        },
        '& .button-text': {
          fontSize: 10,
          color: '#fff',
          fontWeight: '500',
          marginTop: 5,
          textAlign: 'center',
        },
      },
    },
  },
  sectionThree: {
    padding: 8,
    marginTop: 8,
    position: 'relative',
    '& .title': {
      color: '#9e7666',
      fontSize: 16,
      fontWeight: '500',
    },
    '& .see-all': {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 8,
      top: 6,
      color: '#9e7666',
      fontSize: 14,
      fontWeight: '400',
    },
    '& .articles': {
      marginTop: 10,
      display: 'flex',
      overflow: 'auto',
      gap: 4,
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
    },
    '& .article': {
      width: '43%',
      borderRadius: 10,
      objectFit: 'contain',
      cursor: 'pointer',
    },
  },
  sectionFour: {
    padding: '8px 0 8px 8px',
    marginTop: 8,
    position: 'relative',
    '& .title': {
      color: '#9e7666',
      fontSize: 16,
      fontWeight: '500',
    },
    '& .quotes': {
      marginTop: 10,
      display: 'flex',
      overflow: 'auto',
      gap: 4,
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
    },
    '& .quote': {
      borderRadius: 10,
      display: 'inline-block',
      width: '70%',
      objectFit: 'contain',
    },
  },
};
export default styles;
