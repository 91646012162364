/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Button, Container, Divider, Grid, IconButton,
} from '@mui/material';
import { ChevronLeft, CloseRounded, LocationOnOutlined } from '@mui/icons-material';
import moment from 'moment-timezone';
import { httpsCallable } from 'firebase/functions';
import { brown, grey, mute } from '../../Configs/colors';
import images from '../../Configs/images';
import { BaseModal, BottomNav } from '../../Elements';
import {
  getNextMatch, getTodaysMatch, getUserData, setUserData,
} from '../../utils/storage';
// import { convertTZ, getHour, getMinute } from '../../utils/helper';
import { auth, fFunction } from '../../Configs/firebase';
import { amplytics, reduceDays } from '../../utils/helper';

export default function DateDetails({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Date Details';
    amplytics('DateDetails View');
  }, []);

  const nextMatch = getTodaysMatch() || getNextMatch();
  const user = getUserData();
  const [match, setMatch] = useState({});
  const [loading, setLoading] = useState(false);

  const now = new Date();
  const dateDay = new Date(nextMatch.date?.seconds * 1000);
  const threeDaysBeforeDate = reduceDays(dateDay, 3);
  const canReschedule = now < threeDaysBeforeDate;

  const [readMore, setReadMore] = useState(false);

  const gender = user?.signupInfo?.gender;

  // console.log(nextMatch);

  useEffect(() => {
    if (nextMatch) {
      setMatch(nextMatch);
    }
  }, []);

  const iHaveConfirmed = () => {
    if ((gender === 'Male' && nextMatch.maleConfirmation) || (gender === 'Female' && nextMatch.femaleConfirmation)) {
      return true;
    }
    return false;
  };

  // const needDateConfirm = () => {
  //   if (gender === 'Male' && !nextMatch?.maleConfirmation) {
  //     return true;
  //   } if (gender === 'Female' && !nextMatch?.femaleConfirmation) {
  //     return true;
  //   }

  //   return false;
  // };

  const renderPersonality = () => {
    if (gender === 'Male') {
      return match.femalePersonality;
    }
    return match.malePersonality;
  };

  const [popupConfirm, setPopupConfirm] = useState(false);
  const [sureCheck, setSureCheck] = useState(false);

  const handleTogglePopup = () => {
    setPopupConfirm(!popupConfirm);
  };

  const handleToggleCheck = (e) => {
    setSureCheck(e.target.checked);
  };

  const submitConfirmDate = () => {
    setLoading(true);
    // if (gender === 'Male') {
    const confirmForUser = gender === 'Male' ? 'confirmMatchMale' : 'confirmMatchFemale';
    const callable = httpsCallable(fFunction, confirmForUser);
    callable({ matchId: match.matchId, isAdmin: false })
      .then(async (data) => {
        // console.log(data);
        await amplytics('Date Confirmed');
        await setUserData(auth.currentUser);
        history.replace('/');
        setLoading(false);
      })
      .catch((err) => {
        alert(`${err?.toString()}Please email us at hello@kopidate.com with screenshot, thank you 🙏🏼`);
        setLoading(false);
      });
    // }
    // else {
    //   const callable = httpsCallable(fFunction, 'confirmMatchFemale');
    //   callable({ matchId: match.matchId, isAdmin: false })
    //     .then(async (data) => {
    //       console.log(data);
    //       await setUserData(auth.currentUser);
    //       history.replace('/');
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       alert(`${err?.toString()}Please email us
    // at hello@kopidate.com with screenshot, thank you 🙏🏼`);
    //       setLoading(false);
    //     });
    // }
    handleTogglePopup();
  };

  const handleClose = () => {
    history.goBack();
  };

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const [confirm, setConfirm] = useState(false);
  const toggleConfirmation = () => {
    setConfirm(!confirm);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} sx={{ background: '#fff', overflow: 'hidden' }}>
          {loading
            ? (
              <div className={classes.loading}>
                <img alt="loading" src={images.Loading} width="100" height="100" />
              </div>
            )
            : (
              <div style={{ position: 'relative' }} className={classes.root}>
                <Container>
                  <IconButton sx={{ position: 'absolute', right: 4, top: 10 }} onClick={handleClose}>
                    <CloseRounded />
                  </IconButton>
                  <IconButton sx={{ position: 'absolute', left: 4, top: 10 }} onClick={handleClose}>
                    <ChevronLeft />
                  </IconButton>
                  <div style={{ padding: '20px 20px 80px' }}>
                    <div style={{
                      textAlign: 'center', fontSize: 22, color: grey, fontWeight: '500',
                    }}
                    >
                      You&apos;ve got a date!
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <img src={images.Horray2} alt="Horray" style={{ margin: '20px 0', width: '50%' }} />
                      <div style={{ fontSize: 14, color: brown, fontWeight: '500' }}>Are you ready for an adventure? ☕️</div>
                    </div>
                    <div style={{ color: '#000', fontSize: 12, marginTop: 18 }}>
                      We found you someone exciting in our community!
                      Your upcoming date details are:
                    </div>
                    <div style={{ color: brown, fontSize: 12, marginTop: 8 }}>
                      #
                      {match.matchId || 'No date'}
                    </div>

                    <div className="flexRow">
                      <div className="icon-list">
                        <img alt="calendar" src={images.Calendar} height="14" width="14" />
                      </div>
                      <div>
                        <div className="list-title">Date & Time</div>
                        <div className="list-value">
                          {moment(match.date?.seconds * 1000).tz('Asia/Singapore').format('Do MMM, dddd')}
                          {' '}
                          {nextMatch.time?.toUpperCase()}
                        </div>
                      </div>
                    </div>

                    <Divider sx={{ mt: 1 }} />

                    <div className="flexRow">
                      <div className="icon-list">
                        <img alt="wand" src={images.Wand} height="14" width="14" />
                      </div>
                      <div>
                        <div className="list-title">Your Kopi Date is someone who is</div>
                        <div className="list-value">{renderPersonality()}</div>
                      </div>
                    </div>

                    {match?.postalCode === 307645
                    && (
                    <>
                      <Divider sx={{ mt: 1 }} />
                      <div className="flexRow">
                        <div className="icon-list">
                          <LocationOnOutlined htmlColor="#9e7666" fontSize="small" />
                        </div>
                        <div>
                          <div className="list-title">Directions</div>
                        </div>
                      </div>
                      <div style={{ marginTop: 4 }}>
                        <iframe
                          style={{ height: 315 }}
                          src="https://www.youtube.com/embed/ZR-T_9qTKBg"
                          title="Celines Gelato"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </>
                    )}

                    {user.userState === 5
                  && (
                  <>
                    <div className="flexRow" style={{ marginTop: 20 }}>
                      <div>⏰</div>
                      <div style={{ color: '#000', fontSize: 11, wordSpacing: 0 }}>
                        <strong>Confirm your date ASAP</strong>
                        {' '}
                        to prevent your date from waiting. Need help? See below.
                      </div>
                    </div>

                    {/* <div style={{
                      display: 'block', margin: 'auto', fontSize: '10px', textAlign: 'center',
                    }}
                    >
                      Need help? See below.
                    </div> */}

                    <div style={{ marginTop: 12 }}>
                      <Button
                        id="btn-modal-submit-confirm-date"
                        fullWidth
                        style={{
                          color: '#fff',
                          backgroundColor: '#55b37e',
                          textTransform: 'none',
                          borderRadius: 8,
                        }}
                        size="large"
                        onClick={handleTogglePopup}
                      >
                        Confirm
                      </Button>
                    </div>
                  </>
                  )}

                    <div style={{ fontSize: 12, color: mute, marginTop: 20 }}>
                      <span style={{ color: '#515151' }}>Rescheduling Info:</span>
                      <br />
                      <br />
                      Your Kopi Date location will be revealed upon confirmation from both parties.
                      More details will be revealed nearing date-day. Please note that
                      rescheduling should only be on an emergency basis.
                      {' '}
                      <b role="button" onKeyDown={null} tabIndex={0} style={{ textDecoration: 'underline', cursor: 'pointer', display: readMore ? 'none' : 'inline' }} onClick={toggleReadMore}>Continue here</b>
                      <br />
                      <br />
                      <b style={{ display: readMore ? 'inline' : 'none' }}>
                        Need to reschedule?
                        <br />
                        <br />
                        <span
                          role="button"
                          onKeyDown={null}
                          tabIndex={0}
                          style={{ cursor: 'pointer', textDecoration: 'underline', color: '#9d7666' }}
                          onClick={() => {
                            if (iHaveConfirmed()) {
                              toggleConfirmation();
                            } else {
                              history.push('/reschedule-date');
                            }
                          }}
                        >
                          Reschedule date here.

                        </span>
                      </b>
                      <br />
                      <br />

                      <b role="button" onKeyDown={null} tabIndex={0} style={{ cursor: 'pointer', textDecoration: 'underline', display: readMore ? 'inline' : 'none' }} onClick={toggleReadMore}>See less.</b>
                    </div>
                  </div>
                </Container>
                <BottomNav active={0} />
              </div>
            )}
        </Grid>
      </Grid>
      <BaseModal open={popupConfirm} title="Confirm Date" desc="Please note that there's no rescheduling after confirmation 😊" handleClose={handleTogglePopup} handleSubmit={submitConfirmDate} disabled={!sureCheck}>
        <input id="checkSure" type="checkbox" checked={sureCheck} onChange={handleToggleCheck} />
        <label htmlFor="checkSure">Are you sure?</label>
      </BaseModal>

      <BaseModal
        open={confirm}
        handleClose={toggleConfirmation}
        submitText="Yes, I accept"
        closeText="Cancel"
        noSubmit
        title="Reschedule"
        desc={(
          <div>
            You have already confirmed the date, kindly
            {' '}
            contact us via email at hello@kopidate.com or
            {' '}
            <a href="https://wa.me/96685098" target="_blank" rel="noreferrer">Whatsapp</a>
            .
          </div>
          )}
      />
    </>
  );
}
