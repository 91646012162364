/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import amplitude from 'amplitude-js';
import { auth } from '../Configs/firebase';
// import { logEvent, setUserProperties } from "firebase/analytics";
import {
  getUserData,
  getUserDataForAmplitude,
  getLoginPlatformType,
  getUserId,
  getamplitudeSetup,
} from './storage';

export const datesAreOnSameDay = (first, second) => first.getFullYear() === second.getFullYear()
  && first.getMonth() === second.getMonth()
  && first.getDate() === second.getDate();

export function reduceMonths(date, months) {
  const result = new Date(date.valueOf());
  result.setMonth(result.getMonth() - months);
  return result;
}

export function reduceDays(date, days) {
  const result = new Date(date.valueOf());
  result.setDate(result.getDate() - days);
  return result;
}

export function addDays(date, days) {
  const result = new Date(date.valueOf());
  result.setDate(result.getDate() + days);
  return result;
}

export function analytics(uid) {
  window.dataLayer.push({
    event: 'login',
    userId: uid,
  });
  // setUserProperties(google_analytic, {userId: auth.currentUser?.uid})
  // logEvent(google_analytic, name, {userId: auth.currentUser?.uid})
}

export async function amplytics(event, additionalProperties = {}) {
  const amplitudeKey = await getamplitudeSetup();
  let uid = getUserId();
  if (!uid) {
    uid = auth.currentUser?.uid;
  }
  const adminIdList = ['rZv3Il7SSUY6n5PxW84Z6vdrBM23', 'Iz1FpOdbAnboUSY5BXB3AbHCYAL2', 'iLugoTry4GT1Dhq8skQ0IFYdWK23', 'xTuv71Zu3vUasbQtMsdwI5fv6Pk2', 'p3nsmCmuFZhAlM1Jgeq9ajoYPQf2', '6SyThc6jsrZEGycd1cGLapAWxGr2'];
  if (amplitudeKey === '9f40646c95b9d1b62cbe301eb52026f0' || !adminIdList.includes(uid)) {
    amplitude.getInstance().init(amplitudeKey, uid);
    amplitude.getInstance().setUserId(uid);
    let user = getUserData();
    if (!user) {
      user = await getUserDataForAmplitude(uid);
    }
    if (user?.signupInfo) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const loginPlatform = getLoginPlatformType();
      const age = Math.floor(new Date().getFullYear()
        - new Date(user.signupInfo?.age?.seconds * 1000).getFullYear());
      const eventProperties = {
        Gender: user.signupInfo ? user.signupInfo.gender : user.gender,
        Age: age.toString(),
        Device: userAgent,
        LoginPlatform: loginPlatform,
        ...additionalProperties,
        WebsiteDomain: window.location.origin,
        PlanType: user.planType || 0,
      };
      const userProperties = {
        Gender: user.signupInfo ? user.signupInfo.gender : user.gender,
        Age: age.toString(),
        PlanType: user.planType || 0,
        FirstName: user.signupInfo ? user.signupInfo.firstName : user.firstName,
      };
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);
    }
  }
}

export function amplyticsm(event, data) {
  const userData = getUserDataForAmplitude(data);
  const eventProperties = {
    gender: userData.signupInfo?.gender,
    age: new Date(userData.signupInfo?.age?.seconds * 1000).toString(),
  };
  console.log('event', event);
  amplitude.getInstance().logEvent(event, eventProperties);
}

export const getHour = (time) => {
  const hour = time.split(':')[0]?.replace(/[^0-9]/g, '');
  const meridiem = time.replace(/[^a-zA-Z]/g, '');
  return (meridiem.toLowerCase() === 'pm' && Number(hour) !== 12) ? Number(hour) + 12 : Number(hour);
};

export const getMinute = (time) => {
  const minute = time.split(':');
  if (minute[1]) { return Number(minute[1]?.replace(/[^0-9]/g, '')); }

  return 0;
};

export function convertTZ(date) {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-SG', { timeZone: 'Asia/Singapore' }));
}

export function getAge(birthDay) {
  const ageInMilliseconds = new Date() - birthDay;
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
}

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+\S+/;
  return re.test(email);
};

export function isiOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}
