const styles = {
  root: {
    background: '#fef8ee',
    minHeight: '100vh',
    paddingBottom: 40,
    '& .title': {
      color: '#9e7666',
      fontSize: 34,
      fontWeight: '500',
      marginTop: 8,
      lineHeight: 1.1,
    },
    '& .btn': {
      cursor: 'pointer',
      background: '#fff',
      marginBottom: 8,
      border: '1px solid #ccc',
      borderRadius: 10,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '10px 14px',
      '& .btn-text': {
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 16,
        color: '#000',
        marginLeft: -24,
      },
    },
    '& .btn-signup': {
      cursor: 'pointer',
      background: '#9e7666',
      marginBottom: 8,
      border: 'none',
      borderRadius: 10,
      width: '100%',
      padding: '12px 14px',
      '& .btn-text': {
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 16,
        color: '#fff',
      },
    },
    '& .help': {
      fontSize: 14,
      color: '#606060',
      marginTop: 12,
      textDecoration: 'none',
    },
  },
};
export default styles;
