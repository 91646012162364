import React from 'react';
import { useHistory } from 'react-router-dom';
import images from '../../Configs/images';
import { OrangeButton } from '../../Elements';
import { getTodaysMatch } from '../../utils/storage';

export default function Component({ classes }) {
  const history = useHistory();
  const todaysMatch = getTodaysMatch();
  // console.log(classes, history);
  return (
    <div hidden={!todaysMatch}>
      <div className={classes.kitSection}>
        <img src={images.Openme} className="kit-img" alt="open me" onClick={() => history.push('/kit')} />
        <div>
          <div className="title">Today&apos;s Experience Kit</div>
          <div className="desc">Get inspired for a meaningful connection! Check out what&apos;s planned for you.</div>
          <OrangeButton
            text="BEGIN DATE"
            rounded
            styles={{
              padding: '4px 16px',
              fontSize: 12,
              fontWeight: 600,
              marginTop: 20,
            }}
            onClick={() => history.push('/kit')}
          />
        </div>
      </div>
    </div>
  );
}
