import { bg_brown_light, brown } from '../../Configs/colors';

const styles = {
  root: {
    background: bg_brown_light,
    height: '100vh',
    paddingBottom: 80,
    boxSizing: 'border-box',
    overflow: 'auto',
    '& .title': {
      fontSize: 14,
      color: brown,
      fontWeight: '500',
    },
    '& .desc': {
      color: '#606060',
      fontSize: 14,
      marginTop: 8,
    },
    '& .code-container': {
      marginTop: 28,
      '& .code': {
        color: brown,
        fontSize: 32,
        fontWeight: '500',
        margin: '16px 0',
      },
    },
    '& .desc-container': {
      fontSize: 14,
      color: '#606060',
      padding: '0 20px',
      '& .head': {
        fontWeight: '600',
      },
    },
    '& .benefit': {
      color: brown,
      fontSize: 18,
      fontWeight: '600',
    },
    '& .adhoc': {
      borderRadius: 16,
      boxShadow: '2px 2px 1px rgba(0,0,0,.1)',
      gap: 16,
      cursor: 'pointer',
      '& .adhoc-title': {
        color: brown,
        fontSize: 14,
        fontWeight: '500',
      },
      '& .adhoc-star': {
        color: 'orange',
        fontSize: 14,
        marginTop: 8,
        gap: 8,
      },
    },
  },
};

export default styles;
