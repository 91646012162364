import HomeIcon from '../Assets/home.svg';
import HomeIconActive from '../Assets/home-active.svg';
import ChatIcon from '../Assets/chat.svg';
import ChatIconActive from '../Assets/chat-active.svg';
import FlashlightIcon from '../Assets/flashlight.svg';
import FlashlightIconActive from '../Assets/flashlight-active.svg';
import SettingsIcon from '../Assets/settings.svg';
import SettingsIconActive from '../Assets/settings-active.svg';
import KopiDate from '../Assets/kopidate.svg';
import Welcome from '../Assets/welcome.png';
import Google from '../Assets/google.png';
import Kopi from '../Assets/kopi.svg';
import Canvas from '../Assets/canvas.png';
import Hello from '../Assets/hello.svg';
import TakeIt from '../Assets/takeit.svg';
import Share from '../Assets/share.png';
import Loading from '../Assets/loading.svg';
import NoPlan from '../Assets/no-plan.png';
import PinkCup from '../Assets/pink-cup.png';
import Cafe from '../Assets/cafe.png';
import Providore from '../Assets/providore.png';
import Botany from '../Assets/botany.png';
import Beast from '../Assets/beast.png';
import Person from '../Assets/person.svg';
import Ask from '../Assets/ask.svg';
import Bell from '../Assets/bell.svg';
import Cloud from '../Assets/cloud.svg';
import BlueCup from '../Assets/blue-cup.svg';
import Telegram from '../Assets/telegram.svg';
import Facebook from '../Assets/facebook.svg';
import Tiktok from '../Assets/tiktok.svg';
import Instagram from '../Assets/instagram.svg';
import Conclude from '../Assets/conclude.svg';
import Earn from '../Assets/earn.svg';
import Quote from '../Assets/quote.svg';
import Availability from '../Assets/availability.svg';
import Article1 from '../Assets/article1.png';
import Article2 from '../Assets/article2.png';
import Article3 from '../Assets/article3.png';
import Article4 from '../Assets/article4.png';
import Article5 from '../Assets/article5.png';
import Article6 from '../Assets/article6.png';
import Eta from '../Assets/eta.svg';
import Kit from '../Assets/kit.svg';
import Win from '../Assets/win.svg';
import Raffles from '../Assets/raffles.png';
import PopUpBackground from '../Assets/popup-background.svg';
import ThreeTips from '../Assets/three-tips.svg';
import TwoTips from '../Assets/two-tips.svg';
import Confetti from '../Assets/confetti.svg';
import People from '../Assets/people.svg';
import Lightbulb from '../Assets/lightbulb.svg';
import Calendar from '../Assets/calendar.svg';
import Horray from '../Assets/horray.svg';
import Horray2 from '../Assets/horray2.svg';
import Bubble from '../Assets/bubble.svg';
import Lock from '../Assets/lock.svg';
import Upload from '../Assets/share.svg';
import PersonOutlined from '../Assets/person-outlined.svg';

import CoverBeast from '../Assets/c-beast.png';
import CoverBistrog from '../Assets/c-bistrog.png';
import CoverBotany from '../Assets/c-botany.png';
import CoverCeline from '../Assets/c-celine.png';
import CoverEmeraldEscapade from '../Assets/c-emerald-escapade.png';
import CoverProvidore from '../Assets/c-providore.png';
import CoverProvidoreRaffles from '../Assets/c-providore-raffles.png';
import CoverRaving from '../Assets/c-raving.png';
import CoverMischief from '../Assets/c-mischief.png';
import CoverTiongBahru from '../Assets/c-tiong-bahru.png';
import CoverTiongBahru2 from '../Assets/c-tiong-bahru2.png';
import CoverZorba from '../Assets/c-zorba.png';
import ChatExample from '../Assets/chat-example.png';

import Wand from '../Assets/wand.svg';
import Info from '../Assets/info.svg';
import Celine from '../Assets/celine.png';
import Thumbs from '../Assets/thumbs.svg';
import Thumbsup from '../Assets/thumbsup.svg';

import Idea from '../Assets/idea.svg';
import LookingFor from '../Assets/lookingfor.svg';
import Example1 from '../Assets/example1.svg';
import Example2 from '../Assets/example2.svg';
import Example3 from '../Assets/example3.svg';
import Gtky from '../Assets/gtky.svg';
import Friends from '../Assets/friends.png';
import Slider from '../Assets/slider.svg';
import Conclude1 from '../Assets/Conclude1.svg';
import CommunityGrowth from '../Assets/CommunityGrowth.svg';
import ConverseConnect from '../Assets/ConverseConnect.svg';
import ViewDateMessage from '../Assets/ViewDateMessage.svg';
import DatesGrey from '../Assets/DatesGrey.svg';
import DatesBrown from '../Assets/DatesBrown.svg';
import Star from '../Assets/Star.svg';
import Tick from '../Assets/Tick.svg';
import Adhoc from '../Assets/Adhoc.svg';
import Stripe from '../Assets/Stripe.svg';
import Pricing1 from '../Assets/Pricing1.svg';
import Pricing2 from '../Assets/Pricing2.svg';
import Pricing3 from '../Assets/Pricing3.svg';
import Datingpersonality from '../Assets/Datingpersonality.svg';
import Myths from '../Assets/Myths.svg';
import Controversial from '../Assets/Controversial.png';
import Solokd from '../Assets/Solokd.png';
import GetOnboarded from '../Assets/getonboarded.svg';
import Adhocdate from '../Assets/Adhocdate.png';
import Cafeimage from '../Assets/Cafeimage.png';

import QuestionGameBanner from '../Assets/Question-Game-Banner.png';
import Rocket from '../Assets/rocket.png';
import TiltPhone from '../Assets/tilt-phone.png';
import Guide from '../Assets/Guide.svg';
import Logokd from '../Assets/Logokd.svg';
import Email from '../Assets/Email.svg';
import Socialkd from '../Assets/Socialkd.svg';
import Tickcircle from '../Assets/Tickcircle.svg';
import Conclude2 from '../Assets/Conclude2.svg';
import Thumbsupgreen from '../Assets/Thumbsupgreen.svg';
import Clap1 from '../Assets/Clap1.svg';
import Clap2 from '../Assets/Clap2.svg';
import Referralpic from '../Assets/Referralpic.png';
import Adhocimage from '../Assets/Adhocimage.png';
import Openkit from '../Assets/Openkit.png';
import Openme from '../Assets/Openme.png';
import Love from '../Assets/Love.png';
import Locked from '../Assets/Locked.svg';
import Humanise from '../Assets/Humanise.png';
import Youarethekey from '../Assets/Youarethekey.png';
import Talk from '../Assets/Talk.png';
import Equality from '../Assets/Equality.png';
import Niceair from '../Assets/Niceair.svg';
import New from '../Assets/New.svg';
import Niceview from '../Assets/Niceview.png';
import Coachgirlsmile from '../Assets/Coachgirlsmile.png';
import Attachment from '../Assets/attachment.png';
import Asset30 from '../Assets/Asset30.png';
import SocialClub from '../Assets/socialclub.png';
import HomeMade from '../Assets/homemade.png';
import Logo from '../Assets/logo.svg';
import Intro1 from '../Assets/intro1.svg';
import Intro2 from '../Assets/intro2.svg';
import Avatar from '../Assets/avatar.svg';
import Social from '../Assets/socials.png';
import GoogleStar from '../Assets/googlestar.png';
import Offers from '../Assets/offers.svg';
import TellUsMore from '../Assets/tellusmore.svg';
import Signup1 from '../Assets/signup1.svg';
import Meet from '../Assets/meet.png';
import Google2 from '../Assets/google2.png';
import Conversation from '../Assets/conversation.png';
import FB1 from '../Assets/fb1.png';
import FB2 from '../Assets/fb2.png';
import FB3 from '../Assets/fb3.png';
import FB4 from '../Assets/fb4.png';
import FreeKopiDate from '../Assets/freekopidate.svg';

const images = {
  HomeIcon,
  HomeIconActive,
  ChatIcon,
  ChatIconActive,
  FlashlightIcon,
  FlashlightIconActive,
  SettingsIcon,
  SettingsIconActive,
  KopiDate,
  Welcome,
  Google,
  Kopi,
  Canvas,
  Hello,
  TakeIt,
  Share,
  Loading,
  NoPlan,
  PinkCup,
  Cafe,
  Providore,
  Beast,
  Botany,
  Person,
  Ask,
  Bell,
  Cloud,
  BlueCup,
  Telegram,
  Facebook,
  Instagram,
  Tiktok,
  Conclude,
  Earn,
  Quote,
  Availability,
  Article1,
  Article2,
  Article3,
  Article4,
  Article5,
  Article6,
  Eta,
  Kit,
  Win,
  Raffles,
  PopUpBackground,
  TwoTips,
  ThreeTips,
  Confetti,
  People,
  Lightbulb,
  Calendar,
  Horray,
  Horray2,
  Bubble,
  Lock,
  Upload,
  PersonOutlined,

  CoverBeast,
  CoverBistrog,
  CoverBotany,
  CoverCeline,
  CoverEmeraldEscapade,
  CoverMischief,
  CoverProvidore,
  CoverProvidoreRaffles,
  CoverRaving,
  CoverTiongBahru,
  CoverTiongBahru2,
  CoverZorba,

  ChatExample,
  Wand,
  Info,
  Celine,

  Thumbs,
  Thumbsup,
  Idea,
  LookingFor,
  Example1,
  Example2,
  Example3,
  Gtky,
  Friends,
  Slider,
  Conclude1,
  ConverseConnect,
  ViewDateMessage,
  DatesGrey,
  DatesBrown,
  Star,
  Tick,
  Adhoc,
  Stripe,
  Pricing1,
  Pricing2,
  Pricing3,
  Datingpersonality,
  Myths,
  Controversial,
  Solokd,
  CommunityGrowth,
  GetOnboarded,
  Adhocdate,
  Cafeimage,

  QuestionGameBanner,
  TiltPhone,
  Rocket,
  Guide,
  Logokd,
  Email,
  Socialkd,
  Tickcircle,
  Conclude2,
  Thumbsupgreen,
  Clap1,
  Clap2,
  Referralpic,
  Adhocimage,
  Openkit,
  Openme,
  Love,
  Locked,
  Humanise,
  Youarethekey,
  Talk,
  Equality,
  Niceair,
  New,
  Niceview,
  Coachgirlsmile,
  Attachment,
  Asset30,
  SocialClub,
  HomeMade,
  Logo,
  Intro1,
  Intro2,
  Avatar,
  Social,
  GoogleStar,
  Offers,
  TellUsMore,
  Signup1,
  Meet,
  Google2,
  Conversation,
  FB1,
  FB2,
  FB3,
  FB4,
  FreeKopiDate
};

export default images;
