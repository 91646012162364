import {
  bg_brown,
  brown,
} from '../../Configs/colors';

const styles = {
  root: {
    padding: '20px 0 80px',
    background: bg_brown,
    height: '100vh',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
    '& .card-price': {
      background: '#fff',
      padding: '20px 0',
      borderRadius: 10,
      boxShadow: '0 0 2px rgba(0,0,0,.2)',
      marginBottom: 20,
    },
    '& .btn': {
      borderRadius: 25,
      border: 'none',
      color: '#606060',
      background: '#eee',
      fontSize: 16,
      padding: '8px 16px',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      marginTop: 10,
      textTransform: 'none',
      fontWeight: '500',
      '&.orange': {
        background: 'orange',
        color: '#fff',
      },
    },
    '& .head': {
      color: 'orange',
      fontSize: 16,
      fontWeight: '500',
    },
    '& .star': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
    },
    '& .head2': {
      color: brown,
      fontSize: 24,
      fontWeight: '700',
    },
    '& .price': {
      color: brown,
      fontSize: 32,
      fontWeight: '500',
      marginTop: 10,
    },
    '& .desc': {
      color: '#606060',
      fontSize: 12,
      fontWeight: '500',
    },
    '& .benefit': {
      display: 'flex',
      gap: 16,
      marginBottom: 10,
      alignItems: 'center',
    },
  },
  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    position: 'fixed',
    zIndex: 999,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

export default styles;
