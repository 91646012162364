/* eslint-disable react/no-array-index-key */
import {
  Box, Container, Tab, Tabs, IconButton, Divider, Grid,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BottomNav, OrangeButton } from '../../Elements';
import images from '../../Configs/images';
import { getPastDates } from '../../utils/storage';
import { amplytics } from '../../utils/helper';

export default function Component({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Explore';
    amplytics('Explore View');
  }, []);

  const location = useLocation();

  const [activeTab, setActiveTab] = useState(1);
  const handleChangeTab = (e, tabN) => {
    amplytics(`Clicks on ${tabN === 1 ? 'individual commitments tab' : 'explore topics'} tab`);
    setActiveTab(tabN);
  };

  useEffect(() => {
    if (location.state?.tab2) {
      setActiveTab(2);
    }
  }, [location]);

  const [filteredPastDates, setFilPD] = useState([]);
  const pastDates = getPastDates() || [];

  const filterPastDates = () => {
    const newDates = pastDates
      .filter((date) => new Date(date.date.seconds * 1000) > new Date(2022, 4, 30));
    // console.log(newDates);
    setFilPD(newDates);
  };

  useEffect(() => {
    filterPastDates();
  }, []);

  const [contents] = useState([
    {
      title: 'Growth vs. Destiny Belief',
      desc: 'How having the right mindset might change your destiny…',
      img: images.Youarethekey,
      locked: (
        <div>
          Launches on 1
          <sup style={{ fontSize: 8 }}>st</sup>
          {' '}
          date
        </div>),
      url: 'https://explore.kopidate.com/members-only-growth-vs-destiny-belief',
    },
    {
      title: 'How to Practice Empathy on a First Date',
      desc: "What's something that makes us human? Being able to feel...",
      img: images.Equality,
      locked: (
        <div>
          Launches on 2
          <sup style={{ fontSize: 8 }}>nd</sup>
          {' '}
          date
        </div>),
      url: 'https://explore.kopidate.com/members-only-how-to-practice-empathy-on-a-first-date',
    },
    {
      title: 'How to Allow Yourself to be Vulnerable',
      desc: 'How having the right mindset might change your destiny…',
      img: images.Humanise,
      locked: (
        <div>
          Launches on 3
          <sup style={{ fontSize: 8 }}>rd</sup>
          {' '}
          date
        </div>),
      url: 'https://explore.kopidate.com/members-only-how-to-allow-yourself-to-be-vulnerable',
    },
    {
      title: 'How Understanding Your Inner Child Can Help Your Adult Relationships',
      desc: 'Here’s how understanding our attachment styles can help...',
      img: images.Love,
      locked: (
        <div>
          Launches on 4
          <sup style={{ fontSize: 8 }}>th</sup>
          {' '}
          date
        </div>),
      url: 'https://explore.kopidate.com/members-only-how-understanding-your-inner-child-can-help-your-adult-relationships',
    },
  ]);

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh', overflow: 'hidden' }}>
        <div className={classes.root}>
          <div className="cover">
            <Container>
              <div className="title">Know Yourself</div>
              <div className="desc">Discover helpful content in structured journeys of learning and apply these wisdom on your date-day!</div>
              <img src={images.Niceair} alt="nice air" style={{ width: '80%' }} />
            </Container>
          </div>
          <Box className="content">
            <Container>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative' }}>
                <Tabs
                  value={activeTab}
                  onChange={handleChangeTab}
                  className={classes.tabs}
                  variant="fullWidth"
                >
                  <Tab className="tab" label="Explore Topics" value={1} />
                  <Tab className="tab" label="Individual Commitments" value={2} />
                </Tabs>
                <div className="new-feature">
                  NEW!
                </div>
              </Box>

              <Box sx={{ display: activeTab === 1 ? 'block' : 'none', pt: 2 }}>
                {/* <Container> */}
                <Box sx={{ mb: 1, alignItems: 'center' }} className="flex space-between">
                  <div style={{ fontSize: 12, color: '#000' }}>Unlock insightful topics as embark on your Kopi Date adventures!</div>
                  <IconButton sx={{ p: 0 }} onClick={() => history.push('/feedback/QiT7bnfv')}>
                    <img src={images.Thumbs} alt="thumbs" width="36" height="36" />
                  </IconButton>
                </Box>

                {/* Free Content : start */}
                <Box className="card-content free">
                  <div className="left-content">
                    <div className="title">Become a Dazzling Conversationalist</div>
                    <div className="desc">
                      How do great conversationalists do it? How do they connect...
                    </div>
                    <button
                      className="more-btn"
                      onClick={() => {
                        amplytics('Clicks on read more topic: Become a Dazzling Conversationalist');
                        history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/members-only-lifechanging-hacks-to-be-a-dazzling-conversationalist-copy' });
                      }}
                    >
                      READ MORE
                    </button>
                  </div>
                  <img src={images.Talk} alt="Become a Dazzling Conversationalist" style={{ width: '40%' }} />
                </Box>
                {/* Free Content : end */}

                <div className="more-content-info">
                  Every date, a self-discovery and learning experience
                </div>

                {contents.map((content, index) => (
                  <Box className="card-content" key={index}>
                    <div className="left-content">
                      <div className="title">{content.title}</div>
                      <div className="desc">
                        {content.desc}
                      </div>
                      {filteredPastDates.length > index
                        ? (
                          <button
                            className="more-btn"
                            onClick={() => {
                              amplytics(`Clicks on read more topic: ${content.title}`);
                              history.push('/explore/topic', { topicLink: content.url });
                            }}
                          >
                            READ MORE
                          </button>
                        ) : (
                          <div className="flex align-items-center locked">
                            <img src={images.Locked} alt="locked" width="14" height="14" />
                            {content.locked}
                          </div>
                        )}
                    </div>
                    <img src={content.img} alt={content.title} style={{ width: '40%' }} />
                  </Box>
                ))}
                {/* </Container> */}
              </Box>
              <Box sx={{ display: activeTab === 2 ? 'block' : 'none', py: 2 }}>
                <div className="head2">
                  Individual Coaching *Fully booked
                </div>
                <img src={images.Niceview} alt="nice view" style={{ width: '100%' }} />

                <Box
                  className="flex align-items-center"
                  sx={{
                    '& svg': {
                      m: 0,
                    },
                    '& hr': {
                      mx: 1,
                      height: 14,
                      bgcolor: '#606060',
                    },
                    fontSize: 14,
                    fontWeight: '600',
                    color: '#606060',
                  }}
                >
                  <div>
                    50 mins
                  </div>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <div>
                    $150/session
                  </div>
                </Box>

                <div style={{ fontSize: 12, fontWeight: '500' }}>
                  <div>
                    An in-depth 1:1 conversation and the opportunity
                    for follow on sessions with a highly qualified coach.
                  </div>
                  <br />
                  <div>
                    We&apos;ll engage you in an enlightening one-to-one conversation
                    marked by curiosity, respect and understanding.
                  </div>
                </div>

                <OrangeButton
                  text="JOIN THE WAITLIST"
                  rounded
                  styles={{
                    padding: '8px 32px',
                    fontSize: 14,
                    fontWeight: '700',
                    marginTop: 18,
                    marginLeft: -4,
                  }}
                  onClick={() => {
                    amplytics('Clicks on join the waitlist');
                    history.push('/explore/topic', { topicLink: 'https://form.typeform.com/to/fyvNx4Mu' });
                  }}
                />
              </Box>
            </Container>
          </Box>
        </div>
        <BottomNav active={2} />
      </Grid>
    </Grid>
  );
}
