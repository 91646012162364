import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent, setCurrentScreen } from 'firebase/analytics';
import { google_analytic } from './firebase';
// import ReactGA from 'react-ga';

export default function Analytics() {
  const location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    setCurrentScreen(google_analytic, page_path);
    logEvent(google_analytic, 'page_view', { page_path });
    // ReactGA.pageview(page_path)
  }, [location]);

  return null;
}
