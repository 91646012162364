// import { brown } from '../../Configs/colors';

const styles = {
  communitySection: {
    '& .head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .date': {
      color: '#606060',
      fontSize: 12,
      position: 'absolute',
      left: 12,
      top: 12,
    },
    '& .card': {
      textAlign: 'center',
      position: 'relative',
      background: '#fff5ee',
      padding: '16px 8px 16px',
      borderRadius: 16,
      marginTop: 4,
      marginBottom: 16,
      '& .share': {
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 2,
      },
      '& .i': {
        display: 'inline-block',
        height: 12,
        width: 12,
        position: 'relative',
        top: 2,
        left: 2,
      },
      '& .quest': {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 8,
        lineHeight: '140%',
      },
      '& .replies': {
        color: '#606060',
        fontSize: 12,
        marginTop: 4,
      },
      '& .field': {
        borderRadius: 16,
        overflow: 'hidden',
        margin: '16px 0',
        '& .fieldtext': {
          background: '#fff',
          outline: 'none',
          border: 'none',
          fontSize: 4,
        },
      },
      '& .desc': {
        color: '#606060',
        fontSize: 12,
        marginTop: 16,
      },
    },
    '& .answers': {
      display: 'flex',
      overflowX: 'auto',
      paddingBottom: 10,
      width: '100%',
      height: '100%',
      position: 'relative',
      marginTop: 16,
      '& .scrollable': {
        display: 'flex',
        flexWrap: 'nowrap',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
      '& .answer-card': {
        borderRadius: 8,
        padding: '8px 16px 40px',
        boxShadow: '2px 2px 2px rgba(0,0,0,.1)',
        background: '#fff',
        minWidth: 200,
        position: 'relative',
        marginRight: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& .blur-card': {
          background: 'linear-gradient(to right, rgba(255,255,255,0.6) , rgba(255,255,255,1))',
          position: 'absolute',
          bottom: 0,
          right: 0,
          top: 0,
          left: 0,
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .user-answer': {
          color: '#000',
          fontSize: 14,
          textAlign: 'left',
        },
        '& .user-name': {
          position: 'absolute',
          color: '#606060',
          fontSize: 10,
          marginTop: 16,
          right: 8,
          left: 55,
          bottom: 8,
          textAlign: 'right',
        },
        '& .like-btn': {
          position: 'absolute',
          left: 4,
          bottom: 2,
          padding: 0,
          zIndex: 2,
        },
      },
    },
    '& .thank-text': {
      color: '#606060',
      fontSize: 14,
      fontWeight: '500',
      marginTop: 12,
    },
  },
};
export default styles;
