// import { ChevronRightRounded } from '@mui/icons-material';
import { ChevronRightRounded } from '@mui/icons-material';
import { Container, Divider } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { bg_brown, brown } from '../../Configs/colors';
import images from '../../Configs/images';
// import images from '../../Configs/images';
import { amplytics } from '../../utils/helper';
import { getHomemadeBrew } from '../../utils/storage';
// import 'react-tiktok/dist/index.css';

export default function Component({ classes }) {
  const history = useHistory();
  const homeMade = getHomemadeBrew();
  // console.log(classes, history);
  return (
    <div className={classes.root}>
      <Container>
        <div className="title">Essential Knowledge</div>
      </Container>
      <Container>
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: Who’s Laughing Now?');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/whos-laughing-now-humour-styles-and-your-dating-life-77d8d527ea31' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              Who&apos;s Laughing Now? Humour
              Styles and Your Dating Life
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              “The librarian was the dog!”, you say emphatically, ears
              bracing for the impact of the uproarious laughter to soon...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>4 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/3oEjHAUOqG3lSS0f1C" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
        <Divider />
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: Who’s Laughing Now?');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/the-brief-history-of-dating-97bf4798fc82' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              The Brief History Of Dating
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              Dating has changed lots over the past few years. But it&apos;s
              hard to imagine that just a few years ago, the dating
              habits that we know today were...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>7 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/26mkhMYkitO7DoJuU" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
      </Container>

      <div style={{ background: bg_brown, paddingTop: 16 }} className="center">
        <div style={{
          fontSize: 18,
          fontWeight: '700',
          color: brown,
        }}
        >
          {homeMade.title}
        </div>
        <img src={homeMade.imgLink || images.HomeMade} alt="homemade" width="200" style={{ marginTop: 12 }} />
        <div style={{ fontSize: 12, margin: '12px 32px 0' }}>
          {homeMade.description}
        </div>
        <div
          className="flex align-items-center"
          style={{
            justifyContent: 'center',
            background: '#1D1E1E',
            marginTop: 16,
            color: '#fff',
            fontSize: 16,
            padding: '8px 0',
            cursor: 'pointer',
          }}
          onClick={() => window.open(homeMade.readMoreLink, '_blank')}
        >
          Read more
          {' '}
          <ChevronRightRounded />
        </div>
      </div>

      <Container>
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: Hook-up Culture and the Disempowerment of Women');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/social-commentary-hook-up-culture-and-the-disempowerment-of-women-2cf6e893052b' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              Hook-up Culture and the Disempowerment of Women
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              According to the philosopher Immanuel Kant,
              human beings should be treated as an ends-in-themselves
              and not as a means to something else...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>6 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/gHbgunYtZZ7w8Izf0O" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
        <Divider />
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: The Cycle of Breakups');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/the-cycle-of-breakups-faf3e9ab9145' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              The Cycle of Breakups
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              Relationship breakups are hard. A whole wave of emotions
              come rushing over you and when you’re left to face this
              emotional conflict alone — it could be daunting...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>7 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/vokkuERklQbGU" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
      </Container>

    </div>
  );
}
