/* eslint-disable react/no-danger */
import React, { useState } from 'react';

export default function test() {
  const [url, setUrl] = useState('https://explore.kopidate.com/');
  return (
    <div>
      <button onClick={() => setUrl('https://explore.kopidate.com/tiong-bahru-walk-and-talk/')}>Walk And Talk</button>
      <object
        title="Kopi Date"
        data={url}
        style={{
          width: '100%',
          height: '90vh',
          overflow: 'auto',
          border: 'none',
          marginTop: 20,
        }}
      />
    </div>
  );
}
