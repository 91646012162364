import { brown } from '../../Configs/colors';

const styles = {
  kitSection: {
    display: 'flex',
    padding: '0 8px',
    marginTop: 24,
    gap: 16,
    alignItems: 'center',
    '& .kit-img': {
      width: '40%',
      cursor: 'hover',
    },
    '& .title': {
      color: brown,
      fontSize: 16,
      fontWeight: '500',
    },
    '& .desc': {
      color: '#606060',
      fontSize: 14,
      marginTop: 10,
    },
  },
};

export default styles;
