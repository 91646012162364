import Home from './Home';
import Kit from './Kit';
import Login from './Login';
import Chat from './Chat';
import Settings from './Settings';
import Faq from './Faq';
import ShareFeedback from './ShareFeedback';
import ExtendPause from './ExtendPause';
import UpdateAvailability from './UpdateAvailability';
import DateDetails from './DateDetails';
import UpcomingDate from './UpcomingDate';
import RescheduleDate from './RescheduleDate';
import ExplorePeople from './ExplorePeople';
import AdminChat from './AdminChat';
import KnowingTheRealYou from './KnowingTheRealYou';
import Conclude from './Conclude';
import Dates from './Dates';
import AdHoc from './AdHoc';
import Subscription from './Subscription';
import Games from './Games';
import DateMessage from './DateMessage';
import StartScreen from './QuestionGame/StartScreen';
import TitleScreen from './QuestionGame/TitleScreen';
import GameScreen from './QuestionGame/GameScreen';
import Feedback from './Feedback';
import Referral from './Referral';
import DateConcluded from './DateConcluded';
import ExploreTopic from './ExploreTopic';
import Waitlisted from './Waitlisted';
import Onboarding from './Onboarding';
import RescheduleFee from './RescheduleFee';
import SetupProfile from './SetupProfile';
import Explore from './Explore';
import Test from './Test';
import Payment from './Payment';
import Calendly from './Calendly';

const pages = {
  Test,
  Payment,
  Calendly,
  Home,
  Kit,
  Login,
  Chat,
  Settings,
  Faq,
  ShareFeedback,
  ExtendPause,
  UpdateAvailability,
  DateDetails,
  UpcomingDate,
  RescheduleDate,
  ExplorePeople,
  AdminChat,
  KnowingTheRealYou,
  Conclude,
  Dates,
  AdHoc,
  Subscription,
  Games,
  DateMessage,
  StartScreen,
  TitleScreen,
  GameScreen,
  Feedback,
  Referral,
  DateConcluded,
  ExploreTopic,
  Waitlisted,
  Onboarding,
  RescheduleFee,
  SetupProfile,
  Explore,
};

export default pages;
