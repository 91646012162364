/* eslint-disable */
import { React, useState, useEffect } from 'react';
import { Container, Grid, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import questions from './data.js';
import '../style.css';
import images from '../../../Configs/images';

const getIndexLeft = () => {
  let arrayIndexLeft = [];
  for (var i = 0; i < questions.length; i++) arrayIndexLeft.push(i);
  if (arrayIndexLeft) {
    return arrayIndexLeft;
  } else {
    return [];
  }
};

export default function GameScreen(props) {
  const [player, setPlayer] = useState(2);
  const [indexLeft, setIndexLeft] = useState(getIndexLeft());
  const [question, setQuestion] = useState('');
  const [rating, setRating] = useState('');
  const [author, setAuthor] = useState('');

  const togglePlayer = () => {
    if (player === 1) {
      setPlayer(2);
    } else {
      setPlayer(1);
    }
  };

  const getQuestion = () => {
    if (indexLeft.length === 0) {
      console.log('No more unsed values');
      return;
    }
    let randNum = Math.floor(Math.random() * indexLeft.length);

    let indexOfItemInArray = indexLeft[randNum];

    setQuestion(questions[indexOfItemInArray].question);
    setRating(questions[indexOfItemInArray].rating);
    setAuthor(questions[indexOfItemInArray].author);

    indexLeft.splice(randNum, 1);

    togglePlayer();

    console.log('rand num: ' + indexOfItemInArray);
    console.log('Unused Indexes: ' + indexLeft);
    console.log(questions);
  }

    useEffect(() => {
      getQuestion();
    }, []);

    return (
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <div
            className={`root ${
              player === 1 ? 'orange-background' : 'blue-background'
            }`}
          >
            <Container>
              <div className="wrapper">
                <div className="player">PLAYER {player} ANSWER:</div>
                <div className="question">{question}</div>
                <img
                  src={images.Rocket}
                  onClick={getQuestion}
                  className="rocket-img"
                />
                <div className="btn-desc">TAP TO LAUNCH!</div>
              </div>
              <div className="rating-container">
                Posted by {author}
                <ArrowUpward
                  className="arrowUp"
                  sx={{ color: '#BAF89D', fontSize: 28 }}
                />
                {rating}
                <ArrowDownward
                  className="arrowDown"
                  sx={{ color: '#FF8686', fontSize: 28 }}
                />
              </div>
            </Container>
          </div>
        </Grid>
      </Grid>
    );
  };

