/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Box, Container, Divider, Grid, SwipeableDrawer,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { EmailIcon, TelegramIcon, WhatsappIcon } from 'react-share';
import { OrangeButton } from '../../Elements';
import images from '../../Configs/images';
import { amplytics } from '../../utils/helper';
import { getReferralInfo } from '../../utils/storage';
import { auth, fFunction } from '../../Configs/firebase';
import { httpsCallable } from 'firebase/functions';

export default function Component({ classes, history }) {
    const [loading, setLoading] = useState(false)
    const [eligible, setEligible] = useState(false)

  useEffect(() => {
    document.title = 'Kopi Date Experience - Date Concluded'
    amplytics('Concluded View')

    setLoading(true)
    const callable = httpsCallable(fFunction, 'freeCreditEligibility');
    callable({ uid: auth.currentUser?.uid }).then((response) => {
        setLoading(false)
        console.log(response.data)
        if (response.data.status) {
            setEligible(true)
        } else {
            setEligible(false)
        }
    }).catch(err => {
        setLoading(false)
        console.log("[ERROR]", err)
    })
  }, []);

  const referralInfo = getReferralInfo();

  const message = `Have%20you%20tried%20Kopi%20Date?%20Sign%20up%20with%20my%20promo%20code:%20${referralInfo?.promocode}%20and%20you%20will%20get%20off%20the%20waitlist,%20PLUS%20a%20VIP%20pass!%20%F0%9F%A4%9F%F0%9F%8F%BB%20Come%20check%20it%20out!%20https://kopidate.com`;

  const [openToShare, setOpenToShare] = useState(false);
  const toggleOpenToShare = () => {
    if (!openToShare) {
      amplytics('Clicks on referral');
    }
    setOpenToShare(!openToShare);
  };

  const handleRedirect = (link) => () => {
    // const linkArr = link.split('/');
    // const destiny = linkArr[linkArr.length - 1];
    // amplytics(`Home CTA redirect to ${destiny}`);
    window.open(link, '_blank');
  };

  return (
    <div className={classes.root}>
        
        {loading
            && (
            <div style={{
                textAlign: 'center',
                background: '#fef8f4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '95vh',
            }}>
                <img alt="loading" src={images.Loading} style={{ width: 100, height: 100 }} />
            </div>
        )}
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} style={{ position: 'relative' }}>
          <Container sx={{ pt: 2, px: 4 }}>
            <div style={{ textAlign: 'right' }} onClick={() => history.replace('/availability')}>
              <CloseRounded fontSize="small" />
            </div>

            <div className="center title">
              Date Concluded
            </div>

            <div className="center desc">
              We are now better equipped for your next experience! 🙌🏻
            </div>

            <Divider sx={{ my: 2 }} />

            {eligible && (
                <>
                <div className="center benefit" style={{fontSize: "22px"}}>We appreciate YOU!</div>
                <div className="center desc">
                    We are so glad you/ve enjoyed your Kopi Date!
                </div>
                <div className="center desc">
                    Congrats 🎉 You've unlocked a <b>FREE Kopi Date credit!</b>
                </div>
                
                <Box className="center" sx={{ my: 3 }}>
                    <img src={images.FreeKopiDate} alt="social-kd" style={{ width: '80%' }} />
                </Box>
                <div className="center desc">
                    To redeem, kindly help us with a:
                </div>

                <div style={{margin: "2rem 2rem"}}>
                    <div className="desc flex align-items-center adhoc-star" style={{fontSize:"16px", marginBottom: "1.2rem"}}>
                        <img src={images.Tickcircle} alt="star" height="16" width="16" style={{marginRight: 10}}/>
                        5-star <b><u><a style={{color:"inherit", marginLeft: 3}} href="https://www.google.com/maps/place/Kopi+Date/@1.314,103.84425,11z/data=!3m1!4b1!4m6!3m5!1s0x31da3d0b9efca44d:0xfa167217ea0e7373!8m2!3d1.314!4d103.84425!16s%2Fg%2F11j48kvz2j" target="_blank"> Google Review</a></u></b>
                    </div>
                    <div className="desc flex align-items-center adhoc-star" style={{fontSize:"16px", marginBottom: "1.2rem"}}>
                        <img src={images.Tickcircle} alt="star" height="16" width="16" style={{marginRight: 10}}/>
                        5-star <b><u><a style={{color:"inherit", marginLeft: 3}} href="https://onelink.to/ja7k5a" target="_blank"> App Review</a></u></b>
                    </div>
                    <div className="desc flex align-items-center adhoc-star" style={{fontSize:"16px", marginBottom: "0.9rem"}}>
                        <img src={images.Tickcircle} alt="star" height="16" width="16" style={{marginRight: 10}}/>
                        <u><a style={{color:"inherit"}} href="mailto:hello@kopidate.com?subject=Redeem%20a%20FREE%20Kopi%20Date!&body=Hey%20Kopi%20Date!%0D%0A%0D%0AI've%20attached%20the%20screenshots%20of%20the%20Google%20Review%20and%20App%20review.%0D%0A%0D%0ACheers">Email to let us know</a></u>
                    </div>

                    <div className="desc" style={{textAlign:"end"}}>
                        <u><a style={{color:"inherit", fontSize:"13px"}} href="https://explore.kopidate.com/free-kopi-date-tcs" target="_blank">T&Cs apply</a></u>
                    </div>
                </div>


                <Box sx={{ p: 2, background: '#fff' }}>
                    <Box className="flex align-items-center adhoc" onClick={() => history.push('/adhoc')}>
                    <img src={images.Adhocimage} alt="social-kd" style={{ width: '40%' }} />
                    <div>
                        <div className="adhoc-title">Additional Kopi Date</div>
                        <div className="desc">Go for an additional Kopi Date this month (ad-hoc)!</div>
                        <div className="flex align-items-center adhoc-star">
                        <img src={images.Star} alt="star" height="16" width="16" />
                        <div>Popular choice!</div>
                        </div>
                    </div>
                    </Box>
                </Box>
                </>
            )}
            <div className="center benefit">Have a free Kopi Date!</div>

            <div className="desc">
              Kopi Date is an early-stage startup
              that aims to move away from the superficiality of online dating.
            </div>

            <div className="desc">
              Skip the waitlist for your friend(s)
              and receive a FREE Kopi Date Credit! ☕
            </div>

            <div className="center code-container">
              <div style={{ color: '#606060', fontSize: 14, fontWeight: '500' }}>YOUR REFERRAL CODE</div>
              <div className="code">{referralInfo?.promocode}</div>
              <OrangeButton onClick={toggleOpenToShare} text="SHARE MAGIC LINK" rounded styles={{ padding: '6px 32px', fontWeight: '600', fontSize: 16 }} />
            </div>

          </Container>

          <Box className="center" sx={{ my: 3 }}>
            <img src={images.Socialkd} alt="social-kd" style={{ width: '80%' }} />
          </Box>

        </Grid>
      </Grid>
      <SwipeableDrawer
        anchor="bottom"
        open={openToShare}
        onClose={toggleOpenToShare}
        onOpen={() => null}
      >
        <Box
          sx={{ width: 'auto', textAlign: 'center' }}
          role="presentation"
        >
          <div style={{
            marginTop: 10, fontSize: 16, fontWeight: 'bold', color: '#000',
          }}
          >
            Share using
          </div>
          <div style={{
            padding: '18px 20px 40px', display: 'flex', gap: 4, justifyContent: 'center',
          }}
          >
            <WhatsappIcon size={45} round onClick={handleRedirect(`https://api.whatsapp.com/send?text=${message}`)} />
            <TelegramIcon size={45} round onClick={handleRedirect(`https://t.me/share/url?url={https://kopidate.com}&text=${message}`)} />
            <a href={`sms:&body=${message}`}>
              <EmailIcon size={45} round />
            </a>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
