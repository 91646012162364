/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import { ChevronLeft } from '@mui/icons-material';
import {
  Box, Container, Divider, Grid,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import images from '../../Configs/images';
import { BottomNav, OrangeButton } from '../../Elements';
import { amplytics } from '../../utils/helper';
import { getPastCancelledDates, getPastDates, getUserData } from '../../utils/storage';

export default function Component({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Date Message';
    amplytics('DateMessage View');
  }, []);

  const { matchId } = useParams();
  const pastDates = getPastDates() || [];
  const pastCancelledDates = getPastCancelledDates() || [];
  const user = getUserData();
  const gender = user?.signupInfo?.gender;

  const [selectedMatch, setSelectedMatch] = useState({ date: {} });
  const partnerAlias = gender === 'Male' ? selectedMatch.femaleAlias : selectedMatch.maleAlias;
  const haveNotWrittenReviewYet = (Boolean(gender === 'Male' && !selectedMatch?.maleFeedback?.toMyDateMsg) || Boolean(gender === 'Female' && !selectedMatch?.femaleFeedback?.toMyDateMsg));

  useEffect(() => {
    if (!matchId) {
      history.replace('/dates');
    } else {
      const sd = [...pastDates, ...pastCancelledDates]
        .filter((match) => match.matchId === matchId)[0];
      setSelectedMatch(sd);
    }
  }, [matchId]);

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className={classes.root}>
          <Container>
            <Box sx={{ mt: 2, cursor: 'pointer', fontSize: 14 }} onClick={() => history.goBack()} className="flex align-items-center">
              <ChevronLeft sx={{ p: 0 }} />
              <div>
                <strong>BACK</strong>
              </div>
            </Box>
            <Box>
              <div className="head">
                Your Kopi Date With &apos;
                {partnerAlias}
                &apos;
              </div>

              <div>
                <div>
                  <div className="section-title">Location</div>
                  <div className="desc">{selectedMatch.cafeName}</div>
                </div>

                <div className="flex" style={{ gap: 24 }}>
                  <div>
                    <div className="section-title">Date</div>
                    <div className="desc">{moment(selectedMatch.date.seconds * 1000).format('D MMM')}</div>
                  </div>
                  <div>
                    <div className="section-title">Time</div>
                    <div className="desc">{selectedMatch.time}</div>
                  </div>
                </div>
              </div>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ minHeight: '25vh' }}>
              <div className="center">
                <img src={images.ViewDateMessage} alt="view date message" width="60" height="60" />
              </div>
              <div className="center ttl">
                Message from
                {' '}
                &apos;
                {partnerAlias}
                &apos;
                {' '}
                ✉️
              </div>

              <div style={{
                fontSize: 14,
                color: '#000',
                margin: '12px 0',
              }}
              >
                {haveNotWrittenReviewYet
                  ? 'You have not written a review yet!'
                  : gender === 'Male' ? selectedMatch?.femaleFeedback?.toMyDateMsg || `'${partnerAlias}' has not written a review yet!` : selectedMatch?.maleFeedback?.toMyDateMsg || `'${partnerAlias}' has not written a review yet!`}
              </div>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ minHeight: '20vh' }}>
              <div className="center ttl">
                What you wrote to
                {' '}
                &apos;
                {partnerAlias}
                &apos;
                {' '}
                ✏️
              </div>

              <div style={{
                fontSize: 14,
                color: '#000',
                margin: '12px 0',
              }}
              >
                {gender === 'Male' ? selectedMatch?.maleFeedback?.toMyDateMsg || 'You have not written a review yet!' : selectedMatch?.femaleFeedback?.toMyDateMsg || 'You have not written a review yet!'}
              </div>
            </Box>

            {haveNotWrittenReviewYet
            && (
            <OrangeButton
              text="WRITE REVIEW"
              fullwidth
              size="large"
              styles={{ fontWeight: 'bold', borderRadius: 30 }}
            />
            )}

          </Container>
        </div>
        <BottomNav active={3} />
      </Grid>
    </Grid>
  );
}
