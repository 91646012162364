import { brown } from '../../Configs/colors';

const styles = {
  referralSection: {
    margin: '32px 0 8px',
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
      marginLeft: 8,
    },
    '& .highlight': {
      color: brown,
      fontWeight: 'bold',
      fontSize: 17,
    },
    '& .desc': {
      fontSize: 11,
      color: '#000',
      margin: '8px 0',
    },
    '& .container': {
      background: '#fff5e9',
      padding: '21px',
      marginTop: -25,
      position: 'relative',
    },
    '& .referral-pict': {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      height: '100%',
      width: '32%',
    },
  },
};

export default styles;
