// import { brown } from '../../Configs/colors';

import { brown } from '../../Configs/colors';

const styles = {
  recommendationSection: {
    paddingLeft: 8,
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .cards': {
      display: 'flex',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      position: 'relative',
      '& .card': {
        display: 'flex',
        flexWrap: 'nowrap',
        padding: 0,
      },
      '& .edit-profile': {
        color: brown,
        fontWeight: 'bold',
        fontSize: 16,
      },
      '& .headline': {
        color: brown,
        fontWeight: 'bold',
        fontSize: 16,
        width: '80%',
      },
      '& .hobbies': {
        color: '#000',
        marginTop: 10,
        fontSize: 12,
      },
      '& .occupation': {
        position: 'absolute',
        bottom: 8,
        right: 20,
        fontSize: 12,
        color: '#606060',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
      '& div.people': {
        borderRadius: 10,
        objectFit: 'cover',
        color: '#9e7666',
        fontWeight: '400',
        width: 200,
        padding: '10px 20px 34px',
        position: 'relative',
        boxShadow: '1px 1px 3px rgba(0,0,0,.2)',
        boxSizing: 'content-box',
        marginBottom: 10,
        marginRight: 10,
      },
      '& .thumbsup': {
        position: 'absolute',
        right: 10,
        top: 4,
      },
      '& .btn-edit': {
        border: `1px solid ${brown}`,
        color: brown,
        background: 'transparent',
        borderRadius: 25,
        padding: '4px 20px',
        marginTop: 20,
        fontSize: 12,
        cursor: 'pointer',
      },
    },
  },
  peopleCard: {
    borderRadius: 10,
    objectFit: 'cover',
    color: '#9e7666',
    fontWeight: '400',
    padding: '0 0 34px',
    position: 'relative',
    boxSizing: 'content-box',
    marginBottom: 10,
    marginRight: 10,
    '& .headline': {
      color: brown,
      fontWeight: 'bold',
      fontSize: 16,
    },
    '& .hobbies': {
      color: '#000',
      marginTop: 10,
      fontSize: 12,
    },
    '& .occupation': {
      position: 'absolute',
      bottom: 8,
      right: 20,
      fontSize: 12,
      color: '#606060',
    },
  },
};
export default styles;
