import { bg_grey, brown } from '../../Configs/colors';

const styles = {
  root: {
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'auto',
    '& .title': {
      fontSize: 18,
      color: '#000',
      fontWeight: '500',
    },
    '& .code-container': {
      background: bg_grey,
      padding: '20px 0',
      marginTop: 20,
      borderRadius: '0 0 25px 25px',
      '& .code': {
        color: brown,
        fontSize: 32,
        fontWeight: '500',
        margin: '16px 0',
      },
    },
    '& .desc-container': {
      fontSize: 14,
      color: '#606060',
      padding: '0 20px',
      '& .head': {
        fontWeight: '600',
      },
    },
    '& .benefits-container': {
      '& .head': {
        fontSize: 14,
        color: brown,
        fontWeight: '600',
        marginLeft: 8,
        marginBottom: 4,
      },
      '& .benefit': {
        color: '#606060',
        fontSize: 12,
        fontWeight: '500',
        marginBottom: 10,
      },
    },
  },
};

export default styles;
