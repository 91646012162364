/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Container, Divider, Grid, Grow, IconButton, Popper, TextField,
} from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import images from '../../Configs/images';
import { brown_light, grey } from '../../Configs/colors';
import {
  getGettingToKnowYou, getNextMatch, getTodaysMatch, setUserData,
} from '../../utils/storage';
import { auth, firestore } from '../../Configs/firebase';
// import { Link } from 'react-router-dom';
import { amplytics } from '../../utils/helper';
import {isMobile} from 'react-device-detect';

export default function KnowingTheRealYou(props) {
  // const user = getUserData()
  const { classes, history } = props;
  const upcomingDate = getTodaysMatch() || getNextMatch();
  useEffect(() => {
    if (upcomingDate && !upcomingDate.askForReschedule) {
      history.replace('/');
    }
    return null;
  });
  const gettingToKnowYou = getGettingToKnowYou();
  // const [agreeSingle, setAgree] = useState(false)
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    anyQuestionsForUs: '',
    headline: '',
    height: '',
    hobbiesQuestion: '',
    race: '',
    fridayEvening: false,
    saturdayAfternoon: false,
    saturdayMorning: false,
    sundayAfternoon: false,
    sundayMorning: false,
    thursdayEvening: false,
    unavailableQuestion: '',
  });
  // updated this to 3
  const checkThereAreThreeAvailable = () => {
    const {
      fridayEvening,
      saturdayAfternoon,
      saturdayMorning,
      sundayAfternoon,
      sundayMorning,
      thursdayEvening,
    } = input;
    const availabilties = [
      fridayEvening,
      saturdayAfternoon,
      saturdayMorning,
      sundayAfternoon,
      sundayMorning,
      thursdayEvening,
    ];

    let truthy = 0;
    availabilties.forEach((val) => {
      if (val) truthy += 1;
    });
    return truthy >= 3;
  };

  useEffect(() => {
    document.title = 'Kopi Date Experience - Update Availability';
    amplytics('UpdateAvailability View');

    if (isMobile) {
        window.location.assign(process.env.REACT_APP_DEEPLINK + `/availability`)
    }

    if (gettingToKnowYou) {
      setInput(gettingToKnowYou);
      // setAgree(true)
    }
  }, []);

  const [error, setError] = useState({});
  const handleChangeError = () => {
    setError({
      availability: !(
        input.fridayEvening
      || input.saturdayAfternoon
      || input.saturdayMorning
      || input.sundayAfternoon
      || input.sundayMorning
      || input.thursdayEvening),
    });
  };
  const anyError = () => (
    !(
      input.fridayEvening
    || input.saturdayAfternoon
    || input.saturdayMorning
    || input.sundayAfternoon
    || input.sundayMorning
    || input.thursdayEvening)
    || (input.unavailableQuestion?.length < 3 || (input.unavailableQuestion?.length === 3 && input.unavailableQuestion !== 'NIL'))
    || !checkThereAreThreeAvailable()
  );
  useEffect(() => {
    handleChangeError();
  }, [input]);

  const changeInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleToggleAvailability = (e) => {
    setInput({ ...input, [e.target.name]: !input[e.target.name] });
  };

  const handleSubmit = async () => {
    // analytics('/btn-save-gtky');
    const err = await anyError();
    if (!err) {
      setLoading(true);
      const docRef = doc(firestore, `/user/${auth.currentUser.uid}`);

      const gettingToKnowYouInfo = {
        ...input,
      };
      await updateDoc(docRef, {
        gettingToKnowYouInfo,
      }).then(() => {
        setUserData(auth.currentUser);
        amplytics('Completed Update Availability');
        history.replace('/');
        setLoading(false);
      }).catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
    }
  };

  const pauseCurationCondition = () => {
    const thereIsMonth = input.unavailableQuestion?.toLowerCase()?.match(/month/g);
    const thereIsWhole = input.unavailableQuestion?.toLowerCase()?.match(/whole/g);
    const more30Chars = input.unavailableQuestion?.length > 30;
    if (openPauseTooltip === -1) {
      return false;
    }
    if (thereIsMonth || thereIsWhole || more30Chars) {
      return true;
    }
    return false;
  };

  const anchorEl = useRef(null);
  const anchorEl2 = useRef(null);
  const anchorPauseCuration = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(0);
  const [openPauseTooltip, setOpenPauseTooltip] = useState(0);

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        { loading
          ? (
            <div className={classes.loading}>
              <div className="loadingText">Please wait...</div>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          ) : (
            <div className={classes.root}>
              <Container>
                <IconButton className="back" onClick={() => history.replace('/')}><ChevronLeftRounded /></IconButton>
                <div className="section">

                  <div style={{ textAlign: 'left' }}>

                    <div>
                      <div className="flex space-between" style={{ alignItems: 'center', marginBottom: 4 }}>
                        <div className="form-label">Availability over the next 1 month</div>
                        <div
                          style={{
                            color: '#000',
                            fontWeight: '300',
                            fontSize: 12,
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                          }}
                          onClick={() => setOpenTooltip(1)}
                        >
                          Need Help?
                        </div>
                      </div>
                      <div className="order-desc">Maximise the quality of your Kopi Date curations by checking more slots ☕</div>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Saturday Morning</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 10AM - 1PM
                          </div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.saturdayMorning} labelFor="saturdayMorning" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Saturday Afternoon</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 2PM - 6PM
                          </div>
                          <div ref={anchorEl} style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.saturdayAfternoon} labelFor="saturdayAfternoon" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                      </Grid>
                      <Divider className="divider" />
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Sunday Morning</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 10AM - 1PM
                          </div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.sundayMorning} labelFor="sundayMorning" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Sunday Afternoon</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 2PM - 6PM
                          </div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.sundayAfternoon} labelFor="sundayAfternoon" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                      </Grid>
                      <Divider className="divider" />
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Thursday Evening</div>
                          <div style={{ fontSize: 10, color: grey }}>7PM onwards</div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.thursdayEvening} labelFor="thursdayEvening" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Friday Evening</div>
                          <div style={{ fontSize: 10, color: grey }}>7PM onwards</div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.fridayEvening} labelFor="fridayEvening" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    <Divider className="divider" />
                    <div>
                      <div className="form-label">Indicate your strictly unavailable date and timing (If any)</div>
                      <div className="order-desc" ref={anchorPauseCuration}>As an exception to the schedule you have ticked above</div>
                      <TextField ref={anchorEl2} name="unavailableQuestion" value={input.unavailableQuestion} onChange={changeInput} placeholder="Unavailable on 29 Jan, 4pm onwards" size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                    </div>
                  </div>

                </div>
                <div style={{ fontSize: 12, color: 'salmon', textAlign: 'center' }}>
                  {error.availability
                  && (
                  <div style={{ marginTop: 8 }}>
                    Please indicate your availability!

                  </div>
                  )}
                  {!checkThereAreThreeAvailable()
                  && (
                  <div style={{ marginTop: 8 }}>
                    Please check at least 3 timings to proceed!

                  </div>
                  )}
                  {(input.unavailableQuestion?.length < 3 || (input.unavailableQuestion?.length === 3 && input.unavailableQuestion !== 'NIL')) && <div style={{ marginTop: 8 }}>Please put &quot;NIL&quot; in the unavailability field if you&apos;re available for all the date selections!</div>}
                </div>
                <button
                  disabled={anyError()}
                  className={`submit ${anyError() && 'disabled'}`}
                  onClick={handleSubmit}
                  id="btn-save-availability"
                >
                  <div className="submit-text">SAVE & CONTINUE</div>
                </button>
              </Container>
            </div>
          )}
      </Grid>
      <Popper
        anchorEl={anchorPauseCuration.current}
        disablePortal
        className={classes.grow}
        open={pauseCurationCondition()}
        role={undefined}
        transition
        placement="top-start"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <div>
              <ClickAwayListener onClickAway={() => setOpenTooltip(-1)}>
                <div className={classes.tooltip}>
                  <div>
                    <b>Gentle note:</b>
                  </div>
                  <br />
                  <div>
                    Away for a month?
                    <br />
                    Pause curation here!
                  </div>
                  <br />
                  <Button
                    style={{ fontSize: 12 }}
                    color="secondary"
                    onClick={() => history.push('/settings', { pauseCuration: true })}
                  >
                    <span style={{ color: '#606060' }}>Proceed</span>
                  </Button>
                  <Button
                    sx={{ fontSize: 12 }}
                    onClick={() => setOpenPauseTooltip(-1)}
                  >
                    Close
                  </Button>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
      <Popper
        anchorEl={openTooltip === 1 ? anchorEl.current : anchorEl2.current}
        disablePortal
        className={classes.grow}
        open={Boolean(openTooltip)}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
          >
            <div>
              <ClickAwayListener onClickAway={() => setOpenTooltip(0)}>
                <div className={classes.tooltip}>
                  <div>
                    <b>
                      Step
                      {' '}
                      {openTooltip}
                    </b>
                  </div>
                  <br />
                  <div hidden={openTooltip !== 1}>
                    Select weekly time slots that you&apos;ll
                    <br />
                    mostly be available on (minimum 2).
                    <br />
                    Please choose as many as possible
                    <br />
                    to maximise the quality of your curation.
                  </div>
                  <div hidden={openTooltip !== 2}>
                    Indicate exceptions for the next 1 month
                    <br />
                    (if any), try to be as detailed as possible
                    <br />
                    (i.e. Not available on the 15th Apr 3-4pm).
                    <br />
                    For rare occasions if you&apos;re only available
                    <br />
                    on 1/2 days please indicate: only available
                    <br />
                    on X/Y here too.
                  </div>
                  <br />
                  <Button
                    sx={{ fontSize: 12 }}
                    onClick={() => setOpenTooltip(openTooltip === 2 ? 0 : 2)}
                  >
                    Ok, Got It
                  </Button>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

function CheckBox({
  labelN, checked, toggleCheck, labelFor, color, size,
}) {
  const small = size === 'small';
  return (
    <div style={{
      display: 'flex', alignItems: 'flex-start', textAlign: 'left', gap: 8,
    }}
    >
      <div>
        <input
          name={labelFor}
          onChange={toggleCheck}
          type="checkbox"
          checked={checked}
          id={labelFor}
          style={{
            margin: 0, border: '1px solid #9e7666 !important', height: small ? 16 : 20, width: small ? 16 : 20, marginTop: 4,
          }}
        />
      </div>
      <div>
        <label htmlFor={labelFor} style={{ color: color || '#606060', fontSize: small ? 12 : 14 }}>{labelN}</label>
      </div>
    </div>
  );
}

const personalitiesOpt = [
  'Adaptable',
  'Adventurous',
  'Ambitious',
  'Ambivert',
  'Bubbly',
  'Conservative',
  'Compassionate',
  'Courageous',
  'Creative',
  'Diligent',
  'Disciplined',
  'Driven',
  'Easygoing',
  'Empathic',
  'Extrovert',
  'Humorous',
  'Family-oriented',
  'Fashionable',
  'Friendly',
  'Free-spirited',
  'Foodie',
  'Fun',
  'Funny',
  'Introvert',
  'Independent',
  'Jovial',
  'Laid-back',
  'Liberal',
  'Observant',
  'Open-minded',
  'Outgoing',
  'Organised',
  'Passionate',
  'Perfectionist',
  'Practical',
  'Philosophical',
  'Sporty',
  'Sincere',
  'Thinker',
];

const passionsOpt = [
  'Food',
  'Films',
  'Books',
  'Sports',
  'Dancing',
  'Singing',
  'Working Out',
  'Photography',
  'Travel',
  'Cooking',
  'Gaming',
  'Music',
  'Cafe or Bar hopping',
  'Hanging out with friends',
  'Shopping',
  'Writing',
  'Volunteering',
  'Art/DIY',
  'Exploring',
  'Drinking',
  'Museum',
  'Gardening',
  'Yoga',
  'Hiking',
  'Culture',
  'Politics',
  'History',
  'Science',
  'Technology',
  'Philosophy',
  'Business and Economics',
  'Education',
  'Environment',
  'Fashion',
  'Social Issues',
  'Religion and Spirituality',
];
