/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement, Elements, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useParams, useLocation } from 'react-router-dom';
import images from '../../Configs/images';
import { BottomNav } from '../../Elements';
import { auth, fFunction } from '../../Configs/firebase';
import { amplytics } from '../../utils/helper';
import { getUserData } from '../../utils/storage';

const stripeKey = process.env.REACT_APP_stripeKey;
const stripePromise = loadStripe(stripeKey);

export default function Component({ classes, history }) {
  useEffect(() => {
    amplytics('RescheduleFee View');
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className={classes.root}>
          <Container>

            <Elements stripe={stripePromise}>
              <CheckoutForm classes={classes} history={history} />
            </Elements>

          </Container>
        </div>
        <BottomNav active={3} />
      </Grid>
    </Grid>
  );
}

function CheckoutForm(props) {
  const { classes, history } = props;
  const { matchId } = useParams();
  const user = getUserData();
  useEffect(() => {
    if (!matchId) {
      alert('match not found');
      history.replace('/reschedule-date');
    }
  }, [matchId]);
  const stripe = useStripe();
  const elements = useElements();
  const [cardVal, setCardVal] = useState(null);
  const [error, setError] = useState('');
  useEffect(() => {
    if (!cardVal) {
      const c1 = elements?.getElement(CardElement);
      const c2 = elements?.getElement('card');
      setCardVal(c1 || c2);
    }
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const startReschedule = async () => {
    const rescheduleCallable = httpsCallable(fFunction, 'rescheduleDate');
    await rescheduleCallable({
      matchId,
      cancelledBy: user?.signupInfo?.gender,
      cancellationReason: location.state?.cancellationReason,
      uid: auth.currentUser.uid,
    })
      .then(() => {
        setTimeout(() => {
          window.location.replace('/chat');
        }, 300);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    setError('');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const callable = httpsCallable(fFunction, 'reschedulePayment');
    await callable({ userId: auth.currentUser?.uid, matchId }).then(async (intents) => {
      const { paymentIntent, setupIntent } = intents.data;
      console.log('data', intents.data);
      await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: { card: cardVal },
      }).then(async (result) => {
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          setError(result.error.message);
          setLoading(false);
        } else {
          console.log('payment success');
          amplytics('Reschedule Fee Paid');
          startReschedule();
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }

        if (setupIntent) {
          await stripe
            .confirmCardSetup(setupIntent.client_secret, {
              payment_method: { card: cardVal },
            })
            .then(async (res) => {
              if (res.error) {
                setError(res.error.message);
                setLoading(false);
              } else {
                console.log('payment success');
                amplytics('Reschedule Fee Paid');
                startReschedule();
              }
            });
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    });
  };

  return (
    <form
      action="/charge"
      method="post"
      onSubmit={handleSubmit}
      className="form"
    >
      {loading
          && (
          <div className={classes.loading2}>
            <div className="loadingText">Please wait...</div>
            <img alt="loading" src={images.Loading} width="100" height="100" />
          </div>
          )}
      <div
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
        }}
        className="center"
      >
        <div className="flex align-items-center" onClick={() => history.goBack()}>
          <ChevronLeftRounded />
          <div>BACK</div>
        </div>

        <div className="center" style={{ marginTop: 24 }}>
          <img src={images.Adhocdate} style={{ width: '80%' }} alt="adhocdate" />

          <div className="head star" style={{ marginTop: 8 }}>
            Reschedule Fee
          </div>

          <Container sx={{ my: 2, color: '#606060' }}>
            <div>
              A thoughtful experience designed for
              genuine connections in person.
            </div>
          </Container>
        </div>

        <div className="card-container">
          <CardElement className="field-pay" id="card" />
        </div>
        {Boolean(error) && (
          <div className="error-mssg">
            {error}
          </div>
        )}
        <div className="center" style={{ marginTop: 20 }}>
          <img alt="stripe" src={images.Stripe} height="40" width="160" />
          <div className="secure">
            All transactions are secure and encrypted.
          </div>
        </div>
        <button
          type="submit"
          className="onwards-btn"
        >
          <div className="onwards-text">
            ONWARDS
          </div>
        </button>
      </div>
    </form>
  );
}
