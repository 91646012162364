/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Container, Divider, Grid, IconButton,
} from '@mui/material';
import { CloseRounded, LocationOnOutlined, SellOutlined } from '@mui/icons-material';
import moment from 'moment-timezone';
import { doc, getDoc } from 'firebase/firestore';
import { BottomNav } from '../../Elements';
import { getNextMatch, getTodaysMatch, getUserData } from '../../utils/storage';
import { amplytics, getHour, getMinute } from '../../utils/helper';
import images from '../../Configs/images';
import { brown, grey, mute } from '../../Configs/colors';
import { firestore } from '../../Configs/firebase';

export default function UpcomingDate({ classes, history }) {
  // console.log(classes, history);
  useEffect(() => {
    document.title = 'Kopi Date Experience - Upcoming Date Details';
    amplytics('UpcomingDate View');
  }, []);

  const todaysMatch = getTodaysMatch();
  const nextMatch = getTodaysMatch() || getNextMatch();
  const user = getUserData();
  const [match, setMatch] = useState({});

  const [gender] = useState(user?.signupInfo?.gender);

  const [personality, setPersonality] = useState('');
  const [headline, setHeadline] = useState('');
  const [about, setAbout] = useState('');
  const [initial, setInitial] = useState('');
  const [cover, setCover] = useState('');
  const [dateIsTomorrow, setDateIsTomorrow] = useState(false);

  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false);

  function getMidnight(day) {
    const date = new Date(day);
    date.setMilliseconds(999);
    date.setSeconds(59);
    date.setMinutes(59);
    date.setHours(23);
    return date;
  }

  function isDateTomorrow(date) {
    const oneDay = 1000 * 60 * 60 * 24;
    const midnightTonight = getMidnight(new Date());
    const midnightTomorrow = new Date(midnightTonight.getTime() + oneDay);

    return date > midnightTonight && date < midnightTomorrow;
  }

  useEffect(async () => {
    let data = nextMatch;
    if (nextMatch) {
      const docRef = doc(firestore, `matches/${nextMatch.matchId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        data = docSnap.data();
      }

      const date = new Date(nextMatch.date.seconds * 1000);
      const { time } = nextMatch;

      date.setHours(getHour(time));
      date.setMinutes(getMinute(time));

      const idt = isDateTomorrow(date);
      setDateIsTomorrow(idt);

      data.date = date.getTime();

      setMatch(data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (gender === 'Male') {
      setPersonality(match.femalePersonality);
      setInitial(match.femaleAlias);
      setHeadline(match.gettingToKnowYouInfoFemale?.headline);
      setAbout(match.gettingToKnowYouInfoFemale?.hobbiesQuestion);
    } else if (gender === 'Female') {
      setPersonality(match.malePersonality);
      setInitial(match.maleAlias);
      setHeadline(match.gettingToKnowYouInfoMale?.headline);
      setAbout(match.gettingToKnowYouInfoMale?.hobbiesQuestion);
    }
  }, [match, gender]);

  useEffect(() => {
    if (match.postalCode) {
      switch (match.postalCode) {
        case 238245:
          setCover(images.CoverBotany);
          break;
        case 68815:
          setCover(images.CoverProvidore);
          break;
        case 179103:
          setCover(images.CoverProvidoreRaffles);
          break;
        case 307645:
          setCover(images.CoverCeline);
          break;
        case 178957:
          setCover(images.CoverMischief);
          break;
        case 39596:
          setCover(images.CoverBistrog);
          break;
        case 238259:
          setCover(images.CoverBeast);
          break;
        case 179020:
          setCover(images.CoverZorba);
          break;
        case 160049:
          setCover(images.CoverTiongBahru2);
          break;
        case 161048:
          setCover(images.CoverTiongBahru);
          break;
        case 188778:
          setCover(images.CoverRaving);
          break;
        case 238858:
          setCover(images.CoverEmeraldEscapade);
          break;
        default: setCover(images.CoverRaving);
      }
    }
  }, [match]);

  const handleClose = () => {
    history.replace('/');
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ background: '#fff', height: '100vh', overflow: 'hidden' }}>
        {loading
          ? (
            <div className={classes.loading}>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          )
          : (
            <Container sx={{ position: 'relative' }} className={classes.root}>
              <IconButton sx={{ position: 'absolute', right: 4, top: 10 }} onClick={handleClose}>
                <CloseRounded />
              </IconButton>
              <div className={classes.root}>

                <div>
                  <div style={{ fontSize: 22, color: brown, fontWeight: 'bold' }}>
                    Upcoming Kopi Date 🥳
                  </div>
                  <img alt="cover" src={cover} style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ color: brown, fontSize: 18, fontWeight: '800' }}>
                      You will be meeting ‘
                      {initial}
                      ’ ☕️
                    </div>
                    {/* <IconButton>
                      <FileUploadOutlined htmlColor={mute} />
                    </IconButton> */}
                  </div>
                </div>

                <div style={{ height: '55vh', overflow: 'auto', paddingRight: 10 }}>
                  <div className="flexRow">
                    <div className="icon-list">
                      <img alt="calendar" src={images.Calendar} height="14" width="14" />
                    </div>
                    <div>
                      <div className="list-title">Date & Time</div>
                      <div className="list-value">{moment(match.date).tz('Asia/Singapore').format('Do MMM, dddd, LT')}</div>
                    </div>
                  </div>

                  {(todaysMatch || dateIsTomorrow)
                  && (
                  <>
                    <Divider sx={{ mt: 1 }} />
                    <div className="flexRow">
                      <div className="icon-list">
                        <img alt="calendar" src={images.Calendar} height="14" width="14" />
                      </div>
                      <div>
                        <div className="list-title">Address</div>
                        <div className="list-value">{match.cafeName}</div>
                        <div className="list-value">{match.addressLineOne}</div>
                        <div className="list-value">{match.addressLineTwo}</div>
                      </div>
                    </div>
                  </>
                  )}

                  {(match?.postalCode === 307645 && todaysMatch)
                    && (
                    <>
                      <Divider sx={{ mt: 1 }} />
                      <div className="flexRow">
                        <div className="icon-list">
                          <LocationOnOutlined htmlColor="#9e7666" fontSize="small" />
                        </div>
                        <div>
                          <div className="list-title">Directions</div>
                        </div>
                      </div>
                      <div style={{ marginTop: 4 }}>
                        <iframe
                          style={{ height: 315 }}
                          src="https://www.youtube.com/embed/ZR-T_9qTKBg"
                          title="Celines Gelato"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </>
                    )}
                  {match?.postalCode === 88757 && todaysMatch
                    && (
                    <>
                      <Divider sx={{ mt: 1 }} />
                      <div className="flexRow">
                        <div className="icon-list">
                          <SellOutlined htmlColor="#9e7666" fontSize="small" />
                        </div>
                        <div>
                          <div className="list-title">Drink Voucher</div>
                          <div>
                            <a className="link" href={match.promo?.imgUrl} target="_blank" rel="noreferrer">Click here to get your drink voucher</a>
                          </div>
                        </div>
                      </div>
                    </>
                    )}

                  {(dateIsTomorrow || todaysMatch)
                  && (
                  <>
                    <Divider sx={{ mt: 1 }} />
                    <div className="flexRow">
                      <div className="icon-list">
                        <img alt="bubble" src={images.Bubble} height="14" width="14" />
                      </div>
                      <div>
                        <div className="list-title">Your date’s headline</div>
                        <div className="list-value">{headline || '-'}</div>
                      </div>
                    </div>

                    <Divider sx={{ mt: 1 }} />
                    <div className="flexRow">
                      <div className="icon-list">
                        <img alt="person" src={images.PersonOutlined} height="14" width="14" />
                      </div>
                      <div>
                        <div className="list-title">
                          About ‘
                          {initial}
                          ’
                        </div>
                        <div className="list-value">{about || '-'}</div>
                      </div>
                    </div>
                  </>
                  )}

                  <Divider sx={{ mt: 1 }} />
                  <div className="flexRow">
                    <div className="icon-list">
                      <img alt="wand" src={images.Wand} height="14" width="14" />
                    </div>
                    <div>
                      <div className="list-title">Your Kopi Date is someone who is</div>
                      <div className="list-value">{personality}</div>
                    </div>
                  </div>

                  {todaysMatch
                    ? (
                      <div style={{ marginTop: 12 }}>
                        <Button
                          onClick={() => history.push('/chat')}
                          id="btn-modal-submit-confirm-date"
                          fullWidth
                          style={{
                            color: '#fff', backgroundColor: 'orange', textTransform: 'none', borderRadius: 8, marginTop: 20,
                          }}
                          size="large"
                        >
                          CHAT WITH DATE
                        </Button>
                      </div>
                    ) : (
                      <>
                        <Divider sx={{ mt: 1 }} />
                        <div className="flexRow">
                          <div className="icon-list">
                            <img alt="lock" src={images.Lock} height="14" width="14" />
                          </div>
                          <div style={{ color: grey, fontSize: 12, textAlign: 'justify' }}>
                            Further instructions will be revealed one day
                            <br />
                            before date-day.
                          </div>
                        </div>
                      </>
                    )}

                  <div style={{
                    fontSize: 12, color: mute, marginTop: 20, paddingBottom: 80,
                  }}
                  >
                    Note:
                    <br />
                    What happens if I can&apos;t make it for the date due to emergency reasons?
                    {' '}
                    <b style={{ cursor: 'pointer', display: more ? 'none' : 'inline' }} onClick={() => setMore(true)}>Read more</b>
                    <br />
                    <br />
                    {more
                    && (
                    <span>
                      There will be a cancellation fee of 1 additional date
                      credit (billed to your card). Please inform us at 96685098
                      so we can notify your date immediately.
                      {' '}
                      <a style={{ cursor: 'pointer', textDecoration: 'underline', color: mute }} target="_blank" href="https://kopidate.com/faqrescheduling" rel="noreferrer">Read our FAQ for more.</a>
                      <b style={{ cursor: 'pointer' }} onClick={() => setMore(false)}> Read less</b>
                    </span>
                    )}
                  </div>
                </div>

              </div>
              <BottomNav active={0} />
            </Container>
          )}
      </Grid>
    </Grid>
  );
}
