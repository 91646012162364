import { Container } from '@mui/material';
import React from 'react';
// import { useHistory } from 'react-router-dom';
import images from '../../Configs/images';

export default function Component({ classes, handleRedirect }) {
  // const history = useHistory();
  // console.log(classes, history);
  return (
    <div className={classes.articleSection}>
      <Container>
        <div>
          <div className="title">Desserts for the soul</div>
        </div>
      </Container>
      <div className="articles">
        <img id="open-article-1" onClick={handleRedirect('https://medium.com/kopi-date/love-is-love-is-love-d31d518fb155')} alt="article" src={images.Article5} className="article" />
        <img id="open-article-2" onClick={handleRedirect('https://medium.com/kopi-date/opinion-relationships-can-be-fulfilling-if-you-dont-settle-cf4b7ba34c8d')} alt="article" src={images.Article1} className="article" />
        <img id="open-article-3" onClick={handleRedirect('https://medium.com/kopi-date/less-is-more-the-lure-of-ambiguity-82a896a3d57d')} alt="article" src={images.Article2} className="article" />
        <img id="open-article-4" onClick={handleRedirect('https://medium.com/kopi-date/do-first-impressions-actually-matter-646cb4ab2b91')} alt="article" src={images.Article3} className="article" />
        <img id="open-article-5" onClick={handleRedirect('https://medium.com/kopi-date/5-reminders-for-those-struggling-with-rejection-44fe81084d91')} alt="article" src={images.Article4} className="article" />
        <img id="open-article-6" onClick={handleRedirect('https://medium.com/kopi-date/5-things-good-listeners-do-c9b80eafa76b')} alt="article" src={images.Article6} className="article" />
      </div>
    </div>
  );
}
