/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import { BottomNav } from '../../Elements';
// import { limitToLast, onValue, query, ref } from '@firebase/database';
import images from '../../Configs/images';
import { brown } from '../../Configs/colors';
import { getLastMatch, getTodaysMatch, getUserData } from '../../utils/storage';
import { amplytics } from '../../utils/helper';

const hajiLane = 'https://docs.google.com/presentation/d/e/2PACX-1vQusfJSE55-nVAjCnXciEK_eGdg91Ul1_JxZ7Leb44Alv9Vk-oZElHxerbwWs7SCLZzxRZmmVevZuXX/embed?start=false&loop=false&delayms=60000';
const tiongBahruStroll = 'https://docs.google.com/presentation/d/e/2PACX-1vSxm1rVAmxJ3NRs8C4GjY97LgNNkOXCQgLKJbQPbttgE5d3IWgLoGRA260Lgx2G-6y6ax1l3QBQhqk6/embed?start=false&loop=false&delayms=3000';
const zorba = 'https://docs.google.com/presentation/d/e/2PACX-1vQV5-CuB8iakgjeB6aD9oAjB5I3ozcCHBAdK9Cmrc3OMOo_dFPeDDMSO0q2dysFWTn2maImEY7qY-Q1/embed?start=false&loop=false&delayms=3000';
const walkAndTalk = 'https://docs.google.com/presentation/d/e/2PACX-1vT-Ni6JmrZe1hY_taco3dRRbf6QMxiTyNC0bjRi4To1ITchfpRq4itGXrHfrLjuSBls4m3bWu0wWDhr/embed?start=false&loop=false&delayms=3000';
const mischief = 'https://docs.google.com/presentation/d/e/2PACX-1vTd9nnXC5e0ngfAJ6ot8sJykOgZR1inc6Ve7leOOH-pUSwMdFT-IRqILXxP5oC_ytU8ElSozM_Okcwl/embed?start=false&loop=false&delayms=3000';

export default function Kit({ history }) {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;

  const [url, setUrl] = useState('');
  const [urlMicrosoft, setUrlMicrosoft] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [postalCode, setPostalCode] = useState('');

  const [especial, setEspecial] = useState(false);

  useEffect(() => {
    document.title = 'Kopi Date Experience - Kit';
    amplytics('ExperienceKit View');
    async function fetchData() {
      // setUrl(tiongBahruStroll);
      // setUrlMicrosoft('tiongBahruStroll');
      // return;

      setLoading(true);

      const user = await getUserData();
      setUserData(user);

      const matchData = await getTodaysMatch();
      console.log(matchData);
      if (matchData && !matchData?.dateCanceled) {
        console.log('matchId', matchData.matchId);
        const postCode = matchData.postalCode || '';
        console.log('postalCode', postCode);

        setPostalCode(postCode);

        switch (postCode) {
          case 247933:
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://www.explore.kopidate.com/fantastic-farms-main-page' });
            break;
          case 238259:
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://www.explore.kopidate.com/beast-and-butterflies' });
            break;
          case 188778:
            setUrl(hajiLane);
            setUrlMicrosoft('hajiLane');
            break;
          case 238896:
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/ewet34254sfjdsnf232-323rfwegrjqi3jiqrf-w4tqtqtqwtfdsvbfdbf' });
            break;
          case 88757:
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/wyiyi6jgosdpfjtw34po6tueogq4939' });
            break;
          case 48625:
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/39uwihfiahfq-q3fbsjzberuqhr-t43w5qdcas-q2eascsd' });
            break;
          case 160049:
          case 161048:
            setUrl(tiongBahruStroll);
            setUrlMicrosoft('tiongBahruStroll');
            break;
          case 179020:
            setUrl(zorba);
            break;
          case 238858:
            setUrl(walkAndTalk);
            setUrlMicrosoft('walkAndTalk');
            break;
          case 178957:
            setUrl(mischief);
            setUrlMicrosoft('mischief');
            break;
          case 238245:
            console.log('its botany');
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/botany-main-page' });
            break;
          case 307645:
            console.log('its Celines');
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://www.explore.kopidate.com/celines-gelato-cart' });
            break;
          case 68815:
            console.log('its providore');
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://www.explore.kopidate.com/Providore-Downtown-Gallery' });
            break;
            // case 238259:
            //   console.log('its beast & butterflies');
            //   break;
          case 179103:
            console.log('its Raffles City');
            setEspecial(true);
            history.push('/explore/topic', { topicLink: 'https://www.explore.kopidate.com/providore-raffles-city' });
            break;
          default: {
            setUrl(null);
            console.log('postalCode not in list, show dummy image');
          }
        }
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  const handleGoToKopiDate = (linkTo) => {
    window.open(linkTo);
    // window.open(linkTo, '_blank');
  };

  const renderBooklet = () => {
    // if (postalCode === 238245) return images.Botany;
    if (postalCode === 307645) return images.Celine;
    if (postalCode === 68815) return images.Providore;
    // if (postalCode === 238259) return images.Beast;
    if (postalCode === 179103) return images.Raffles;

    return false;
  };

  const renderMicrosoftFiles = () => {
    if (urlMicrosoft === 'hajiLane') {
      return (
        <iframe title="kit" src="https://onedrive.live.com/embed?cid=4461682E22C35A55&amp;resid=4461682E22C35A55%211916&amp;authkey=ALNyzDRJF2t5wak&amp;em=2&amp;wdAr=0.5625" style={{ height: '92vh', width: '100%' }} frameBorder="0">
          This is an embedded
          <a target="_blank" href="https://office.com" rel="noreferrer">Microsoft Office</a>
          {' '}
          presentation, powered by
          <a target="_blank" href="https://office.com/webapps" rel="noreferrer">Office</a>
          .
        </iframe>
      );
    } if (urlMicrosoft === 'tiongBahruStroll') {
      return (
        <iframe title="kit" src="https://onedrive.live.com/embed?cid=4461682E22C35A55&amp;resid=4461682E22C35A55%211920&amp;authkey=AOxNfCGXoA0qQ54&amp;em=2&amp;wdAr=0.5625" style={{ height: '92vh', width: '100%' }} frameBorder="0">
          This is an embedded
          <a target="_blank" href="https://office.com" rel="noreferrer">Microsoft Office</a>
          {' '}
          presentation, powered by
          <a target="_blank" href="https://office.com/webapps" rel="noreferrer">Office</a>
          .
        </iframe>
      );
    } if (urlMicrosoft === 'walkAndTalk') {
      return (
        <iframe title="kit" src="https://onedrive.live.com/embed?cid=4461682E22C35A55&amp;resid=4461682E22C35A55%211914&amp;authkey=ALe1HtLAqyU6ILk&amp;em=2&amp;wdAr=0.5625" style={{ height: '92vh', width: '100%' }} frameBorder="0">
          This is an embedded
          <a target="_blank" href="https://office.com" rel="noreferrer">Microsoft Office</a>
          {' '}
          presentation, powered by
          <a target="_blank" href="https://office.com/webapps" rel="noreferrer">Office</a>
          .
        </iframe>
      );
    } if (urlMicrosoft === 'mischief') {
      return (
        <iframe title="kit" src="https://onedrive.live.com/embed?cid=4461682E22C35A55&amp;resid=4461682E22C35A55%211939&amp;authkey=AFdr0T39OV8mPsk&amp;em=2&amp;wdAr=0.5625" style={{ height: '92vh', width: '100%' }} frameBorder="0">
          This is an embedded
          <a target="_blank" href="https://office.com" rel="noreferrer">Microsoft Office</a>
          {' '}
          presentation, powered by
          <a target="_blank" href="https://office.com/webapps" rel="noreferrer">Office</a>
          .
        </iframe>
      );
    } return null;
  };

  // alert(urlMicrosoft)

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12}>

          {loading
            ? (
              <div style={{
                textAlign: 'center',
                background: '#fef8f4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '95vh',
              }}
              >
                <img alt="loading" src={images.Loading} style={{ width: 100, height: 100 }} />
              </div>
            )
            : (
              <div style={{
                paddingBottom: 20,
                background: '#fef8ee',
                overflowY: 'auto',
              }}
              >
                {url
                  ? (
                    <div>
                      <div style={{
                        height: '6vh',
                        background: '#282727',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      >
                        <Button sx={{ color: '#fff', fontWeight: '600' }} startIcon={(<ChevronLeftRounded />)} onClick={() => history.replace('')}>Back to Experience App</Button>
                      </div>
                      {isAndroid
                        ? renderMicrosoftFiles()
                        : <iframe src={url} title="kit" frameBorder="0" style={{ backgroundSize: 'cover', width: '100%', height: '94vh' }} allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />}
                    </div>
                  )

                  : (
                    <div style={{ background: '#fef8ee', minHeight: '95vh', padding: '20px 10px 60px' }}>
                      {(postalCode && renderBooklet(postalCode))
                      // Booklet
                        ? (
                          <Container style={{ textAlign: 'center' }}>
                            <div style={{ color: brown, fontSize: 24, fontWeight: '500' }}>Kopi Date Experience Kit</div>
                            <img alt="kopi" src={renderBooklet()} style={{ width: '100%', marginTop: 18, objectFit: 'cover' }} />
                            <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>It&apos;s date-day!</div>
                            <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>Kindly approach the friendly staff at the cafe for your Kopi Date Experience Kit!</div>
                          </Container>
                        )

                        : !userData?.planType
                        // User has no planType
                          ? (
                            <Container style={{ textAlign: 'center' }}>
                              <div style={{ color: brown, fontSize: 24, fontWeight: '500' }}>The Kopi Date Experience</div>
                              <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>
                                Hey
                                {' '}
                                {userData?.signupInfo?.firstName}
                                , thank you for being part of Kopi Date community!
                              </div>
                              <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>
                                You are currently not on any plan.
                                {' '}
                                <a href="/subscription" style={{ color: brown }} target="_blank" rel="noreferrer">Tap here</a>
                                {' '}
                                to kickstart your next curation!
                              </div>
                              <img alt="kopi" src={images.NoPlan} style={{ width: '100%', marginTop: 18, objectFit: 'cover' }} />
                              <button
                                onClick={() => history.push('/subscription')}
                                style={{
                                  cursor: 'pointer',
                                  background: '#6dc682',
                                  marginTop: 14,
                                  border: 'none',
                                  borderRadius: 10,
                                  width: '100%',
                                  padding: '12px 14px',
                                }}
                              >
                                <div style={{
                                  textAlign: 'center',
                                  width: '100%',
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  color: '#fff',
                                }}
                                >
                                  Unlock Kopi Date
                                </div>
                              </button>
                            </Container>
                          )

                          : userData?.userState === 5
                          // User Matched but not date-day. Havent/have confirmed
                            ? (
                              <Container style={{ textAlign: 'center' }}>
                                <div style={{ color: brown, fontSize: 24, fontWeight: '500' }}>Congrats, you have a date!</div>
                                <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>Good news! We have found you a kopi date!</div>
                                <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>
                                  Don&apos;t keep your date waiting! If you have yet to
                                  {' '}
                                  <span
                                    onClick={() => history.push('/date-details')}
                                    style={{
                                      color: brown,
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    confirm the date
                                  </span>
                                  , kindly do so ASAP to avoid forfeiting a credit.
                                </div>
                                <img alt="kopi" src={images.Cafe} style={{ width: '100%', marginTop: 18, objectFit: 'cover' }} />
                                <button
                                  onClick={() => history.push('/date-details')}
                                  style={{
                                    cursor: 'pointer',
                                    background: '#9e7666',
                                    marginTop: 14,
                                    border: 'none',
                                    borderRadius: 10,
                                    width: '100%',
                                    padding: '12px 14px',
                                  }}
                                >
                                  <div style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: '#fff',
                                  }}
                                  >
                                    Confirm date
                                  </div>
                                </button>
                              </Container>
                            )

                          // Not on any date
                            : (
                              <Container style={{ textAlign: 'center' }}>
                                <div style={{ color: brown, fontSize: 24, fontWeight: '500' }}>Your Kopi Date is Brewing</div>
                                <img alt="kopi" src={images.PinkCup} style={{ width: '100%', marginTop: 18, objectFit: 'cover' }} />
                                <div style={{ fontSize: 14, color: '#000', marginTop: 14 }}>You&apos;ll hear from us via email when your date is finalised. Kindly update your availability details for your next date.</div>
                                <button
                                  onClick={() => history.push('/availability')}
                                  style={{
                                    cursor: 'pointer',
                                    background: '#9e7666',
                                    marginTop: 14,
                                    border: 'none',
                                    borderRadius: 10,
                                    width: '100%',
                                    padding: '12px 14px',
                                  }}
                                >
                                  <div style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: '#fff',
                                  }}
                                  >
                                    Update availability
                                  </div>
                                </button>
                              </Container>
                            )}
                    </div>
                  )}
              </div>
            )}
        </Grid>
      </Grid>
      <BottomNav active={2} />
    </>
  );
}
