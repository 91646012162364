/* eslint-disable */
const questions = [
  {
    question: `What is your most treasured memory?`,
    rating: 2,
    author: 'Michelle',
  },
  {
    question: `When do you feel most motivated?`,
    rating: 123,
    author: 'Janice',
  },
  {
    question: `What's one thing you're most happy to spend your money on?`,
    rating: 42,
    author: 'Melissa',
  },
  {
    question: `What is one thing you got over your fear of?`,
    rating: 26,
    author: 'Nicholas',
  },
];

export default questions;
