/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
} from '@mui/material';
import {
  doc, setDoc, Timestamp, collection, getDoc, getDocs, query as firestoreQuery, where, updateDoc,
} from 'firebase/firestore';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  auth, firestore,
} from '../../Configs/firebase';
import {
  getEnv,
  getExplorePeople,
  getLikedPeople,
  setLikedPeople,
} from '../../utils/storage';
import images from '../../Configs/images';
import { amplytics, reduceMonths } from '../../utils/helper';
import { brown } from '../../Configs/colors';
import { BaseModal } from '../../Elements';

export default function Recommendation({ classes }) {
  const history = useHistory();
  const explorePeople = getExplorePeople();
  const env = getEnv();
  const [rec, setRec] = useState(getLikedPeople());
  const [detailPeople, setDetailPeople] = useState('');

  const handleCloseDetailPeople = () => {
    setDetailPeople('');
  };

  const getinfo = async (person) => {
    // console.log(rec);
    // console.log('hi test', person.signupInfo.uid);
    const docRef = doc(firestore, `/userLikes/${auth.currentUser?.uid}`);
    const docSnap = await getDoc(docRef);
    let store = setDoc;
    if (docSnap.exists()) {
      store = updateDoc;
    }

    const recommend = [
      ...rec,
      {
        fullName: person.fullName || person.firstName,
        headline: person.headline,
        hobbiesQuestion: person.hobbiesQuestion,
        occupation: person.occupation,
        uid: person.uid,
      },
    ];

    const body = {
      recommend,
      uid: auth.currentUser?.uid,
    };

    await store(docRef, body);
    setLikedPeople(body.recommend);
    setRec(body.recommend);
    await amplytics('People get liked');
  };

  const deleteRec = async (people) => {
    const newRec = rec.filter((p) => p.uid !== people.uid);
    const docRef = doc(firestore, `/userLikes/${auth.currentUser?.uid}`);
    const docSnap = await getDoc(docRef);
    let store = setDoc;
    if (docSnap.exists()) {
      store = updateDoc;
    }

    const body = {
      recommend: newRec,
      uid: auth.currentUser?.uid,
    };

    await store(docRef, body);
    setLikedPeople(body.recommend);
    setRec(body.recommend);
    await amplytics('People get unliked');
  };

  const likedPeople = (people) => {
    const likedArr = rec.filter((p) => p.uid === people.uid);
    if (likedArr.length) {
      return true;
    }

    return false;
  };

  const handleTo = (link) => () => {
    // amplytics(`Home tap to ${destiny || link}`);
    history.push(link);
  };

  return (
    <div hidden={!explorePeople?.length || env.recommendPeople === false}>
      <div className={classes.recommendationSection}>
        <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="title">Explore people you might meet</div>
          <IconButton onClick={handleTo('/explore-people')}>
            <img src={images.Thumbs} alt="Thumbs" width="40" height="40" />
          </IconButton>
        </Container>
        <div>
          <div className="cards">

            <div className="card" id="recommmend">
              {explorePeople?.map((people, index) => (
                <Fragment key={index}>
                  <div className="people">
                    <IconButton
                      onClick={() => {
                        if (likedPeople(people)) deleteRec(people);
                        else getinfo(people, index);
                      }}
                      className="thumbsup"
                    >
                      <img src={likedPeople(people) ? images.Thumbsupgreen : images.Thumbsup} alt="Thumbs" width="20" height="20" />
                    </IconButton>
                    <div
                      onClick={() => setDetailPeople(people)}
                      className="two-lines headline"
                    >
                      {people.headline}
                    </div>
                    <div onClick={() => setDetailPeople(people)} className="four-lines hobbies">
                      {people.hobbiesQuestion}
                    </div>
                    <div onClick={() => setDetailPeople(people)} className="occupation">
                      {people.occupation}
                    </div>
                  </div>
                  {(index === 3 || index === explorePeople.length - 1)
                    && (
                    <div className="people">
                      <div className="two-lines edit-profile">
                        Feeling inspired?
                        <br />
                        Update your profile!
                      </div>
                      <button className="btn-edit" onClick={handleTo('/knowing-the-real-you')}>EDIT PROFILE</button>
                    </div>
                    )}
                </Fragment>
              ))}
            </div>

            <BaseModal open={Boolean(detailPeople)} title="" actionFull handleClose={handleCloseDetailPeople} noSubmit>
              <div className={classes.peopleCard}>
                {/* <img src={images.Thumbsup} alt='Thumbs' width="40" height="40" /> */}
                <div className="headline">
                  {detailPeople?.headline}
                </div>
                <div className="hobbies">
                  {detailPeople?.hobbiesQuestion}
                </div>
                <div className="occupation">
                  {detailPeople?.occupation}
                </div>
              </div>
            </BaseModal>

          </div>
        </div>
      </div>
    </div>
  );
}
