import { Box, Container } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import images from '../../Configs/images';
import { OrangeButton } from '../../Elements';

export default function Component({ classes }) {
  const history = useHistory();
  console.log(classes, history);
  return (
    <div className={classes.root}>
      <Container>
        <div className="title">Understand Yourself</div>
      </Container>
      <Box className="flex" sx={{ mt: 1 }}>
        <div className="left-sect">
          <img
            src={images.Coachgirlsmile}
            alt="coach girl smile"
            className="coach-girl"
          />
        </div>
        <div className="right-sect">
          <img src={images.New} alt="new" height="20" />
          <div className="head">Individual 1:1 Coaching</div>
          <div className="desc">*Fully booked</div>
          <div className="full-desc">
            Unpick confusion; find the targeted and clear answers you deserve.
          </div>
          <OrangeButton
            text="LEARN MORE"
            rounded
            styles={{
              padding: '4px 16px',
              fontWeight: 'bold',
              fontSize: 10,
              marginTop: 8,
            }}
            onClick={() => history.push('/explore', { tab2: true })}
          />

          <div className="waitlist-desc">
            <i>
              Waitlist is open.
            </i>
          </div>
        </div>
      </Box>
    </div>
  );
}
