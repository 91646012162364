const styles = {
  articleSection: {
    padding: 8,
    position: 'relative',
    '& .title': {
      color: '#9e7666',
      fontSize: 17,
      fontWeight: 'bold',
    },
    '& .see-all': {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 8,
      top: 6,
      color: '#9e7666',
      fontSize: 14,
      fontWeight: '400',
    },
    '& .articles': {
      marginTop: 10,
      display: 'flex',
      overflow: 'auto',
      gap: 4,
      '&::-webkit-scrollbar': {
        display: 'none',
        scrollbarWidth: 'none',
      },
    },
    '& .article': {
      width: '43%',
      borderRadius: 10,
      objectFit: 'contain',
      cursor: 'pointer',
    },
  },
};

export default styles;
