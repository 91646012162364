/* eslint-disable react/jsx-props-no-spreading */
import {
  ClickAwayListener, Container, Grid, Grow, Popper,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import images from '../../Configs/images';
import {
  getGettingToKnowYou,
  getProfilePicture,
  getUserData, getUserData1,
} from '../../utils/storage';

export default function Component({ classes }) {
  const history = useHistory();
  const user = getUserData();
  const user1 = getUserData1();
  const gettingToKnowYouInfo = getGettingToKnowYou();
  const profilePicture = getProfilePicture();

  const [openTooltip, setOpenTooltip] = useState(false);
  const anchorEl = useRef(null);

  const handleTo = (link) => () => {
    // amplytics(`Home tap to ${destiny || link}`);
    history.push(link);
  };

  return (
    <div className="profile-section">
      <Container>
        <Grid container alignItems="flex-start">
          <Grid item xs={8}>
            <div style={{ marginTop: 25 }}>
              <div style={{ color: '#9e7666', fontSize: 28, fontWeight: '500' }}>
                Hello,
                {' '}
                {user?.signupInfo?.firstName}
                👋🏻
              </div>
              <div style={{
                color: '#9e7666',
                fontSize: 12,
                fontWeight: '500',
              }}
              >
                {gettingToKnowYouInfo?.headline}
              </div>
            </div>

            <div style={{
              display: 'flex',
              marginTop: 16,
              alignItems: 'center',
              marginBottom: 25,
            }}
            >
              <img
                alt="you"
                src={profilePicture || user.photoURL}
                width="72"
                height="85"
                style={{
                  borderRadius: 50,
                  objectFit: 'cover',
                  border: '3px solid #fff',
                }}
              />
              <div style={{
                marginLeft: 10,
                fontSize: 12,
                color: '#555',
                fontWeight: '500',
              }}
              >
                <div role="button" onKeyDown={null} tabIndex={0}>
                  <div className="share-link" onClick={handleTo('/adhoc')}>
                    {user?.creditBalance || 0}
                    {' '}
                    credits remaining
                  </div>
                  <div className="share-link" onClick={handleTo('/referral')}>
                    {user?.completedReferralsCount || 0}
                    {' '}
                    friends referred
                  </div>
                </div>
                <div style={{ marginTop: 16, fontWeight: '700' }}>
                  Rating:
                  {' '}
                  {user1.convoRateAvg }
                  /5
                  {' '}
                  ⭐️
                  <img
                    ref={anchorEl}
                    onClick={() => setOpenTooltip(true)}
                    src={images.Info}
                    alt="info"
                    style={{
                      display: 'inline-block',
                      height: 12,
                      width: 12,
                      position: 'relative',
                      top: 1,
                      left: 4,
                    }}
                  />
                  <Popper
                    anchorEl={anchorEl.current}
                    disablePortal
                    className={classes.grow}
                    open={openTooltip}
                    role={undefined}
                    transition
                    placement="bottom-start"
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                      >
                        <div className={classes.popProfilCard}>
                          <ClickAwayListener
                            onClickAway={() => setOpenTooltip(false)}
                          >
                            <div style={{
                              color: '#6f6c54',
                              backgroundColor: '#ffffff',
                              padding: 10,
                              borderRadius: 8,
                              marginRight: 20,
                            }}
                            >
                              Enjoy better curations when you
                              <br />
                              create great experiences for others.
                              <br />
                              <br />

                              How star ratings work:
                              <br />
                              <br />
                              After each date, members have the opportunity to review the date,
                              based on their conversational experience. Ratings are anonymous.
                              You won’t see individual ratings tied to a particular date or person.
                              <br />
                              <br />
                              This helps us better curate your experiences for our community.
                            </div>
                          </ClickAwayListener>
                        </div>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: 'right', marginTop: 20 }}>
              <img alt="kopi-date" src={images.KopiDate} width="40" height="20" />
            </div>
          </Grid>
        </Grid>
      </Container>
      <img
        alt="kopi-date"
        src={images.SocialClub}
        width="80"
        onClick={handleTo('/referral')}
        style={{
          position: 'absolute', bottom: 16, right: 16, zIndex: 1,
        }}
      />
    </div>
  );
}
