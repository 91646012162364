import { bg_brown_light, brown, grey } from '../../Configs/colors';

const styles = {
  root: {
    paddingBottom: 56,
    height: '100vh',
    overflow: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    '& .cover': {
      background: bg_brown_light,
      position: 'sticky',
      zIndex: 0,
      padding: '24px 0 48px',
      textAlign: 'center',
      top: 0,
      '& .title': {
        fontSize: 20,
        color: '#000',
        fontWeight: '500',
        marginBottom: 8,
      },
      '& .desc': {
        fontSize: 14,
        color: grey,
        marginBottom: 16,
      },
    },
    '& .content': {
      borderRadius: '32px 32px 0 0',
      marginTop: -32,
      position: 'relative',
      zIndex: 1,
      background: '#fff',
      boxShadow: '5px -5px 5px rgba(0,0,0,.05)',
      paddingTop: 16,
    },
    '& .more-content-info': {
      color: '#000',
      fontSize: 12,
      margin: '16px 0',
    },
    '& .new-feature': {
      color: '#F99926',
      position: 'absolute',
      top: 2,
      right: 2,
      fontSize: 8,
      fontWeight: '600',
    },
    '& .head2': {
      fontSize: 18,
      color: brown,
      fontWeight: '600',
      marginBottom: 16,
    },
    '& .card-content': {
      background: '#f7f4ef',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 2,
      alignItems: 'center',
      marginBottom: 8,
      '&.free': {
        background: '#ffd561',
      },
      '& .left-content': {
        padding: '0 8px 0 24px',
        '& .title': {
          color: '#000',
          fontWeight: '500',
          fontSize: 14,
        },
        '& .desc': {
          color: '#000',
          fontWeight: '400',
          fontSize: 12,
          marginTop: 4,
        },
        '& .more-btn': {
          textTransform: 'none',
          color: '#000',
          padding: '6px 24px',
          border: 'none',
          borderRadius: 25,
          background: '#fff',
          textDecoration: 'none',
          display: 'inline-block',
          fontWeight: 'bold',
          fontSize: 13,
          marginTop: 12,
          marginLeft: -4,
          outline: 'none',
        },
        '& .locked': {
          gap: 8,
          fontSize: 12,
          color: brown,
          marginTop: 16,
        },
      },
    },
  },
  tabs: {
    '& :hover': {
      color: brown,
      opacity: 1,
    },
    '& .Mui-selected': {
      color: `${brown} !important`,
      fontWeight: 'bold',
    },
    '& .Mui-focusVisible': {
      backgroundColor: `${brown} !important`,
    },
    '& .tab': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 0,
      padding: 0,
      '&:first-child': {
        alignItems: 'flex-start',
      },
      '&:last-child': {
        alignItems: 'flex-end',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      //   backgroundColor: 'transparent',
      backgroundColor: brown,
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 100,
      width: '100%',
    },
  },
};

export default styles;
