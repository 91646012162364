/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { auth } from '../../Configs/firebase';
import images from '../../Configs/images';
import { amplytics } from '../../utils/helper';
import { getTodaysMatch } from '../../utils/storage';

// It needs rootRef
export default function KitWidget({ rootRef }) {
  const floatingWidgetRef = useRef();
  // const todaysMatch = getTodaysMatch();

  const history = useHistory();
  const location = useLocation();
  const blackListRoutes = ['kit', 'test', 'chat', 'feedback', 'explore-people', 'exploretopic', 'onboarding', 'waitlisted', 'setup-profile', 'payment', 'calendly', 'explore'];
  const firstPath = location.pathname.split('/')[1];
  const oneOfBlacklist = blackListRoutes.includes(firstPath);

  useEffect(() => {
    const timer = setTimeout(() => {
      const fabElement = floatingWidgetRef.current;
      const wrapperElement = rootRef.current;
      //   let oldPositionX;
      //   let oldPositionY;

      const move = (e) => {
        if (e.type === 'touchmove') {
          fabElement.style.top = `${e.touches[0].clientY}px`;
          fabElement.style.left = `${e.touches[0].clientX}px`;
        } else {
          fabElement.style.top = `${e.clientY}px`;
          fabElement.style.left = `${e.clientX}px`;
        }
      };

      const mouseDown = (e) => {
        // console.log('mouse down ');
        window.history.scrollRestoration = 'manual';
        // oldPositionY = fabElement.style.top;
        // oldPositionX = fabElement.style.left;
        if (e.type === 'mousedown') {
          window.addEventListener('mousemove', move);
        } else {
          window.addEventListener('touchmove', move);
        }

        fabElement.style.transition = 'none';
      };

      const snapToSide = (e) => {
        const windowWidth = window.innerWidth;
        let currPositionX; let
          currPositionY;
        if (e.type === 'touchend') {
          currPositionX = e.changedTouches[0].clientX;
          currPositionY = e.changedTouches[0].clientY;
        } else {
          currPositionX = e.clientX;
          currPositionY = e.clientY;
        }
        if (currPositionY < 50) {
          fabElement.style.top = `${50}px`;
        }
        if (currPositionY > wrapperElement?.clientHeight - 50) {
          fabElement.style.top = `${wrapperElement?.clientHeight - 50}px`;
        }
        if (currPositionX < windowWidth / 2) {
          fabElement.style.left = `${40}px`;
          fabElement.classList.remove('right');
          fabElement.classList.add('left');
        } else {
          fabElement.style.left = `${windowWidth - 40}px`;
          fabElement.classList.remove('left');
          fabElement.classList.add('right');
        }
      };

      const mouseUp = (e) => {
        // console.log('mouse up');
        window.history.scrollRestoration = 'manual';
        if (e.type === 'mouseup') {
          window.removeEventListener('mousemove', move);
        } else {
          window.removeEventListener('touchmove', move);
        }
        snapToSide(e);
        fabElement.style.transition = '0.3s ease-in-out left';
      };

      fabElement.addEventListener('mousedown', mouseDown);

      fabElement.addEventListener('mouseup', mouseUp);

      fabElement.addEventListener('touchstart', mouseDown);

      fabElement.addEventListener('touchend', mouseUp);

    //   fabElement.addEventListener('click', () => {
    //     if (
    //       oldPositionY === fabElement.style.top
    //       && oldPositionX === fabElement.style.left
    //     ) {
    //       fabElement.classList.toggle('fab-active');
    //     }
    //   });
    }, 800);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div id="floating-snap-btn-wrapper" ref={floatingWidgetRef} hidden={oneOfBlacklist || !auth.currentUser}>
      <div className="fab-btn">
        <img
          onClick={() => {
            amplytics('Clicks on experience widget');
            history.push('/kit');
          }}
          src={images.Openkit}
          alt="open kit"
          style={{ height: 80, widht: '80px important' }}
        />
      </div>
    </div>
  );
}
