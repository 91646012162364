/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import {
  Check, ChevronLeftRounded, LogoutRounded, VisibilityOffOutlined,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  Snackbar,
  TextField,
} from '@mui/material';
// import { DatePicker, LocalizationProvider, MuiPickersAdapter } from '@mui/lab'
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
// import DateAdapter from '@date-io/date-fns';
import DateAdapter from '@mui/lab/AdapterDateFns';
// import DateAdapter from '@mui/lab/AdapterDateFns'
import {
  doc, getDoc, setDoc, updateDoc,
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { TikTok } from 'react-tiktok';
import { signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import PhoneInput from 'react-phone-input-2';
import { ConfirmationModal } from '../../Elements';
import { analytics, isiOS, validateEmail } from '../../utils/helper';
import {
  clearStorage, getProfilePicture, getUserData, setUserData,
} from '../../utils/storage';
import {
  auth, fFunction, firestore, storage,
} from '../../Configs/firebase';
import images from '../../Configs/images';
import 'react-phone-input-2/lib/material.css';

export default function SetupProfile(props) {
  const { classes, history } = props;
  const isIOS = isiOS();
  const user = getUserData();
  const profilePict = getProfilePicture();

  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState('');

  useEffect(() => {
    document.title = 'Kopi Date Experience - Setup Profile';

    setPP(profilePict);
    setInput({
      ...user?.signupInfo,
      photo: Boolean(profilePict),
      email: user.signupInfo?.email || auth.currentUser.email,
      birthDate: user.signupInfo?.age ? new Date(user.signupInfo.age.seconds * 1000) : new Date(),
    });
  }, []);

  const [pp, setPP] = useState('');
  const inputFileRef = useRef(null);
  const countryRef = useRef(null);
  const onBtnClick = () => {
    /* Collecting node-element and performing click */
    inputFileRef.current.click();
  };
  const onCountrySelect = () => {
    /* Collecting node-element and performing click */
    countryRef.current.click();
  };
  const onFilechange = async (e) => {
    /* Selected files data can be collected here. */
    setSnack('Uploading image...');
    const file = e.target.files[0];

    const fileRef = ref(storage, `profile-picutre/${auth.currentUser.email}`);
    await uploadBytes(fileRef, file)
      .then(() => {
        setPP(URL.createObjectURL(file));
        setInput({ ...input, photo: true });
        setSnack('Upload success');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const [input, setInput] = useState({
    firstName: '',
    gender: '',
    birthDate: '',
    religion: '',
    occupation: '',
    school: '',
    instaHandle: '',
    linkedinUrl: '',
    contactNumber: '',
    lastName: '',
    meetAnyoneQuestion: '',
    promoCode: '',
    photo: false,
    doesUserUseDatingApps: '',
    email: '',
    dislikeMostAboutDatingApps: '',
    selfIntroduction: '',
  });

  const [error, setError] = useState({
    firstName: false,
    gender: false,
    birthDate: false,
    religion: false,
    occupation: false,
    school: false,
    instaHandle: false,
    linkedinUrl: false,
    lastName: false,
    meetAnyoneQuestion: false,
    promoCode: false,
    doesUserUseDatingApps: false,
    email: false,
    dislikeMostAboutDatingApps: false,
    selfIntroduction: false,
  });

  const [step, setStep] = useState(0);

  const [fields] = useState([
    {
      title: 'Step 1',
      desc: 'Let’s start with the basics',
      image: <img src={images.Signup1} alt="about you" className="signupImage" />,
      desc2: 'Set up your profile to meet new people.',
    },
    {
      title: 'About you',
      desc: 'What’s your email?',
      field: 'email',
    },
    {
      title: 'About you',
      desc: 'What’s your name?',
      field: 'name',
    },
    {
      title: 'About you',
      desc: 'Birthdate',
      field: 'birthDate',
    },
    {
      title: 'About you',
      desc: 'Gender',
      field: 'gender',
    },
    {
      title: 'About you',
      desc: 'Race',
      field: 'race',
    },
    {
      title: 'About you',
      desc: 'Religion',
      field: 'religion',
    },
    {
      title: 'About you',
      desc: 'Contact Number',
      field: 'contactNumber',
    },
    {
      title: 'Step 2',
      desc: 'Tell us about yourself',
      image: <img src={images.TellUsMore} alt="about you" style={{ width: '40%' }} />,
      desc2: 'So we can curate interesting people for you.',
    },
    {
      title: 'Dig Deeper',
      desc: 'Are you using dating apps?',
      field: 'doesUserUseDatingApps',
    },
    {
      title: 'Dig Deeper',
      desc: 'Why do you dislike most\nabout dating apps?',
      field: 'dislikeMostAboutDatingApps',
    },
    {
      desc: 'We got you covered 🎉',
      field: 'social',
      btnColor: 'orange',
      seeOnly: true,
    },
    {
      desc: 'Focus on connection',
      field: 'offers',
      btnColor: 'orange',
      seeOnly: true,
    },
    {
      desc: 'Discover unique cafès\nin Singapore!',
      field: 'discoverUniqueSpotTT',
      seeOnly: true,
    },
    {
      title: 'Dig Deeper',
      desc: 'Add a photo of you',
      desc2: <>
        <VisibilityOffOutlined fontSize="small" />
          &nbsp;&nbsp;
        <span>This info will NOT be visible to others.</span>
       </>,
      field: 'photo',
    },
    {
      title: 'Dig Deeper',
      desc: 'If you could meet anyone in\nthis world, who would\nyou meet, and why?',
      field: 'meetAnyoneQuestion',
    },
    // {
    //     title: 'Dig Deeper',
    //     desc: 'How would you like\nto be intro’d?',
    //     field:'selfIntroduction'
    // },
    {
      title: 'Dig Deeper',
      desc: 'Occupation',
      field: 'occupation',
    },
    {
      title: 'Dig Deeper',
      desc: 'Last school\nattended/attending',
      field: 'school',
    },
    {
      title: 'Dig Deeper',
      desc: 'Instagram Handle',
      field: 'instaHandle',
      desc2: 'This tells us you\'re real.',
    },
    {
      title: 'Dig Deeper',
      desc: 'LinkedIn URL',
      desc2: 'This let us know you’re not a robot.',
      field: 'linkedinUrl',
      btnText: 'SUBMIT',
      btnColor: 'orange',
    },
  ]);

  const active = fields[step];

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (active.field === 'discoverUniqueSpotTT') {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 5000);
    } else setDisable(false);
  }, [active]);

  useEffect(() => {
    // if (fields[step]?.field) {
    //   history?.replace(`/setup-profile/${fields[step]?.field}`);
    //   document.title = `Kopi Date Experience - Setup Profile | ${fields[step]?.field}`;
    // } else {
    //   history?.replace('/setup-profile');
    //   document.title = 'Kopi Date Experience - Setup Profile';
    // }
  }, [step]);

  // useEffect(()=>{
  //     if(active.field){
  //         storeToAnalytic(`setupProfile-${active.field}`)
  //     }
  // },[step])

  const handleNext = (e) => {
    e.preventDefault();
    if (
      (active.field === 'name' && input.firstName)
            || (active.field === 'email' && validateEmail(input.email))
            || (active.field === 'gender' && (input.gender || input.gender2))
    ) {
      setError({
        ...error, firstName: false, email: false, gender: false,
      });
      setStep(step + 1);
    } else if (active.field === 'name' && !input.firstName) {
      setError({ ...error, firstName: true });
    } else if (active.field === 'email' && !validateEmail(input.email)) {
      setError({ ...error, email: true });
    } else if (active.field === 'gender' && !input.gender && !input.gender2) {
      setError({ ...error, gender: true });
    } else if (
      (active.field && !input[active.field] && active.field !== 'promoCode' && !active.seeOnly)
                || (active.field === 'photo' && !pp)
                || (active.field === 'selfIntroduction' && input.selfIntroduction?.length < 15)) {
      setError({ ...error, [active.field]: true });
    } else {
      if (active.field) {
        setError({ ...error, [active.field]: false });
      }
      setStep(step + 1);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (step >= 1) { setStep(step - 1); } else history.replace('/');
  };

  const handleSubmit = async () => {
    setLoading(true);

    analytics('/btn-save-profile');
    const docRef = doc(firestore, `/user/${auth.currentUser.uid}`);

    const userInfo = input;
    delete userInfo.photo;
    userInfo.authEmail = auth.currentUser.email;
    userInfo.uid = auth.currentUser.uid;
    userInfo.fullName = `${input.firstName} ${input.lastName}`;
    userInfo.age = new Date(input.birthDate);
    delete input.birthDate;

    let handleStoreData = setDoc;
    if (user?.signupInfo?.uid) handleStoreData = updateDoc;
    await handleStoreData(docRef, {
      signupInfo: userInfo,
    }).then(async () => {
      if (!user?.signupInfo?.uid) {
        const authUserDocRef = doc(firestore, `/authenticationUsers/${auth.currentUser.uid}`);
        const userAuthSnap = await getDoc(authUserDocRef);
        if (userAuthSnap.exists()) {
          await updateDoc(authUserDocRef, {
            signUpTime: new Date(),
          });
        }
      }
      const callable = httpsCallable(fFunction, 'processDirectEnrolment');
      await callable({ userId: auth.currentUser.uid })
        .then(async (res) => {
          const wasDirectEnrol = res.data;
          console.log(wasDirectEnrol);
          await setUserData(auth.currentUser);
          history.replace('/waitlisted');
        });
    }).catch((err) => {
      alert(err.message);
      console.log(err);
      setLoading(false);
    });

    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleChangeBirtDate = (newVal) => {
    setInput({ ...input, birthDate: new Date(newVal) });
  };

  const handleRedirect = (link) => () => {
    window.open(link, '_blank');
  };

  const [phoneCode, setPhoneCode] = useState(countries[0]);

  const renderField = () => {
    switch (active.field) {
      case 'social': return (
        <>
          <div style={{
            color: '#606060', fontWeight: 'bold', fontSize: 16, marginBottom: 20,
          }}
          >
            You&#39;re on your way to the most
            <br />
            humanised social expeirence!
          </div>
          <img loading="eager" src={images.Social} alt="socials" style={{ width: '80%' }} />
          <img loading="eager" src={images.GoogleStar} alt="socials" style={{ width: '35%', marginTop: 20 }} />
        </>
      );
      case 'offers': return (
        <>
          <div style={{ color: '#606060', fontSize: 16, marginBottom: 20 }}>
            Kopi Date offers the
            {' '}
            <b>easiest,</b>
            <br />
            most
            {' '}
            <b>stress-free</b>
            {' '}
            way to meet
            <br />
            interesting people.
          </div>
          <img loading="eager" src={images.Offers} alt="socials" style={{ width: '75%' }} />
        </>
      );
      case 'name': return (
        <>
          {input.firstName
                        && (
                        <div className="nice">
                          {input.firstName}
                          . Has a nice ring to it.
                        </div>
                        )}
          <label className="label">First name</label>
          <TextField
            error={error.firstName}
            sx={{ mb: 2 }}
            name="firstName"
            value={input.firstName}
            onChange={handleChangeInput}
            fullWidth
            onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }}
            size="large"
            variant="outlined"
            style={{ width: '90%', background: '#fff' }}
            InputProps={input.firstName ? {
              endAdornment: (
                <Check style={{ color: '#2E99A5' }} />
              ),
            } : null}
          />

          <label className="label">Last name</label>
          <TextField
            error={error.lastName}
            name="lastName"
            value={input.lastName}
            onChange={handleChangeInput}
            fullWidth
            onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }}
            size="large"
            variant="outlined"
            style={{ width: '90%', background: '#fff' }}
            InputProps={input.lastName ? {
              endAdornment: (
                <Check style={{ color: '#2E99A5' }} />
              ),
            } : null}
          />
        </>
      );
      case 'gender': return (
        <div className="selectGender">
          <div className="womanSection">
            <div className="sex">Woman</div>
            <Radio
              name="gender"
              onChange={handleChangeInput}
              value="Female"
              checked={input.gender === 'Female'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="manSection">
            <div className="sex">Man</div>
            <Radio
              name="gender"
              onChange={handleChangeInput}
              value="Male"
              checked={input.gender === 'Male'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="otherSection">
            <TextField fullWidth placeholder="Others" value={!['Male', 'Female'].includes(input.gender) ? input.gender : ''} name="gender" onChange={handleChangeInput} />
          </div>
        </div>
      );
      case 'race': return (
        <>
        <label className="label">Race</label>
        {/* <LocalizationProvider dateAdapter={DateAdapter}>
          <MobileDatePicker
            inputFormat="dd/MM/yyyy"
            name="birthDate"
            value={input.birthDate}
            onChange={handleChangeBirtDate}
            renderInput={(params) => <TextField error={error.birthDate} fullWidth variant="outlined" style={{ width: '90%', background: '#fff' }} {...params} />}
          />
        </LocalizationProvider> */}
        <TextField placeholder="Chinese" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.race} name="race" value={input.race} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
      </>
      );
      case 'doesUserUseDatingApps': return (
        <div className="selectGender">
          <div className="womanSection">
            <div className="sex">Currently using</div>
            <Radio
              name="doesUserUseDatingApps"
              onChange={handleChangeInput}
              value="Currently using"
              checked={input.doesUserUseDatingApps === 'Currently using'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="manSection">
            <div className="sex">Stopped using</div>
            <Radio
              name="doesUserUseDatingApps"
              onChange={handleChangeInput}
              value="Stopped using"
              checked={input.doesUserUseDatingApps === 'Stopped using'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="manSection">
            <div className="sex">Never tried</div>
            <Radio
              name="doesUserUseDatingApps"
              onChange={handleChangeInput}
              value="Never tried"
              checked={input.doesUserUseDatingApps === 'Never tried'}
              sx={radioStyle}
            />
          </div>
        </div>
      );
      case 'dislikeMostAboutDatingApps': return (
        <div className="selectGender">
          <div className="womanSection">
            <div className="sex">Superficial</div>
            <Radio
              name="dislikeMostAboutDatingApps"
              onChange={handleChangeInput}
              value="Superficial"
              checked={input.dislikeMostAboutDatingApps === 'Superficial'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="manSection">
            <div className="sex">Texting</div>
            <Radio
              name="dislikeMostAboutDatingApps"
              onChange={handleChangeInput}
              value="Texting"
              checked={input.dislikeMostAboutDatingApps === 'Texting'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="manSection">
            <div className="sex">Ghosting</div>
            <Radio
              name="dislikeMostAboutDatingApps"
              onChange={handleChangeInput}
              value="Ghosting"
              checked={input.dislikeMostAboutDatingApps === 'Ghosting'}
              sx={radioStyle}
            />
          </div>
          <Divider />
          <div className="manSection">
            <div className="sex">Time-consuming</div>
            <Radio
              name="dislikeMostAboutDatingApps"
              onChange={handleChangeInput}
              value="Time-consuming"
              checked={input.dislikeMostAboutDatingApps === 'Time-consuming'}
              sx={radioStyle}
            />
          </div>
          <div className="otherSection">
            <TextField fullWidth placeholder="Others" value={!['Time-consuming', 'Ghosting', 'Texting', 'Superficial'].includes(input.dislikeMostAboutDatingApps) ? input.dislikeMostAboutDatingApps : ''} name="dislikeMostAboutDatingApps" onChange={handleChangeInput} />
          </div>
        </div>
      );
      case 'discoverUniqueSpotTT': return (
        <div className="video-container">
          <div style={{
            position: 'absolute', top: 45, display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', left: 0, right: 0, zIndex: 0,
          }}
          >
            <img alt="loading" src={images.Loading} width="100" height="100" />
          </div>
          <TikTok url="https://www.tiktok.com/@kopidate/video/7045515801499077889" />
        </div>
      );
      case 'religion': return (
        <TextField placeholder="Closest to your heart" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.religion} name="religion" value={input.religion} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
      );
      case 'contactNumber': return (
        <>
          <label className="label">We’ll reach out to you for updates</label>
          <div style={{ width: '90%', alignSelf: 'flex-start', marginLeft: '5%' }}>
            <PhoneInput
              country="sg"
              value={input.contactNumber?.toString()}
              inputStyle={{ width: '100%' }}
              placeholder="0412 345 678"
              specialLabel={null}
              onChange={(phone) => setInput({ ...input, contactNumber: phone?.toString() })}
            />
          </div>
        </>
      );
      case 'birthDate': return (
        <>
          <label className="label">Your first breath</label>
          {/* <LocalizationProvider dateAdapter={DateAdapter}>
            <MobileDatePicker
              inputFormat="dd/MM/yyyy"
              name="birthDate"
              value={input.birthDate}
              onChange={handleChangeBirtDate}
              renderInput={(params) => <TextField error={error.birthDate} fullWidth variant="outlined" style={{ width: '90%', background: '#fff' }} {...params} />}
            />
          </LocalizationProvider> */}
          <TextField type="date" placeholder="Engineer" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.birthDate} name="birthDate" value={input.birthDate} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
        </>
      );
      case 'occupation': return (
        <>
          <label className="label">Let’s see where you fit!</label>
          <TextField placeholder="Engineer" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.occupation} name="occupation" value={input.occupation} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
        </>
      );
      case 'instaHandle': return <TextField placeholder="@kopi.date" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.instaHandle} name="instaHandle" value={input.instaHandle} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />;
      case 'email': return (
        <>
          <TextField type="email" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.email} name="email" value={input.email} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
          <div style={{
            marginTop: 8, fontSize: 14, color: 'grey', width: '90%',
          }}
          >
            Kindly note that your login authentication will still be:
            <strong>{auth.currentUser.email}</strong>
          </div>
        </>
      );
      case 'school': return (
        <>
          <label className="label">We won’t ask for your scores!</label>
          <TextField placeholder="School" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.school} name="school" value={input.school} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
          <div />
        </>
      );

      case 'photo': return (
        <>
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFilechange}
            hidden
          />
          <img
            src={pp || images.Avatar}
            alt="about you"
            style={{
              width: '60%',
              marginBottom: 20,
            }}
          />
          <button
            onClick={onBtnClick}
            style={{
              cursor: 'pointer', background: '#FFA500', border: 'none', borderRadius: 10, width: '60%', padding: '12px 14px',
            }}
          >
            <div style={{
              textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 16, color: '#fff',
            }}
            >
              CHOOSE IMAGE
            </div>
          </button>
        </>
      );
      case 'selfIntroduction': return (
        <>
          <TextField multiline rows={4} error={error.selfIntroduction} name="selfIntroduction" value={input.selfIntroduction} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
          {input.selfIntroduction?.length < 15
                        && (
                        <div style={{
                          fontSize: 12, alignSelf: 'flex-end', marginRight: 20, marginTop: 10, color: '#ef5350',
                        }}
                        >
                          {input.selfIntroduction?.length || 0}
                          {' '}
                          characters (minimum 15 characters)
                        </div>
                        )}
          <List sx={{ width: '100%' }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ height: 32, width: 32 }} alt="Kim" src={images.Kim} />
              </ListItemAvatar>
              <ListItemText
                secondary={(
                  <div style={{ fontSize: 12 }}>
                    Kim is a Product Designer at Facebook who writes about design in everyday life. She loves working on impactful products, and talking to people about them.
                  </div>
              )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ height: 32, width: 32 }} alt="Sami" src={images.Sami} />
              </ListItemAvatar>
              <ListItemText
                secondary={(
                  <div style={{ fontSize: 12 }}>
                    Sami is a mechanical engineer who is working on a new startup idea in the EV battery space.
                  </div>
              )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ height: 32, width: 32 }} alt="June" src={images.June} />
              </ListItemAvatar>
              <ListItemText
                secondary={(
                  <div style={{ fontSize: 12 }}>
                    June is an opera singer who writes her own blog.
                  </div>
              )}
              />
            </ListItem>
          </List>
        </>
      );
      case 'linkedinUrl': return (
        <>
          <div style={{ alignSelf: 'flex-start', marginLeft: '5%', marginBottom: 20 }}>
            <div
              style={{
                color: '#9e7666', fontSize: 14, fontWeight: '500', textDecoration: 'underline',
              }}
              onClick={handleRedirect('https://www.linkedin.com/in/edit/contact-info/')}
            >
              Retrieve URL here
            </div>
          </div>
          <TextField placeholder="linkedin.com" type="url" onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.linkedinUrl} name="linkedinUrl" value={input.linkedinUrl} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
        </>
      );
      case 'lastName': return <TextField onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.lastName} name="lastName" value={input.lastName} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />;
      case 'meetAnyoneQuestion': return (
        <>
          <TextField onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.meetAnyoneQuestion} name="meetAnyoneQuestion" value={input.meetAnyoneQuestion} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} multiline rows={3} placeholder="I’d like to meet Obama because..." />
          {input.meetAnyoneQuestion?.length < 15
                        && (
                        <div style={{
                          fontSize: 12, alignSelf: 'flex-end', marginRight: 20, marginTop: 10, color: '#ef5350',
                        }}
                        >
                          {input.meetAnyoneQuestion?.length || 0}
                          {' '}
                          characters (minimum 15 characters)
                        </div>
                        )}
          <List sx={{ width: '100%' }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ height: 32, width: 32 }} alt="Sami" src={images.Sami} />
              </ListItemAvatar>
              <ListItemText
                secondary={(
                  <div style={{ fontSize: 12 }}>
                    I would like to meet Roger Federer. He’s the greatest tennis player of all time and is the reason why I began learning tennis when I was 14 years old.
                  </div>
              )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ height: 32, width: 32 }} alt="Kim" src={images.Kim} />
              </ListItemAvatar>
              <ListItemText
                secondary={(
                  <div style={{ fontSize: 12 }}>
                    Franz Kafka and just chat with him, but also to give him a hug, because his writing is so raw it feels like it was torn from bone.
                  </div>
              )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ height: 32, width: 32 }} alt="June" src={images.June} />
              </ListItemAvatar>
              <ListItemText
                secondary={(
                  <div style={{ fontSize: 12 }}>
                    My grandparents in NZ whom I have not been able to visit due to the Covid restrictions.
                  </div>
              )}
              />
            </ListItem>
          </List>
        </>
      );
      case 'promoCode': return (
        <>
          <TextField onKeyUp={(e) => { if (e.key === 'Enter') handleNext(e); }} error={error.promoCode} name={active.field} value={input.promoCode} onChange={handleChangeInput} fullWidth size="large" variant="outlined" style={{ width: '90%', background: '#fff' }} />
          <div style={{ marginTop: 8, fontSize: 14, color: 'grey' }}>Optional</div>
        </>
      );
      default: return null;
    }
  };

  const [logOutConfirm, setLC] = useState(false);
  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // window.location.replace('/login');
        clearStorage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh' }}>
        {loading
          ? (
            <div className={classes.loading}>
              <div className="loadingText">Making magic happen</div>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          ) : (
            <div className={classes.root}>
              {(step !== 0 || user)
                        && <IconButton className="back" onClick={handleBack}><ChevronLeftRounded /></IconButton>}
              {(step === 0 && !user)
                        && <IconButton className="back" onClick={() => setLC(true)}><LogoutRounded /></IconButton>}
              <div className="top" style={active.image ? imageStyle : {}}>
                <div className="title">{active.title}</div>
                <div className="desc">{active.desc}</div>
                <div className="altDesc">{active.altDesc}</div>
                <div className="fieldContainer">
                  {active.image
                                && active.image}
                  {active.field
                                && renderField()}
                </div>
              </div>
              <div style={{ paddingTop: isIOS && !active.seeOnly ? 16 : 36, boxSizing: 'border-box', textAlign: 'center' }}>
                <Container>
                  <div style={{
                    fontSize: 14, color: active.desc2 ? '#606060' : '#fef8f4', marginTop: 8, display: 'flex', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    {active.desc2 || '.'}
                  </div>
                  {!disable
                                && (
                                <button
                                  disabled={disable}
                                  onClick={active.btnText === 'SUBMIT' ? handleSubmit : handleNext}
                                  style={{
                                    cursor: 'pointer', background: active.btnColor === 'orange' ? '#FFA500' : '#9e7666', marginBottom: 8, border: 'none', borderRadius: 10, width: '100%', padding: '12px 14px', marginTop: 20,
                                  }}
                                >
                                  <div style={{
                                    textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 16, color: '#fff',
                                  }}
                                  >
                                    {active.btnText || 'NEXT'}
                                  </div>
                                </button>
                                )}
                </Container>
              </div>
            </div>
          )}
        <Snackbar
          open={Boolean(snack)}
          onClose={() => setSnack('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div className={classes.snack}>{snack}</div>
          </div>
        </Snackbar>
        <ConfirmationModal title="Logout" open={logOutConfirm} handleClose={() => setLC(false)} handleAgree={handleLogOut} />
      </Grid>
    </Grid>
  );
}

const imageStyle = {
  background: '#f9efe8',
  borderRadius: '0 0 50% 50%',
  width: '180%',
  marginLeft: '-40%',
};

const radioStyle = {
  '&.Mui-checked': {
    color: '#9e7666',
  },
};

const countries = [
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: 'Cote d\'Ivoire', phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: 'Korea, Democratic People\'s Republic of',
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: 'Lao People\'s Democratic Republic',
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];
