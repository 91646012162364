/* eslint-disable no-unsafe-optional-chaining */
import { FormatQuoteRounded } from '@mui/icons-material';
import { Container } from '@mui/material';
import moment from 'moment';
import React, { /* useEffect */ } from 'react';
// import { useHistory } from 'react-router-dom';
import { getUserData } from '../../utils/storage';

export default function Component({ classes }) {
  // const history = useHistory();
  // console.log(classes, history);
  const user = getUserData();
  if (user.unlockQuote) {
    return (
      <div className={classes.quotesSection}>
        <Container>
          <div className="title">Quote of the Date</div>
        </Container>
        <div>
          <div className="quotes">
            <div className="quote-card">
              {user.quotes?.length
                ? user.quotes.map((data) => (
                  <QuoteCard key={data?.date?.seconds || Math.random()} data={data} />
                ))
                : null}
            </div>
          </div>

        </div>
      </div>
    );
  }
  return null;
}

function QuoteCard({ data }) {
  // useEffect(() => {
  //   function renderQuoteSize() {
  //     const wowQuote = document.querySelector('.wowQuote');
  //     const quoteContainer = document.querySelector('.quote');

  //     const { fontSize } = window.getComputedStyle(wowQuote);
  //     wowQuote.style.fontSize = `${parseFloat(fontSize) - 1}px`;

  //     if (wowQuote.clientHeight >= quoteContainer.clientHeight) {
  //       renderQuoteSize();
  //     }
  //   }

  //   renderQuoteSize();
  //   return null;
  // }, []);

  return (
    <div className="quote">
      <div className="quote-header">
        <div className="wowQuote">{data.quote}</div>
        <FormatQuoteRounded />
      </div>
      <div className="author">
        {data?.author}
      </div>
      <div className="date">
        {moment(data?.date?.seconds * 1000).format('D MMM YYYY')}
      </div>
    </div>
  );
}
