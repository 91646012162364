/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import { brown } from '../../Configs/colors';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ConfirmationModal({
  classes,
  open,
  title,
  desc,
  handleClose,
  handleAgree,
  noCancel,
  agreeText,
  disagreeText,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      className={classes.root}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!noCancel
            && <Button id={`btn-modal-cancel-${title.replace(' ', '')}`} style={{ color: brown, border: `1px solid ${brown}`, textTransform: 'none' }} onClick={handleClose}>{disagreeText}</Button>}
        <Button id={`btn-modal-confirm-${title.replace(' ', '')}`} style={{ color: '#fff', backgroundColor: brown, textTransform: 'none' }} onClick={handleAgree}>{agreeText}</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  handleClose: PropTypes.func,
  handleAgree: PropTypes.func,
  noCancel: PropTypes.bool,
  agreeText: PropTypes.string,
  disagreeText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  open: false,
  title: '',
  desc: 'Are you sure?',
  handleClose: null,
  handleAgree: null,
  noCancel: false,
  agreeText: 'Yes',
  disagreeText: 'Cancel',
};
