import React, { useEffect, useState } from 'react';
import images from '../../Configs/images';

function BannerMobile() {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function CheckAppInstalled() {
    window.location.assign(process.env.REACT_APP_DEEPLINK);
    setTimeout(() => {
      window.location.assign(process.env.REACT_APP_MOBILE_APP);
    }, 100);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  if (!isMobile) {
    return null;
  }
  return (
    <div style={{
      display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(0,253,195)', zIndex: 100, justifyContent: 'space-between', padding: '4px 12px',
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img alt="loading" src={images.Loading} style={{ width: 32, height: 32, margin: '0px 12px' }} />
        <div>
          <p style={{ fontWeight: 'bold', fontSize: 12, height: 10 }}>Kopi Date Members App:</p>
          <div style={{
            display: 'flex', flexDirection: 'row', marginTop: -14, paddingBottom: 8,
          }}
          >
            <p style={{ fontWeight: 'bold', fontSize: 12, height: 5 }}>NEW:&nbsp;</p>
            <p style={{ fontWeight: 'lighter', fontSize: 12, height: 5 }}> For a better experience</p>
          </div>

        </div>
      </div>
      <button
        onClick={() => CheckAppInstalled()}
        style={{
          backgroundColor: '#fef8f4',
          alignSelf: 'center',
          padding: '8px 16px',
          borderRadius: 100,
          color: '#9e7666',
          fontWeight: 'bold',
          borderWidth: 0,
        }}
      >
        Open
      </button>
    </div>
  );
}

export default BannerMobile;
