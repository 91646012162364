import { brown } from '../../Configs/colors';

const styles = {
  root: {
    paddingBottom: 56,
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
  },
  grow: {
    width: '100%',
  },

  loading: {
    textAlign: 'center',
    background: '#fef8f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '95vh',
  },
  sectionTwo: {
    backgroundColor: '#fef8f4',
    borderRadius: 18,
    padding: '8px 8px 20px',
    '& .share-link': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .profile-section': {
      backgroundColor: '#f9efe8',
      borderRadius: 18,
      position: 'relative',
      minHeight: 180,
      zIndex: 2,
    },
    '& .need-to-do': {
      backgroundColor: '#fff',
      padding: '32px 0 14px',
      marginTop: -18,
      borderRadius: '0 0 18px 18px',
      position: 'relative',
      zIndex: 1,
    },
    '& .actions-section': {
      marginTop: 20,
      '& .actions': {
        paddingTop: 12,
        display: 'flex',
        overflow: 'auto',
        position: 'relative',
        gap: 4,
        '&::-webkit-scrollbar': {
          display: 'none',
          scrollbarWidth: 'none',
        },
        '& .action': {
          width: '23%',
          objectFit: 'contain',
          cursor: 'pointer',
        },
      },
      '& .title': {
        color: '#9e7666',
        fontSize: 17,
        fontWeight: 'bold',
      },
      '& .button-card': {
        backgroundColor: '#f9efe8',
        borderRadius: '50px 50px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 0 8px',
        position: 'relative',
        '& .button-text': {
          fontSize: 10,
          color: brown,
          fontWeight: '500',
          marginTop: 5,
          textAlign: 'center',
        },
      },
      '& .special-border': {
        backgroundColor: '#fff',
        border: '2px solid #ff9b5f',
        boxSizing: 'border-box',
        borderRadius: '50px 50px 20px 20px',
        position: 'relative',
        padding: 2,
        '& .conclude-btn-card': {
          borderRadius: '48px 48px 18px 16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 0 4px',
          backgroundColor: '#ff9b5f',
        },
        '& .button-text': {
          fontSize: 10,
          color: '#fff',
          fontWeight: '500',
          marginTop: 5,
          textAlign: 'center',
        },
      },
    },
  },
};
export default styles;
